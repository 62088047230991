export const FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION =
  'first-migration-to-alegra-2025-notification';

export const MIGRATE_TO_ALEGRA_2025_ATTEMPT_BASE =
  'pos-migrate-to-alegra-2025-attempt';

export const getNotificationsAttempts = (notifications) => {
  const regex = new RegExp(`${MIGRATE_TO_ALEGRA_2025_ATTEMPT_BASE}-\\d+$`);

  return notifications.filter((notification) =>
    Object.keys(notification)[0].match(regex)
  );
};

export const EVENT_NAME = 'exp-banner-ma-managed';

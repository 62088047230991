import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setSettings } from '../../../reducers/activeInvoice';
import CurrencyModal from '../../modals/currency/Currency';
import Header from './Header';
import FastOptions from './FastOptions';
import Client from './client';
import SearchItem from './SearchItem';
import InvoiceItems from './InvoiceItems';
import Summary from './Summary';
import Actions from './Actions';
import InvoiceNotification from './InvoiceNotification';
import useContactsVersionsGroup from '../../../hooks/useContactsVersionsGroup/hook';
import useOutOfStockIndicator from '../../../hooks/useOutOfStockIndicator/hook';
import { GlobalDiscounts } from './globalDiscounts';

const ActiveInvoice = () => {
  const itemsOutOfStock = useSelector((state) => state.activeInvoice?.itemsOutOfStock) || [];
  const dispatch = useDispatch();
  const { isContactsActive } = useContactsVersionsGroup();
  const { disableByStock } = useOutOfStockIndicator({ itemsOutOfStock });

  return (
    <>
      <div className='new-active-invoice w-sm-100 d-flex flex-column position-relative'>
        <Header />

        <div className='invoice-top d-flex flex-column'>
          <FastOptions />

          <InvoiceNotification />

          <Client />

          <SearchItem />
        </div>

        <div className='invoice-content'>
          <InvoiceItems/>
        </div>

        <div className='invoice-bottom'>
          <Summary />

          <Actions disableByStock={disableByStock}/>
        </div>

        <GlobalDiscounts />
      </div>
      
      <CurrencyModal
        onCreated={(currency) => dispatch(setSettings({ currency }))}
      />
    </>
  );
};

export default ActiveInvoice;

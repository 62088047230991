import React, { useEffect, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';

import { checkFeatureLimit } from '../../../reducers/membership';
import { openModal } from '../../../reducers/modals';
import { clear } from '../../../reducers/activeInvoice';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import {
  country as countrySelector,
  companySelector,
  regime as regimeSelector,
  idCompanySelector,
} from '../../../selectors/company';
import {
  currency,
  itemsCount,
  total,
  noITBMS,
  items as itemsSelector,
  numeration as numerationSelector,
  client as clientSelector,
  discSubtotal,
  paymentMethod,
} from '../../../selectors/activeInvoice';
import {
  electronicInvoicing,
  stationInvoiceNumeration,
} from '../../../selectors/app';
import { hasPermissionTo } from '../../../selectors/auth';

import CleanInvoiceConfirm from './CleanInvoiceConfirm';
import { replaceAndParse, toast } from '../../../utils';
import {
  checkNumerationBlock,
  itemsHaveReferenceMaxExceeded,
  itemsInSaleHaveOutdatedProductKey,
} from './utils';
import { COUNTRIES } from '../../../utils/enums/countries';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import { useTicketBaiInformation } from '../../../hooks/useTicketBaiElegible';
import { useElectronicInvoiceOfflineGroup } from '../../../hooks/useElectronicInvoiceOfflineGroup';
import { useFormat } from '../../../hooks/useFormat';
import { displayInvoiceRefactored } from '../../modals/invoiceRefactored/utils/invoice';
import { Tooltip, useModal, Popover } from '@alegradev/smile-ui-react';
import NewInvoice from '../../modals/newInvoice/NewInvoice';
import { getModalTitle } from '../../forms/invoice/utils';
import { showNewInvoiceModal } from '../../modals/newInvoice/utils';
import { useNewInvoiceModal } from '../../../hooks/useNewInvoiceModal';

const Actions = ({disableByStock = false}) => {
  const { isElectronicOffline } = useElectronicInvoiceOfflineGroup();
  const [confirm, setConfirm] = useState(false);
  const isOnline = useConnectionStatus();
  const isApiAvailable = useApiAvailable()
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const count = useSelector(itemsCount);
  const client = useSelector(clientSelector);
  const selectedCurrency = useSelector(currency);
  const items = useSelector(itemsSelector);
  const country = useSelector(countrySelector);
  const numeration = useSelector(numerationSelector);
  const regime = useSelector(regimeSelector);
  const stationNumeration = useSelector(stationInvoiceNumeration);
  const isElectronic = useSelector(electronicInvoicing(numeration));
  const showNoITBMSWarning = useSelector(noITBMS) && isElectronic;
  const currentTotal = useSelector(total);
  const invoiceDiscSubtotal = useSelector(discSubtotal);
  const { registryDate } = useSelector(companySelector);
  const paymentMethodSelected = useSelector(paymentMethod)
  const company = useSelector(idCompanySelector);
  const noViewNumerationPermission = !can('index', 'number-templates');
  const {openModal: openNewModal, closeModal: closeNewModal} = useModal();
  const { openNewInvoiceModal }= useNewInvoiceModal();

  const { isTicketBaiEnabled } = useTicketBaiInformation();
  const { decimal, fmt } = useFormat(selectedCurrency);

  const domCondition =
    country === 'republicaDominicana' &&
    isElectronic &&
    items
      .map((item) =>
        get(item, 'tax').some(
          (tax) => get(tax, 'type') === 'ITBIS' || get(tax, 'type') === 'EXENTO'
        )
      )
      .some((value) => value === false);

  const spainCondition =
    isTicketBaiEnabled &&
    isElectronic &&
    items
      .map((item) =>
        get(item, 'tax').some(
          (tax) => get(tax, 'type') === 'IVA'
        )
      )
      .some((value) => value === false);

  const domClientNumerationCondition =
    country === 'republicaDominicana' &&
    (!!numeration
      ? get(numeration, 'prefix') === 'E31'
      : get(stationNumeration, 'prefix') === 'E31') &&
    !get(client, 'id');

  const spainClientCodition =
    country === 'spain' &&
    !!numeration &&
    get(numeration, 'documentType') === 'invoice' &&
    !get(client, 'id');

  // const peruCondition =
  //   country === COUNTRIES.PERU &&
  //   !`${regime}`.includes('RUS') &&
  //   get(numeration, 'documentType') === 'saleTicket' &&
  //   get(client, 'identificationObject.type') === 'RUC';

  const peruDocumentCondition =
    country === COUNTRIES.PERU &&
    !`${regime}`.includes('RUS') &&
    get(numeration, 'documentType') !== 'saleTicket' &&
    get(client, 'identificationObject.type') !== 'RUC';

  // const peruInvoiceCondition =
  //   country === COUNTRIES.PERU &&
  //   !`${regime}`.includes('RUS') &&
  //   get(numeration, 'documentType') === 'invoice' &&
  //   get(client, 'identificationObject.type') === 'RUC' &&
  //   !isElectronic;

  const criUserHasItemsInSaleWithOutdatedProductKey =
    country === 'costaRica' && itemsInSaleHaveOutdatedProductKey(items);
  const criUserHasItemsWithReferenceMaxExceeded =
    country === 'costaRica' &&
    isElectronic &&
    itemsHaveReferenceMaxExceeded(items, false);

  const isNumerationBlocked = checkNumerationBlock(
    numeration ?? stationNumeration,
    country,
    registryDate,
    false,
    isOnline,
    isApiAvailable,
    isElectronicOffline
  );

  const numerationToCheck = numeration ?? stationNumeration;
  const showPopoverElectronicOffline = !isOnline && isElectronicOffline && get(numerationToCheck, 'isElectronic', false);

  const handleSell = () => {
    const isHighValueInDominicanRepublic = () =>
      currentTotal.toNumber() > 250000 &&
      country === 'republicaDominicana' &&
      !get(client, 'identification') &&
      get(numeration, 'prefix') === 'E32';

    const isSpainWithNonInvoiceNumeration = () =>
      country === 'spain' &&
      currentTotal.toNumber() >= 400 &&
      (!numeration || numeration.documentType !== 'invoice');

    const getClientAddressStatus = () => {
      const requiredFields = ['province', 'city', 'address'];
      const address = get(client, 'address', {});

      const isEmpty = requiredFields.every((field) => !address[field]);

      if (isEmpty) {
        return { status: 'empty', id: get(client, 'id') };
      }

      const isIncomplete = requiredFields.some((field) => !address[field]);

      if (isIncomplete) {
        return { status: 'incomplete', address, id: get(client, 'id') };
      }

      return { status: 'complete', address, id: get(client, 'id') };
    };


    const showSpainClientModal = () => {
      if (isTicketBaiEnabled && get(numeration, 'documentType') === 'invoice') {
        return getClientAddressStatus();
      }
      return false;
    }

    const showClientIdentificationError = () => {
      toast.error({
        title: I18n.get(
          'noClientIdentificationError',
          'Elige un cliente con identificación'
        ),
        subtitle: I18n.get(
          'noClientIdentificationErrorSubtitle',
          'En facturas con un valor superior a RD$ 250,000 debes elegir un cliente con RNC o cédula.'
        ),
      });
      throw new Error();
    };

    const openInvoiceModal = () => {
      dispatch(
        checkFeatureLimit(['income', 'invoices'], () =>
          showNewInvoiceModal(country)
            ? openNewInvoiceModal()
            : (displayInvoiceRefactored({ country })
            ? dispatch(openModal({ modal: 'invoiceRefactored' }))
            : dispatch(openModal({ modal: 'invoice' })))
        )
      );
    }

    if (isHighValueInDominicanRepublic()) {
      showClientIdentificationError();
      return;
    }

    if (isSpainWithNonInvoiceNumeration()) {
      dispatch(openModal({ modal: 'changeToOrdinaryNumeration' }));
      return;
    }

    if (["empty", "incomplete"].includes(showSpainClientModal().status)) {
      dispatch(openModal({ modal: 'updateSpainClient', params: showSpainClientModal() }));
      return;
    }

    // If not in any of the previous conditions, open the invoice modal
    openInvoiceModal();
  };

  const getWarningSell = (country) => {
    if (
      (!isOnline || !isApiAvailable) &&
      get(numeration, 'isElectronic', false) &&
      [COUNTRIES.COLOMBIA, COUNTRIES.ARGENTINA, COUNTRIES.PERU, COUNTRIES.COSTA_RICA, COUNTRIES.PANAMA, COUNTRIES.REPUBLICA_DOMINICANA, COUNTRIES.MEXICO].includes(country)
    ) {
      return I18n.get("numerationElectronicOffline", "No puedes vender porque la numeración está en modo offline.");
    }

    if (noViewNumerationPermission)
      return I18n.get(
        'youShouldSelectANumeration',
        'Debes seleccionar una numeración, solicita a un administrador el permiso para ver el listado.'
      );

    switch (country) {
      case 'panama':
        return I18n.get(
          'noItbmsWarning.summary',
          'Parece que seleccionaste productos que no tienen impuestos ITBMS y debes validarlos para crear tu factura.'
        );
      case 'republicaDominicana':
        return I18n.get(
          'noItbisWarning.summary',
          'Parece que seleccionaste productos que no tienen impuestos ITBIS y debes validarlos para crear tu factura.'
        );
      case 'peru':
        if (peruDocumentCondition)
          return I18n.get(
            'identificationNotAvailable.new.summary',
            `Debes elegir una numeración de boletas porque el cliente tiene un tipo de identificación diferente a 'RUC'.`
          );
        return I18n.get(
          'rucNotAvailable.new.summary',
          `Para clientes con tipo de identificación 'RUC' debes elegir una numeración de facturas electrónicas.`
        );
      case 'costaRica':
        if (criUserHasItemsWithReferenceMaxExceeded)
          return I18n.get(
            'itemsInSaleExceededReference',
            'Alguno de tus productos en venta supera el máximo 20 caracteres para la referencia, editalo para continuar.'
          );
        return I18n.get(
          '',
          'Para vender debes editar el código de los productos pendientes de actualizar.'
        );
      case 'spain':
        if (spainCondition)
          return I18n.get(
            'noIVAWarning.summary',
            'Seleccionaste productos que no tienen impuesto IVA y este es necesario para crear tu factura.'
          );
      default:
        return '';
    }
  };

  return (
    <div className='invoice-bottom__actions'>
      <div className='px-3'>
        <div className='d-flex'>
          {
            showPopoverElectronicOffline && (
              <Popover
                visible
                defaultVisible
                showCloseButton
                title={I18n.get('provisionalTicketCreationWarning.title', 'Crearemos un ticket provisional 🔔')}
                description={I18n.get('provisionalTicketCreationWarning.description', 'Se volverá factura electrónica cuando tengas conexión y tu cliente la recibirá automáticamente en su correo. ')}
                placement='left'
                width={330}
                >
                <div></div>
              </Popover>
            )
          }
        <Tooltip
          visible={
            domCondition ||
            showNoITBMSWarning ||
            criUserHasItemsInSaleWithOutdatedProductKey ||
            peruDocumentCondition ||
            criUserHasItemsWithReferenceMaxExceeded ||
            isNumerationBlocked ||
            noViewNumerationPermission ||
            spainCondition
          }
          children={
            <div className='pay px-3 w-100'>
              <button
                id='invoice-button'
                className='btn btn-primary btn-sell-invoice d-flex justify-content-between'
                type='button'
                data-testid='sell-btn'
                disabled={
                  !count ||
                  domCondition ||
                  domClientNumerationCondition ||
                  spainClientCodition ||
                  showNoITBMSWarning ||
                  criUserHasItemsInSaleWithOutdatedProductKey ||
                  peruDocumentCondition ||
                  criUserHasItemsWithReferenceMaxExceeded ||
                  isNumerationBlocked ||
                  noViewNumerationPermission ||
                  disableByStock ||
                  spainCondition
                }
                onClick={() => {
                  const _numeration = !!numeration
                    ? numeration
                    : stationNumeration;
                  try {
                    handleSell();
                    dispatch(sendNewGTMEvent('pos-sale-attempted', {
                      amount: currentTotal.toNumber()
                    }));
                    dispatch(
                      sendGTMEvent('invoice-creation-attempted', {
                        creationStatus: 'success',
                        isElectronicInvoicer: isElectronic,
                        documentType: get(_numeration, 'documentType', null),
                        isElectronicInvoice: get(
                          _numeration,
                          'isElectronic',
                          false
                        ),
                        currency: get(selectedCurrency, 'code', null),
                        invoiceSubtotal: invoiceDiscSubtotal,
                        invoiceTotal: !!currentTotal
                          ? currentTotal.toNumber()
                          : null,
                        totalItems: count,
                      })
                    );
                  } catch {
                    dispatch(
                      sendGTMEvent('invoice-creation-attempted', {
                        creationStatus: 'failure',
                        isElectronicInvoicer: isElectronic,
                        documentType: get(_numeration, 'documentType', null),
                        isElectronicInvoice: get(
                          _numeration,
                          'isElectronic',
                          false
                        ),
                        currency: get(selectedCurrency, 'code', null),
                        invoiceSubtotal: invoiceDiscSubtotal,
                        invoiceTotal: !!currentTotal
                          ? currentTotal.toNumber()
                          : null,
                        totalItems: count,
                      })
                    );
                  }
                }}
                >
                  <div>{I18n.get('sell', 'vender')}</div>
                  <div>{useSelector(total).toFormat(decimal, fmt)}</div>
                </button>
              </div>
            }
            overlay={getWarningSell(country)}
            width='full'
          />
        </div>


        <div className='cancel px-3 mb-2'>
          <button
            className='btn btn-cancel-invoice w-100 d-flex justify-content-between'
            type='button'
            disabled={!count}
            onClick={() => setConfirm(true)}
          >
            <div>{`${count} ${count === 1
              ? I18n.get('item', 'producto')
              : I18n.get('items', 'productos')
              }`}</div>
            <div className='text-primary'>{I18n.get('cancel', 'cancelar')}</div>
          </button>
        </div>
      </div>
      <CleanInvoiceConfirm
        isOpen={confirm}
        onRequestClose={() => setConfirm(false)}
        onConfirm={() => {
          dispatch(clear());
          setConfirm(false);
        }}
      />
    </div>
  );
};

export default Actions;

import { I18n } from 'aws-amplify';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';
import { INVOICE_DOCUMENT_TYPES } from '../utils/constants';
import { toLower } from 'lodash';

export const SpainInvoiceTextStrategy = {
  getSubmitButtonName: ({ values, numeration, isElectronic }) => {
    return I18n.get('sell', 'Vender');
  },

  getModalTitle: (numeration) => {
    if (numeration?.documentType === INVOICE_DOCUMENT_TYPES.SALE_TICKET) {
      return toLower(I18n.get('invoiceModalTitle.saleTicket', 'ticket'));
    }
    return BaseInvoiceTextStrategy.getModalTitle(numeration);
  },
  getNewSubmitButtonName: ({ numeration }) => {
    if (numeration?.isElectronic) {
      return I18n.get('emit', 'Emitir');
    }
    return BaseInvoiceTextStrategy.getNewSubmitButtonName({ numeration });
  },
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'

import { station as stationSelector } from '../../selectors/app';
import { settings as settingsSelector, localSettings as localSettingsSelector, country as countrySelector, membershipSelector } from '../../selectors/company';
import { isOnlyInvoicingPlan } from '../../selectors/company';
import { allItemCategories } from '../../selectors/itemCategories';
import Invoice from './Invoice';
import Footer from './Footer/Footer';
import { openModal } from '../../reducers/modals';
import { useShowWompiIntegrationModal } from '../../hooks/useShowWompiIntegrationModal';
import { addProvisionalConsecutive, updateProvisionalConsecutive } from '../../reducers/provisionalConsecutive';
import { provisionalConsecutiveSelectorByIdStation } from '../../selectors/provisionalConsecutive';
import { APIGraphqlSelector } from '../../selectors/app';
import { ulid } from 'ulid';

import { fetchConsecutiveProvisionalTicket } from '../../graphql/queries';
import { graphqlOperation } from 'aws-amplify'
import { useConnectionStatus } from '../../hooks/useConnectionStatus';
import { ModalProvider, useModal } from '@alegradev/smile-ui-react';
import usePaymentMethodChange from '../../hooks/usePaymentMethodChange/hook';
import useMigrationToAlegra2025 from '../../hooks/useMigrationToAlegra2025';
import { seenWelcome as seenWelcomeSelector } from '../../selectors/onboarding';
import useNotification from '../../hooks/useNotification/hook';
import { FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION } from '../modals/migrationToAlegra2025/utils';
import { COUNTRIES } from '../../utils/enums/countries';
import AutoInvoiceNotificationModal from '../../pages/invoices/notification/modal';
import { showSelfInvoice } from '../../pages/invoices/utils/selfInvoiceUtil';
import { useElectronicInvoiceOfflineGroup } from '../../hooks/useElectronicInvoiceOfflineGroup';

const Home = () => {
  const { isElectronicOffline } = useElectronicInvoiceOfflineGroup();
  let hubSpotChat = document.getElementById('hubspot-messages-iframe-container')
  let fakeHubSpotChat = document.getElementById('husbpot-chat')
  const isOnline = useConnectionStatus();
  const initHubspot = useSelector(state => get(state, 'app.initHubspot'));
  const categories = useSelector(allItemCategories)
  const station = useSelector(stationSelector)
  const onlyInvoicingPlan = useSelector(isOnlyInvoicingPlan)
  const settings = useSelector(settingsSelector)
  const localSettings = useSelector(localSettingsSelector)
  const country = useSelector(countrySelector)
  const dispatch = useDispatch()
  const provisionalConsecutive = useSelector(provisionalConsecutiveSelectorByIdStation(get(station, 'id', null)))
  const APIGraphql = useSelector(APIGraphqlSelector);
  const { hiddenImages } = usePaymentMethodChange()
  const seenWelcome = useSelector(seenWelcomeSelector);
  const { userIsElegible, showMigrationToAlegra2025 } =
    useMigrationToAlegra2025();
  const { viewed: firstViewed } = useNotification({
    notificationName: FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION,
  });
  const membership = useSelector(membershipSelector);
  const { openModal: openNewModal, closeModal: closeNewModal } = useModal();

  const pendingInvoicesEnabled = get(station, 'pendingInvoicesEnabled', false);

  const checkProvisionalConsecutive = async (isOnline, isElectronicOffline) => {
    if (!isElectronicOffline) return
    if (!isOnline) return
    if (isEmpty(provisionalConsecutive)) {
      try {
        const { data } = await APIGraphql(graphqlOperation(fetchConsecutiveProvisionalTicket, { idStation: station?.id, idDevice: ulid() }));
        const newProvisionalConsecutive = get(data, 'fetchConsecutiveProvisionalTicket', null)
        await dispatch(addProvisionalConsecutive({
          id: ulid(),
          idStation: get(newProvisionalConsecutive, 'idStation', null),
          idCompany: get(newProvisionalConsecutive, 'idCompany', null),
          idDevice: get(newProvisionalConsecutive, 'idDevice', null),
          prefix: get(newProvisionalConsecutive, 'prefix', null),
          current: get(newProvisionalConsecutive, 'current', null),
        }))
      } catch (error) {
        console.log(error)
      }
    }
  }

  useEffect(() => {
    checkProvisionalConsecutive(isOnline, isElectronicOffline)
  }, [isOnline, isElectronicOffline])

  useEffect(() => {
    if (!!hubSpotChat) {
      hubSpotChat.classList.add("home-page")

      if (categories.length > 0)
        hubSpotChat.classList.add("categories")

      if (!!pendingInvoicesEnabled && !onlyInvoicingPlan)
        hubSpotChat.classList.add("pending-invoices")
    }
    if (!!fakeHubSpotChat) {
      fakeHubSpotChat.classList.add("home-page")

      if (categories.length > 0)
        fakeHubSpotChat.classList.add("categories")

      if (!!pendingInvoicesEnabled && !onlyInvoicingPlan)
        fakeHubSpotChat.classList.add("pending-invoices")
    }
    return () => {
      if (!!hubSpotChat) {
        hubSpotChat.classList.remove("home-page")
        hubSpotChat.classList.remove("categories")
        hubSpotChat.classList.remove("pending-invoices")
      }
      if (!!fakeHubSpotChat) {
        fakeHubSpotChat.classList.remove("home-page")
        fakeHubSpotChat.classList.remove("categories")
        fakeHubSpotChat.classList.remove("pending-invoices")
      }
    }
  }, [hubSpotChat, fakeHubSpotChat, initHubspot, categories, pendingInvoicesEnabled, onlyInvoicingPlan])

  useEffect(() => {
    if (country === 'colombia' && get(settings, 'isActiveAutomationElectronicInvoice', false) && !get(localSettings, 'automationFEEnabled', false))
      dispatch(openModal({ modal: 'stationSetting5UVT' }))
  }, [country, settings, localSettings, dispatch])

  useEffect(() => {
    if (userIsElegible && !window.Cypress) {
      const intervalId = setInterval(
        () => {
          showMigrationToAlegra2025();
        },
        3 * 60 * 1000
      );

      return () => clearInterval(intervalId);
    }
  }, [userIsElegible, seenWelcome, firstViewed, showMigrationToAlegra2025]);

  useEffect(() => {
    if (country === COUNTRIES.MEXICO && membership?.pay && showSelfInvoice()) {
      openNewModal({
        title: '  ',
        hideCloseButton: false,
        name: 'AutoInvoiceNotificationModal',
        component: AutoInvoiceNotificationModal,
        size: 'medium',
        onClose: closeNewModal,
      });
    }
  },[country, membership])
  
  useShowWompiIntegrationModal();

  useEffect(() => {
    window.dataLayer.push({
      'event': 'VirtualPageview',
      'virtualPageURL': '/',
      'virtualPageTitle': 'Home'
    });
  }, [])

  return (
    <ModalProvider>
      <div className='home d-flex flex-column'>
        <Invoice />
        <div style={{ display: 'none' }}>{hiddenImages}</div>
        {!!pendingInvoicesEnabled && !onlyInvoicingPlan && <Footer />}
      </div>
    </ModalProvider>
  );
}

export default Home;
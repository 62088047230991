import { trimValues } from '../../../../../utils/trimValues';

export const validate = (values) => {
  let trimmedValues = trimValues(values);
  let errors = {};

  const productsArray = Object.values(trimmedValues.products || {});
  if (
    !productsArray.length ||
    !productsArray.every((item) => item.product && item.product.id)
  ) {
    errors.productsError = 'Debe agregar al menos un producto con un ID válido';
  }

  if (!trimmedValues.provider || !trimmedValues.provider.identification) {
    errors.providerError = 'Debe seleccionar un proveedor';
  }

  return errors;
};

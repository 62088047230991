import {
  IconBrandMastercard,
  IconBuildingBank,
  IconBusinessplan,
  IconCash,
  IconCreditCard,
  IconTransferIn,
} from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import { get, isEqual, truncate } from 'lodash';
import { COUNTRIES } from '../../../utils/enums/countries';

export const validate = (values, paymentMethods) => {
  const paymentMethodName = get(values, 'name', '').trim();
  const paymentMethodDuplicated = paymentMethods.find((paymentMethod) =>
    isEqual(paymentMethod.name.toLowerCase(), paymentMethodName.toLowerCase())
  );
  const errors = {};
  if (!get(values, 'name', '')) {
    errors.name = I18n.get('', 'Campo requerido');
  } else if (get(values, 'name', '').length < 3) {
    errors.name = I18n.get(
      'paymentMethod.minLength',
      'Usa mínimo 4 caracteres para el nombre'
    );
  } else if (
    paymentMethodName === 'Efectivo' ||
    paymentMethodName === 'Transferencia' ||
    paymentMethodName === 'Tarjeta de crédito' ||
    paymentMethodName === 'Tarjeta de débito' ||
    paymentMethodName === 'Combinado'
  ) {
    errors.name = I18n.get(
      'paymentMethod.duplicated',
      'Ya tienes un método de pago con ese nombre'
    );
  } else if (paymentMethodDuplicated) {
    errors.name = I18n.get(
      'paymentMethod.duplicated',
      'Ya tienes un método de pago con ese nombre'
    );
  }

  if (!get(values, 'instructions', '')) {
    errors.instructions = I18n.get('', 'Campo requerido');
  }

  return errors;
};

export const getPaymentMethodIcon = (name) => {
  switch (name) {
    case 'cash':
      return IconCash;
    case 'debit':
      return IconCreditCard;
    case 'credit':
      return IconBrandMastercard;
    case 'transfer':
      return IconBuildingBank;
    case 'combined':
      return IconBusinessplan;
    default:
      return IconTransferIn;
  }
};

export const getPaymentMethodName = (name) => {
  switch (name) {
    case 'cash':
      return I18n.get(name, 'Efectivo');
    case 'debit':
      return I18n.get('debitCardSaleModal', 'Tarjeta débito');
    case 'credit':
      return I18n.get('creditCard', 'Tarjeta de crédito');
    case 'transfer':
      return I18n.get(name, 'Transferencia');
    case 'combined':
      return I18n.get(name, 'Combinado');
    case 'wompiNequi':
      return I18n.get('', 'Nequi');
    default:
      return truncate(name, { length: 22 });
  }
};

const HELP_BASE_URL =
  'https://ayuda.alegra.com/es/agrega-metodos-de-pago-personalizados-pos';

const COUNTRY_URL_SUFFIXES = {
  [COUNTRIES.SPAIN]: '-esp',
  [COUNTRIES.MEXICO]: '-mex',
  [COUNTRIES.REPUBLICA_DOMINICANA]: '-dom',
  [COUNTRIES.COLOMBIA]: '-col',
};

export const onHelpClick = ({ country }) => {
  const suffix = COUNTRY_URL_SUFFIXES[country] || '';
  const helpUrl = `${HELP_BASE_URL}${suffix}`;
  window.open(helpUrl, '_blank');
};

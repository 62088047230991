import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
} from '@reduxjs/toolkit';
import * as provisionalConsecutiveDB from '../database/provisionalConsecutiveDB';
import { getInitialState } from '../utils';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
};

export const getAllProvisionalConsecutive = createAsyncThunk(
  'provisionalConsecutive/getAllProvisionalConsecutive',
  async (_, { rejectWithValue, getState }) => {
    try {
      const data = await provisionalConsecutiveDB.getAll();
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addProvisionalConsecutive = createAsyncThunk(
  'provisionalConsecutive/addProvisionalConsecutive',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await provisionalConsecutiveDB.put(data);
      dispatch(refresh());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProvisionalConsecutive = createAsyncThunk(
  'provisionalConsecutive/updateProvisionalConsecutive',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      await provisionalConsecutiveDB.update(data.id, data.changes);
      dispatch(refresh());
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const refresh = createAsyncThunk(
  'provisionalConsecutive/refresh',
  async (_, { rejectWithValue, getState }) => {
    try {
      const data = await provisionalConsecutiveDB.getAll();
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'provisionalConsecutive',
  initialState: getInitialState('provisionalConsecutive', initialState),
  reducers: {
    createProvisionalConsecutive: adapter.addOne,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProvisionalConsecutive.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllProvisionalConsecutive.fulfilled, (state, action) => {
        state.isLoading = false;
        adapter.setAll(state, action.payload.data);
      })
      .addCase(getAllProvisionalConsecutive.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(refresh.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.isLoading = false;
        adapter.setAll(state, action.payload.data);
      })
      .addCase(refresh.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

const { reducer, actions } = appSlice;

export const provisionalConsecutiveSelector = adapter.getSelectors(
  (state) => state.provisionalConsecutive
);

export default reducer;

export const DEFAULT_LOCAL_CLIENTS = {
  mexico: {
    name: 'Público en General',
    identification: 'XAXX010101000',
    regime: 'SIMPLIFIED_REGIME',
    regimeObject: ['SIMPLIFIED_REGIME'],
    address: {
      country: 'MEX',
      zipCode: '12345',
    },
  },
  costaRica: {
    name: 'Cliente de contado',
    identification: { type: 'CF', number: '100000000' },
    identificationObject: { type: 'CF', number: '100000000' },
  },
  panama: {
    name: 'Consumidor final',
    thirdType: 'TAXPAYER',
    kindOfPerson: 'PERSON_ENTITY',
  },
  republicaDominicana: {
    name: 'Consumidor final',
  },
  spain: {
    name: 'Público en general',
    identificationObject: {
      type: 'NIF',
      number: 'X0101010Y',
    },
    identification: {
      type: 'NIF',
      number: 'X0101010Y',
    },
  },
  argentina: {
    name: 'Consumidor final',
    identification: { type: 'DNI', number: '1' },
  },
  colombia: {
    name: { firstName: 'Consumidor', lastName: 'final' },
    nameObject: { firstName: 'Consumidor', lastName: 'final' },
    identification: { type: 'CC', number: '222222222222' },
    identificationObject: { type: 'CC', number: '222222222222' },
  },
  peru: {
    name: 'Cliente general',
    identification: { type: 'DNI', number: '00000000' },
  },
  default: {
    name: 'POS',
  },
};

import { Table, useModal } from '@alegradev/smile-ui-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import invoicesColumns from './columns';
import invoicesActions from './actions';
import { useInvoicesQuery } from '../services/queries';
import { useDispatch, useSelector } from 'react-redux';
import {
  APIGraphqlSelector,
  station as stationSelector,
} from '../../../../selectors/app';
import { get } from 'lodash';
import { hasPermissionTo } from '../../../../selectors/auth';
import { fetchInvoices } from '../../../../reducers/invoices';
import {
  invoices,
  loadingSelector,
  metadataSelector,
} from '../../../../selectors/invoices';
import {
  companySelector,
  country as countrySelector,
  decimalPrecision,
  electronicInvoicing,
  shiftsEnabled,
} from '../../../../selectors/company';
import { getMainCurrency } from '../../../../selectors/currencies';
import InvoiceDetail from '../../detail';
import '../../_invoices.scss';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { IconFileInvoice } from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import { current, isOpen } from '../../../../selectors/shifts';
import VoidInvoice from '../../components/VoidInvoice';

const InvoicesTable = () => {
  const history = useHistory();
  const [invoice, setInvoice] = useState(null);
  const [isSplit, setIsSplit] = useState(false);
  const [metadata, setMetadata] = useState({
    start: 0,
    limit: 20,
    sortDirection: 'desc',
  });
  const [fromTableAction, setFromTableAction] = useState(false);
  const ref = useRef(null);
  const closePartibleFnRef = useRef(null);
  const openPartibleFnRef = useRef(null);
  const togglePartibleFnRef = useRef(null);
  const can = useSelector(hasPermissionTo);
  const elements = useSelector(invoices);
  const requestMetadata = useSelector(metadataSelector);
  const loading = useSelector(loadingSelector);
  const station = useSelector(stationSelector);
  const companyDecimal = useSelector(decimalPrecision);
  const mainCurrency = useSelector(getMainCurrency);
  const dispatch = useDispatch();
  const companyElectronic = useSelector(electronicInvoicing);
  const country = useSelector(countrySelector);
  const company = useSelector(companySelector);
  const isShiftEnabled = useSelector(shiftsEnabled);
  const isShiftOpen = useSelector(isOpen);
  const currentShift = useSelector(current);
  const { openModal: openNewModal } = useModal();

  const columns = useMemo(
    () =>
      invoicesColumns(isSplit, companyDecimal, mainCurrency, companyElectronic),
    [isSplit, companyDecimal, mainCurrency, companyElectronic]
  );

  const fetchData = useCallback(
    async (batch) => {
      if (can('view', 'invoices') && can('index', 'invoices')) {
        await dispatch(fetchInvoices(batch));
      }

      if (ref.current) {
        setMetadata(batch);
      }
    },
    [dispatch, can]
  );

  useEffect(() => {
    ref.current = true;
    window.dataLayer.push({
      event: 'VirtualPageview',
      virtualPageURL: '/invoices',
      virtualPageTitle: 'Invoices',
    });

    return () => {
      ref.current = false;
    };
  }, []);

  useEffect(() => {
    fetchData({
      start: 0,
      limit: 20,
      sortDirection: 'desc',
    });
  }, [fetchData]);

  const handleChangePage = (batch) => {
    fetchData(batch);
  };

  const handleClosePartible = () => {
    if (closePartibleFnRef.current) {
      closePartibleFnRef.current();
    }
  };

  const assignClosePartibleFn = (callback) => {
    closePartibleFnRef.current = callback;
  };

  const openPartibleComponent = (callback, togglePartible) => {
    openPartibleFnRef.current = callback;
    togglePartibleFnRef.current = togglePartible;
  };

  const handleOpenPartible = (row) => {
    if (openPartibleFnRef.current) {
      openPartibleFnRef.current(row);
    }
    if (togglePartibleFnRef.current) {
      togglePartibleFnRef.current();
    }
  };

  useEffect(() => {
    setFromTableAction(false);
  }, [isSplit]);

  const handleInvoiceDetailValue = (value) => {
    return (
      elements.find(
        (item) => item?.numberTemplate?.number == value?.numberTemplate?.number
      ) || value
    );
  };
  return (
    <div className='invoices-table'>
      <Table
        onChange={(value) => {
          handleChangePage({
            limit: get(value, 'pagination.limit', 20),
            start: get(value, 'pagination.start', 0),
            sortDirection: get(value, 'pagination.order_direction', 'desc'),
          });
        }}
        data={elements}
        columns={columns}
        partibleComponent={(value) => (
          <InvoiceDetail
            value={handleInvoiceDetailValue(value)}
            fetchData={fetchData}
            closePartibleFn={handleClosePartible}
            fromTableAction={fromTableAction}
          />
        )}
        emptyComponent={{
          generalIcon: IconFileInvoice,
          title: I18n.get(
            'createYourFirstInvoice',
            '¡Crea tu primera venta en segundos! 📝'
          ),
          description: I18n.get(
            'createYourFirstInvoice.description',
            'Haz una factura y empieza a darle vida a tu punto de venta.'
          ),
          action: {
            label: I18n.get('createAnInvoice', 'Crear venta'),
            handler: () => history?.push('/'),
          },
        }}
        actions={(row) =>
          invoicesActions({
            row,
            country,
            company,
            can,
            currentShift,
            isShiftEnabled,
            isShiftOpen,
            dispatch,
            setInvoice,
            handleOpenPartible,
            setFromTableAction,
            openNewModal,
          })
        }
        loading={loading}
        limit={20}
        hideSearchBar
        simplePagination
        canNextPage={get(requestMetadata, 'hasNextPage', false)}
        onPartible={(value) => {
          setIsSplit(value);
        }}
        minimal
        partible
        closePartibleRow={assignClosePartibleFn}
        openPartibleRow={openPartibleComponent}
        onRowClick={(row) => {
          if (!isSplit) {
            handleOpenPartible(row);
          }
        }}
        emptyStateWithHeader
      />
      <VoidInvoice invoice={invoice} />
    </div>
  );
};

export default InvoicesTable;

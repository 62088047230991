import { Grid } from '@alegradev/smile-ui-react';
import { createContext, useContext, useMemo, useState } from 'react';
import {
  GLOBAL_DISCOUNTS_INITIAL_VALUES,
  GLOBAL_DISCOUNTS_TYPES,
} from './utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setGlobalDiscount,
  updateItem,
} from '../../../../reducers/activeInvoice';
import BigNumber from 'bignumber.js';
import { items } from '../../../../selectors/activeInvoice';
import { closeModal } from '../../../../reducers/modals';
import {
  country as countrySelector,
  decimalPrecision,
} from '../../../../selectors/company';
import { sendNewGTMEvent } from '../../../../reducers/company';
import { COUNTRIES } from '../../../../utils/enums/countries';

const GlobalDiscountsContext = createContext({
  values: GLOBAL_DISCOUNTS_INITIAL_VALUES,
  toggleValues: () => {},
  applyDiscount: () => {},
  canApplyDiscount: false,
  discountValueByAmount: 0,
  error: null,
  toggleError: () => false,
});

const GlobalDiscountsProvider = ({ children }) => {
  const { Wrapper } = Grid;
  const dispatch = useDispatch();
  const invoiceItems = useSelector(items);
  const decimals = useSelector(decimalPrecision);
  const country = useSelector(countrySelector);
  const [values, setValues] = useState(GLOBAL_DISCOUNTS_INITIAL_VALUES);
  const [error, setError] = useState(false);

  const toggleValues = (key, value) => {
    setValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const canApplyDiscount = useMemo(() => {
    const value = Number(values?.value || 0);

    if (country === COUNTRIES.COSTA_RICA && !values.nature) return true;

    if (value === 0 || values?.selectedItems.length === 0) return true;

    if (values.type === GLOBAL_DISCOUNTS_TYPES.AMOUNT) {
      const itemsSubtotal = values.selectedItems.reduce((acc, item) => {
        return acc + item.price;
      }, 0);
      const discountValue = new BigNumber(99.999999)
        .decimalPlaces(decimals, BigNumber.ROUND_DOWN)
        .toNumber();
      const maxDiscount = itemsSubtotal * (discountValue / 100);
      if (value > maxDiscount) return true;
    }

    return false;
  }, [values]);

  const discountValueByAmount = useMemo(() => {
    if (values.type === GLOBAL_DISCOUNTS_TYPES.AMOUNT) {
      const itemsSubtotal = values.selectedItems.reduce((acc, item) => {
        return acc + item.price;
      }, 0);
      return new BigNumber((Number(values.value) / itemsSubtotal) * 100)
        .decimalPlaces(1)
        .toNumber();
    }
    return 0;
  }, [values]);

  const applyDiscount = () => {
    values?.selectedItems.forEach((item) => {
      const getItemIndex = invoiceItems.findIndex((i) => i.id === item.id);
      const percentage =
        values.type === GLOBAL_DISCOUNTS_TYPES.PERCENTAGE
          ? values.value
          : discountValueByAmount;
      if (country === COUNTRIES.COSTA_RICA) {
        dispatch(
          updateItem({
            index: getItemIndex,
            values: {
              discount: {
                discount: new BigNumber(percentage).decimalPlaces(1).toNumber(),
                nature: values.nature,
              },
            },
          })
        );
      } else {
        dispatch(
          updateItem({
            index: getItemIndex,
            values: {
              discount: new BigNumber(percentage).decimalPlaces(1).toNumber(),
            },
          })
        );
      }
    });
    dispatch(closeModal({ modal: 'globalDiscounts' }));
    dispatch(
      setGlobalDiscount({
        discountType: 'global',
        totalItems: values.selectedItems.length,
      })
    );
    dispatch(
      sendNewGTMEvent('pos-global-discount-created', {
        totalItems: values.selectedItems.length,
      })
    );
  };

  const toggleError = (status) => {
    setError(status);
  };

  return (
    <Wrapper
      backgroundColor='#F8FAFC'
      extraClasses='global-discounts'
      direction={{ lg: 'column' }}
    >
      <GlobalDiscountsContext.Provider
        value={{
          values,
          toggleValues,
          applyDiscount,
          canApplyDiscount,
          discountValueByAmount,
          error,
          toggleError,
        }}
      >
        {children}
      </GlobalDiscountsContext.Provider>
    </Wrapper>
  );
};

const useGlobalDiscounts = () => {
  return useContext(GlobalDiscountsContext);
};

export { useGlobalDiscounts, GlobalDiscountsProvider };

import { Icon, Typography } from '@alegradev/smile-ui-react';
import { IconCheck, IconFileSymlink, IconProgress } from '@tabler/icons-react';
import { get } from 'lodash';

export const LegalStatus = ({ invoice }) => {
  const documentType = get(invoice, 'numberTemplate.documentType');
  const globalInvoiceStamp = get(invoice, 'globalInvoice.stamp', null);
  const invoiceStamp = get(invoice, 'stamp.uuid', null);

  const associatedInvoice = get(invoice, 'associatedInvoice', null);

  if (associatedInvoice) {
    const originApp = get(associatedInvoice, 'originApp', '');
    if (originApp === 'SELF_INVOICING') {
      return (
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconFileSymlink} color='#94A3B8' size='small' />
          <Typography type='body-3-regular' text='Autofacturado' />
        </div>
      );
    }
  }

  if (documentType === 'saleTicket' && !!globalInvoiceStamp) {
    return (
      <div className='d-flex align-items-center gap-2'>
        <Icon icon={IconCheck} color='#16A34A' size='small' />
        <Typography type='body-3-regular' text='Facturado' />
      </div>
    );
  } else if (documentType === 'saleTicket' && !globalInvoiceStamp) {
    return <Typography type='body-3-regular' variant='secondary' text='--' />;
  } else if (documentType === 'invoice' && invoiceStamp) {
    return (
      <div className='d-flex align-items-center gap-2'>
        <Icon icon={IconCheck} color='#16A34A' size='small' />
        <Typography type='body-3-regular' text='Timbrado' />
      </div>
    );
  } else if (documentType === 'invoice' && !invoiceStamp) {
    return (
      <div className='d-flex align-items-center gap-2'>
        <Icon icon={IconProgress} color='#94A3B8' size='small' />
        <Typography type='body-3-regular' text='En proceso' />
      </div>
    );
  }

  return '--';
};

export default LegalStatus;

import { toLower } from 'lodash';
import { INVOICE_DOCUMENT_TYPES } from '../utils/constants';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';
import { I18n } from 'aws-amplify';

export const PeruInvoiceTextStrategy = {
  getSubmitButtonName: ({ values, numeration, isElectronic }) => {
    return BaseInvoiceTextStrategy.getSubmitButtonName({
      values,
      numeration,
      isElectronic,
    });
  },
  getNewSubmitButtonName: ({ numeration }) => {
    if (numeration?.isElectronic) {
      return I18n.get('emit', 'Emitir');
    }
    return BaseInvoiceTextStrategy.getNewSubmitButtonName({ numeration });
  },

  getModalTitle: (numeration) => {
    if (numeration?.documentType === INVOICE_DOCUMENT_TYPES.SALE_TICKET) {
      return toLower(I18n.get('newInvoiceModal.title.saleTicket', 'Boleta'));
    }
    return BaseInvoiceTextStrategy.getModalTitle(numeration);
  },
};

import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { provisionalConsecutiveSelector } from '../reducers/provisionalConsecutive';

const provisionalConsecutiveDataSelector = (state) => state.provisionalConsecutive;

export const allProvisionalConsecutiveData = createDraftSafeSelector(
  provisionalConsecutiveSelector.selectAll,
  (data) => data
);

export const loadingSelector = createDraftSafeSelector(
  provisionalConsecutiveDataSelector,
  (data) => data.loading === 'loading'
);

export const provisionalConsecutiveSelectorByIdStation = (idStation) =>
  createDraftSafeSelector(
    provisionalConsecutiveSelector.selectAll,
    (data) => data.filter((provisionalConsecutive) => get(provisionalConsecutive, "idStation", null) === idStation)[0]
  );
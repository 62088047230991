import { Button, useDeviceType, useModal } from '@alegradev/smile-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'aws-amplify';
import { paymentMethod } from '../../../../selectors/activeInvoice';

export const Bottom = ({ primaryButton }) => {
  const { closeModal: closeNewModal } = useModal();
  const paymentMethodSelected = useSelector(paymentMethod);
  const device = useDeviceType();
  const dispatch = useDispatch();

  return (
    <div
      className={`d-flex mt-4 ${device === 'mobile' ? 'mobile' : 'justify-content-end'} bottom-invoice-container`}
    >
      <Button
        text={I18n.get('cancel', 'Cancelar')}
        size='medium'
        type='button'
        emphasis='outline'
        onClick={() => {
          closeNewModal('NewInvoice');
        }}
      />
      {paymentMethodSelected && (
        <Button
          data-testid='btn-send-invoice'
          text={primaryButton?.text}
          disabled={primaryButton?.disabled}
          loading={primaryButton?.loading}
          size='medium'
          type='submit'
        />
      )}
    </div>
  );
};

import { useSelector } from 'react-redux';
import { items } from '../../../../../selectors/activeInvoice';
import { Grid, Table, Toast } from '@alegradev/smile-ui-react';
import { globalDiscountsTableColumns } from './columns';
import { useGlobalDiscounts } from '../GlobalDiscountsProvider';
import { useMemo } from 'react';
import { I18n } from 'aws-amplify';

const GlobalDiscountsTable = () => {
  const { Wrapper } = Grid;
  const { toggleValues, values, discountValueByAmount } = useGlobalDiscounts();
  const invoiceItems = useSelector(items);

  const itemsHasDiscount = invoiceItems?.some((item) => item?.discount > 0);

  const columns = useMemo(
    () =>
      globalDiscountsTableColumns(
        values?.type,
        values?.value,
        values.selectedItems,
        invoiceItems?.length,
        discountValueByAmount
      ),
    [values]
  );

  return (
    <Wrapper extraClasses='global-discounts__table' padding={{ lg: 'md' }}direction={{ lg: 'column' }} gap={10}>
      {itemsHasDiscount && (
        <Toast
          type='neutral'
          shadow={false}
          title=''
          description={I18n.get(
            'itemsAlreadyHasDiscount',
            'Los productos marcados con ⚠️ ya tienen descuentos <br /> aplicados. Al añadir un descuento global, estos se reemplazarán. '
          )}
        />
      )}
      <Table
        columns={columns}
        data={invoiceItems?.filter((item) => item?.id)}
        onSelected={(selectedItems) =>
          toggleValues('selectedItems', selectedItems)
        }
        selectable={values?.value ? true : false}
        hidePagination
        hideToolbar
        limit={invoiceItems?.length || 30}
      />
    </Wrapper>
  );
};

export default GlobalDiscountsTable;

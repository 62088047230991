import { get } from 'lodash';
import { INVOICE_DOCUMENT_TYPES } from '../utils/constants';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';
import { I18n } from 'aws-amplify';

export const CostaRicaInvoiceTextStrategy = {
  getSubmitButtonName: ({ values, numeration, isElectronic }) => {
    if (get(numeration, 'documentType') !== INVOICE_DOCUMENT_TYPES.INVOICE) {
      return I18n.get('sell', 'Vender');
    }

    return BaseInvoiceTextStrategy.getSubmitButtonName({
      values,
      numeration,
      isElectronic,
    });
  },
  getNewSubmitButtonName: ({ numeration }) => {
    if (
      (numeration?.documentType === INVOICE_DOCUMENT_TYPES.SALE_TICKET &&
        numeration?.isElectronic) ||
      (numeration?.documentType === INVOICE_DOCUMENT_TYPES.INVOICE &&
        numeration?.isElectronic)
    ) {
      return I18n.get('emit', 'Emitir');
    }
    return BaseInvoiceTextStrategy.getNewSubmitButtonName({ numeration });
  },

  getModalTitle: (numeration) => {
    return BaseInvoiceTextStrategy.getModalTitle(numeration);
  },
};

import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import _, { get } from 'lodash';

import { toast } from '../../../utils';
import { setSearchProductType as setType } from '../../../reducers/app';
import { openModal } from '../../../reducers/modals';
import { filter, findBarcode, itemByCustomField, resetFilters } from '../../../reducers/items';
import { items as itemsSelector } from '../../../selectors/activeInvoice';
import { addItem } from '../../../reducers/activeInvoice';
import { checkFeatureLimit } from '../../../reducers/membership';
import { searchProductType } from '../../../selectors/app';
import { hasPermissionTo } from '../../../selectors/auth';
import { selectedCategories } from '../../../selectors/itemCategories';

import { isDesktop } from 'react-device-detect';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import { toastHandler, Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { sendNewGTMEvent } from '../../../reducers/company';
import { IconBarcode, IconLoader2, IconPlus, IconSearch, IconX } from '@tabler/icons-react';
import { getBarcodeCustomFieldId } from '../../../selectors/customFields';
import { searchBarcodeLoading } from '../../../selectors/items';
import { idCompanySelector } from '../../../selectors/company';

const Search = () => {
  const searchBarRef = useRef();
  const [filterType, setFilterType] = useLocalStorage('searching-type', 'text');
  const [filterValidated, setFilterValidated] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const categories = useSelector(selectedCategories);
  const isOpen = useSelector((state) =>
    get(state, 'sideModals.editItem.isOpen', false)
  );
  const itemsPercent = useSelector((state) =>
    get(state, 'items.loadingPercent', false)
  );
  const items = useSelector((state) => itemsSelector(state));
  const type = useSelector(searchProductType);
  const can = useSelector(hasPermissionTo);
  const barcodeCustomFieldId = useSelector(getBarcodeCustomFieldId);
  const barcodeItemLoading = useSelector(searchBarcodeLoading)
  const companyId = useSelector(idCompanySelector);
  const online = useConnectionStatus();

  const focusSearchInput = () => {
    if (searchBarRef.current && isDesktop) {
      searchBarRef.current.focus();
    }
  };

  const search = useCallback(
    (value) => {
      dispatch(resetFilters());
      dispatch(filter({ text: value, type, searchCategories: true, limit: 30, start: 0 }));
    },
    [dispatch]
  );

  const resetSearch = useCallback(() => {
    dispatch(resetFilters());
    search('')
  }, [search]);

  const changeType = (type) => {
    setFilterType(type);
    dispatch(setType(type));
    setValue('');
    resetSearch();
  };

  useEffect(() => {
    setValue('');
    focusSearchInput();
  }, [type]);

  useMemo(() => {
    if (items || !isOpen) {
      setTimeout(() => {
        focusSearchInput();
      }, 100);
    }
  }, [items, isOpen]);

  useMemo(() => {
    if (filterType && type && !filterValidated) {
      dispatch(setType(filterType));
      setFilterValidated(true);
    }
  }, [filterType, type, dispatch, filterValidated]);

  useEffect(() => {
    resetSearch();
  }, [resetSearch]);

  const searchBarcode = async () => {
    let item = null;

    item = await findBarcode(value);

    if (online && itemsPercent !== 100 && !item?.id) {
      const apiResponse = await dispatch(
        itemByCustomField({
          customFieldId: barcodeCustomFieldId,
          customFieldValue: value,
          start: 0,
        })
      );

      item = apiResponse?.payload;
    } 

    if (!!item?.id) {
      dispatch(addItem(item));
      setValue('');
    } else {
      toastHandler({
        title: I18n.get('noResultsSearch', 'Búsqueda sin resultados'),
        description: I18n.get('noResultsSearch.message', 'Puedes probar buscando el producto por su nombre o crearlo si aún no lo tienes disponible.'),
        autoClose: 3000,
        width: 450,
        type: 'error',
      });
    }
  };

  // eslint-disable-next-line
  const debounce = useCallback(
    _.debounce((value) => {
      search(value);
    }, 500),
    []
  );

  useEffect(() => {
    if (type.includes('text')) debounce(value);
  }, [categories, value, debounce, type]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (type.includes('barcode')) {
      if (event.key === 'Tab' || event.key === ' ') event.preventDefault();
      if (event.key === 'Enter' || event.key === 'Tab' || event.key === ' ')
        searchBarcode();
    }
  };

  return (
    <div className='search d-flex justify-content-between'>
      <div className='search-bar d-flex'>
        <button
          type='button'
          className={`btn btn-primary ${type.includes('text') ? 'active' : ''}`}
          onClick={() => changeType('text')}
        >
          <Icon icon={IconSearch} extraClass='icon-empty' />
        </button>

        <button
          type='button'
          className={`btn btn-primary ${
            type.includes('barcode') ? 'active' : ''
          }`}
          onClick={() => changeType('barcode')}
        >
          {barcodeItemLoading ? (
            <Icon
              icon={IconLoader2}
              animated
              extraClass='icon'
            />
          ) : (
            <Icon icon={IconBarcode} extraClass='icon-empty' />
          )}
        </button>

        <div className='search-bar-container position-relative w-100 h-100 mr-2'>
          <input
            className='search-bar-input'
            ref={searchBarRef}
            type='text'
            value={value}
            onChange={(event) => handleChange(event)}
            onKeyDown={(event) => handleKeyDown(event)}
            placeholder={
              type.includes('text')
                ? I18n.get('searchItemByText', 'buscar productos')
                : I18n.get('searchItemByBarcode', 'código de barras')
            }
          />

          {!!value && (
            <button
              type='button'
              className='btn button-transparent position-absolute'
              onClick={() => changeType(type)}
            >
              <Icon icon={IconX} extraClass='icon-gray' />
            </button>
          )}
        </div>
      </div>

      <Tooltip
        visible={!can('add', 'items') || !online}
        overlay={online ? I18n.get(
          'userNotAllowed.items.add',
          'no tienes permisos para agregar productos'
        ) : I18n.get('offileCreatingProductBlocked', 'Necesitás tener conexión para crear productos.')}
      >
        <button
          id='add-item-button'
          type='button'
          disabled={!can('add', 'items') || !online}
          className='btn d-flex justify-content-center align-items-center btn-add-item'
          onClick={() =>
            dispatch(
              checkFeatureLimit('items', () => {
                dispatch(openModal({ modal: 'item', params: { origin: 'invoicing' } }));
                dispatch(sendNewGTMEvent('pos-item-started', {
                  origin: 'Invoicing'
                }));
              })
            )
          }
        >
          <div className='text-capitalize-first mr-2 d-none d-md-block'>
            {I18n.get('newItemButton', 'nuevo producto')}
          </div>
          <Icon icon={IconPlus} />
        </button>
      </Tooltip>
    </div>
  );
};

export default Search;

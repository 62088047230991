import { Icon, Typography } from '@alegradev/smile-ui-react';
import { IconAlertCircle, IconFileReport } from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import BottomInvoiceSaved from './BottomInvoiceSaved';
import BigNumber from 'bignumber.js';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { useFormat } from '../../../hooks/useFormat';

export const InvoiceOfflineBody = ({ invoiceCreated, handleClose }) => {
  const { fmt, decimal } = useFormat();
  const isOnline = useConnectionStatus();
  return (
    <div className=''>
      <div className='d-flex flex-column align-items-center justify-content-center px-5 py-5 gap-3 text-center'>
        <Icon icon={IconFileReport} extraClass='icon-secondary' size='huge' />
        {!!invoiceCreated && (
          <Typography
            type='heading-4'
            variant='secondary'
            text={I18n.get(
              'offline_success_invoice_total',
              'Total {{total}}'
            ).replace(
              '{{total}}',
              new BigNumber(invoiceCreated.total).toFormat(decimal, fmt)
            )}
          />
        )}
        <div className='d-flex align-items-center gap-2'>
          <Icon icon={IconAlertCircle} extraClass='icon-amber' />
          <Typography
            type='body-3-bold'
            variant='primary'
            text={
              !isOnline
                ? I18n.get(
                    'offline_success_invoice_title',
                    'Se sincronizará cuando tengas conexión'
                  )
                : I18n.get(
                    'api_intermittences_invoice_title',
                    'Se sincronizará cuando estabilicemos el servicio'
                  )
            }
          />
        </div>
        <Typography
          type='body-3-regular'
          variant='secondary'
          text={
            !isOnline
              ? I18n.get(
                  'offline_success_invoice_description',
                  'Puedes seguir vendiendo y sincronizaremos tus ventas cuando recuperes la conexión.'
                )
              : I18n.get(
                  'api_intermittences_invoice_description',
                  'La factura se creó y quedó pendiente de sincronizarse, la guardaremos por ti cuando finalice la intermitencia. Agradecemos tu comprensión. '
                )
          }
          withHtml
        />
      </div>

      <BottomInvoiceSaved handleClose={handleClose} />
    </div>
  );
};

export default InvoiceOfflineBody;

import { Divider, Table, Typography } from '@alegradev/smile-ui-react';
import { useMemo } from 'react';
import InvoiceDetailGlobalInvoicesTableColumns from './columns';
import { useInvoiceDetail } from '../../context';
import { get } from 'lodash';

const InvoiceDetailGlobalInvoicesTable = () => {
  const { data, displayGlobalInvoiceTooltip, setDisplayGlobalInvoiceTooltip } =
    useInvoiceDetail();
  const globalInvoice = get(data, 'globalInvoice', {});
  const columns = useMemo(
    () =>
      InvoiceDetailGlobalInvoicesTableColumns({
        displayGlobalInvoiceTooltip,
        setDisplayGlobalInvoiceTooltip,
      }),
    [displayGlobalInvoiceTooltip, setDisplayGlobalInvoiceTooltip]
  );
  return (
    <div className='mt-5'>
      <Typography type='label-2' text='Facturas globales' variant='secondary' />
      <Divider />
      <Table columns={columns} data={[globalInvoice]} simple />
    </div>
  );
};

export default InvoiceDetailGlobalInvoicesTable;

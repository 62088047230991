import {
  TableCellRegular,
  Tooltip,
  Typography,
} from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import BigNumber from 'bignumber.js';
import { capitalize, get, truncate } from 'lodash';
import {
  DECIMALS_ACTIVE_COUNTRIES,
  DECIMALS_ACTIVE_IDS,
} from '../../../../hooks/useDecimalsVersionsGroup/hook';
import { COUNTRIES } from '../../../../utils/enums/countries';
import {
  calculateSingleItemValues,
  calculateSingleItemValuesWithDecimals,
} from '../../../../utils';

const invoiceDetailsColumns = (
  decimal,
  fmt,
  company,
  isEditable,
  widthSize
) => {
  const getItemValues = (item) => {
    return DECIMALS_ACTIVE_COUNTRIES.includes(COUNTRIES.MEXICO) ||
      DECIMALS_ACTIVE_IDS.includes(company?.id)
      ? calculateSingleItemValuesWithDecimals(item, decimal, true)
      : calculateSingleItemValues(item, decimal, true);
  };

  return [
    {
      Header: capitalize(I18n.get('product', 'Producto')),
      accessor: 'name',
      width: '30%',
      Cell: ({ row }) => {
        const { original } = row;
        let nameLength = 25;

        if (widthSize > 1500 && widthSize <= 1600) {
          nameLength = 30;
        } else if (widthSize > 1600) {
          nameLength = 35;
        }

        return (
          <div>
            <Tooltip
              visible={get(original, 'name', null).length > nameLength - 1}
              overlay={get(original, 'name', null)}
            >
              <Typography
                type='body-3-regular'
                text={truncate(get(original, 'name'), { length: nameLength })}
              />
            </Tooltip>
            <div className='d-flex gap-2'>
              <Typography
                type='caption-regular'
                text={new BigNumber(get(original, 'price', ''))
                  .toFormat(decimal, fmt)
                  .toString()}
                variant='tertiary'
              />
              {get(original, 'reference', null) && (
                <Typography
                  type='caption-regular'
                  text={' | ' + get(original, 'reference')}
                  variant='tertiary'
                />
              )}
            </div>
          </div>
        );
      },
    },
    {
      Header: capitalize(I18n.get('quantity', 'Cantidad')),
      accessor: 'quantity',
      width: '15%',
      alignment: 'right',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCellRegular value={get(original, 'quantity', 0).toString()} />
        );
      },
    },
    {
      Header: capitalize(I18n.get('discount', 'Descuento')),
      accessor: 'discount',
      width: '15%',
      alignment: 'right',
      Cell: ({ row }) => {
        const { original } = row;
        return <TableCellRegular value={get(original, 'discount', 0) + '%'} />;
      },
    },
    {
      Header: capitalize(I18n.get('tax', 'Impuesto')),
      accessor: 'id',
      width: '15%',
      alignment: 'right',
      Cell: ({ row }) => {
        const { original } = row;
        const itemValues = getItemValues(original);
        let taxesLength = 12;

        if (widthSize > 1500 && widthSize <= 1600) {
          taxesLength = 15;
        } else if (widthSize > 1600) {
          taxesLength = 18;
        }
        return (
          <Tooltip
            visible={get(itemValues, 'taxes', '').length > taxesLength - 1}
            overlay={get(itemValues, 'taxes', '')}
          >
            <TableCellRegular
              value={truncate(get(itemValues, 'taxes', ''), {
                length: taxesLength,
              })}
            />
          </Tooltip>
        );
      },
    },
    {
      Header: capitalize(I18n.get('total', 'Total')),
      accessor: 'total',
      alignment: 'right',
      width: '25%',
      Cell: ({ row }) => {
        const { original } = row;
        const itemValues = getItemValues(original);
        return (
          <TableCellRegular
            value={new BigNumber(get(itemValues, 'total', 0)).toFormat(
              decimal,
              fmt
            )}
          />
        );
      },
    },
  ];
};

export default invoiceDetailsColumns;

import { get, isEmpty } from "lodash";
import { useDispatch } from "react-redux"
import { setTypeToPrint } from "../../reducers/print";
import { sendNewGTMEvent } from "../../reducers/company";
import { Icon, OverflowMenu, Button } from "@alegradev/smile-ui-react";
import { I18n } from "aws-amplify";
import { IconFiles, IconFileText, IconPrinter } from "@tabler/icons-react";

export const PrintButton = ({ invoice }) => {
  const dispatch = useDispatch();
  const haveProvisionalInvoice = !isEmpty(get(invoice, 'provisionalTicket', []));

  const disabledPrintInvoice = !!invoice?.offlineStatus &&
    (invoice?.offlineStatus === 'error' ||
      invoice?.offlineStatus === 'syncing' ||
      (invoice?.offlineStatus === 'pending' &&
        invoice?.statusInProcess === 1));

  if (haveProvisionalInvoice) {
    return (
      <OverflowMenu
        options={[
          {
            content: {
              text: I18n.get('', 'Ticket provisional'),
            },
            id: '1',
            leading: {
              icon: IconFiles,
              type: 'icon'
            },
            onClick: () => {
              dispatch(setTypeToPrint('provisionalInvoice'));
            },
            type: 'option'
          },
          {
            content: {
              text: I18n.get('', 'Factura electrónica'),
            },
            disabled: disabledPrintInvoice,
            id: '2',
            leading: {
              icon: IconFileText,
              type: 'icon'
            },
            onClick: () => {
              if (disabledPrintInvoice) return;

              dispatch(setTypeToPrint('invoice'));
              dispatch(
                sendNewGTMEvent('pos-sale-managed', {
                  id: get(invoice, 'id', ''),
                  action: 'print',
                })
              );
            },
            type: 'option'
          }
        ]}
      >
       <Button
          type='button'
          leftIcon={IconPrinter}
          text={I18n.get('print', 'imprimir')}
        />
      </OverflowMenu>
    )
  }

  return (
    <Button
      type='button'
      onClick={() => [
        dispatch(setTypeToPrint('invoice')),
        dispatch(
          sendNewGTMEvent('pos-sale-managed', {
            id: get(invoice, 'id', ''),
            action: 'print',
          })
        ),
      ]}
      leftIcon={IconPrinter}
      text={I18n.get('print', 'imprimir')}
    />
  )
}
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTotalToCollect } from '../../reducers/totalToCollect';
import ReportsAPI from '../../reducers/balanceByClient';
import { allTotalToCollectData } from '../../selectors/totalToCollect';
import { useConnectionStatus } from '../useConnectionStatus';
import { get } from 'lodash';
import { getPOSClient } from '../../reducers/app';
import { isDefaultClient } from '../../utils';
import { country as countrySelector } from '../../selectors/company';

/**
 * Hook que obtiene el total a cobrar de un cliente.
 * @param {object} props
 * @param {object} props.client - El cliente a obtener el total a cobrar.
 * @returns {object} - Un objeto con las propiedades:
 *   - totalToCollect: El total a cobrar del cliente.
 *   - isTotalToCollectLoading: Un booleano que indica si se est  cargando el total a cobrar.
 *   - getBalanceByClient: Una funci n que actualiza el total a cobrar del cliente.
 */
const useTotalToCollect = ({ client }) => {
  const connectionStatus = useConnectionStatus();
  const [isTotalToCollectLoading, setIsTotalToCollectLoading] = useState(false);
  const [totalToCollect, setTotalToCollect] = useState(null);
  const allTotalToCollect = useSelector(allTotalToCollectData);
  const country = useSelector(countrySelector);

  const dispatch = useDispatch();
  const getLocalBalance = (clientId) => {
    const foundItem = allTotalToCollect.find((item) => item.id === clientId);
    return foundItem ? foundItem.totalToCollect : null;
  };

  const getBalanceByClient = async () => {
    setIsTotalToCollectLoading(true);
    try {
      let amount = null;
      let clientId = get(client, 'id', null);
      let creditLimit = get(client, 'creditLimit', null);
      if (!clientId && isDefaultClient(client, country)) {
        const posClient = await dispatch(getPOSClient());
        clientId = get(posClient, 'id', null);
        creditLimit = get(posClient, 'creditLimit', null);
      }

      if (connectionStatus && clientId && creditLimit !== null) {
        const response = await ReportsAPI.get(
          `client_id=${clientId}&balance_type=totalToCollect`
        );
        amount = get(response, 'data.totalToCollect', null);

        dispatch(
          addTotalToCollect({
            id: clientId,
            totalToCollect: amount,
          })
        );
      } else {
        amount = getLocalBalance(clientId);
      }

      setTotalToCollect(amount);
    } catch (error) {
      console.error('Error en el proceso:', error);
      const clientId = get(client, 'id', null);
      setTotalToCollect(getLocalBalance(clientId));
    } finally {
      setIsTotalToCollectLoading(false);
    }
  };

  return { totalToCollect, isTotalToCollectLoading, getBalanceByClient };
};

export default useTotalToCollect;

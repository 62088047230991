import { useSelector } from "react-redux"
import { companySelector, idCompanySelector } from "../../../../selectors/company"
import { station as stationSelector } from "../../../../selectors/app"
import { get } from "lodash"
import QRCode from "qrcode.react"

const URL_PORTAL = process.env.REACT_APP_PROVISONAL_TICKET_PORTAL || 'https://almost-alegra-pos-portal.alegra.com/'

const ProvisionalQR = ({ invoice }) => {
  const company = useSelector(companySelector);
  const companyId = useSelector(idCompanySelector)
  const station = useSelector(stationSelector)

  const provisionalTicketId = get(invoice, 'provisionalTicket.id')
  const stationId = get(station, 'id')
  const typeRaw = get(invoice, 'numberTemplate.documentType', 'invoice')
  const type = typeRaw !== 'invoice' ? 'docPOS' : 'invoice'

  const qr = `${URL_PORTAL}/${companyId}/${stationId}/${provisionalTicketId}/${get(company, 'email', '')}/${type}`

  return (
    <div className="d-flex justify-content-center mt-4">
      <QRCode
        value={qr}
        renderAs='svg'
        size={156}
        level="M"
      />
    </div>
  )
}

export default ProvisionalQR
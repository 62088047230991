import { capitalize, get, isEmpty, method } from 'lodash';
import Header from '../common/Header';
import { I18n } from '@aws-amplify/core';
import Nequi from '../../svg/paymentMethods/Nequi';
import ArrowRight from '../../svg/settings/paymentMethods/ArrowRight';
import Typography from '../../common/Typography';
import { useDeviceType } from '../../../hooks/useDeviceType';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from '../../../reducers/modals';
import PaymentMethod from './ConfigureMethods/PaymentMethod';
import { Icon, ModalProvider } from '@alegradev/smile-ui-react';
import {
  allPaymentMethods,
  loadingSelector as loadingPaymentMethodsSelector,
} from '../../../selectors/paymentMethods';
import { useEffect, useState } from 'react';
import { COUNTRIES } from '../../../utils/enums/countries';
import {
  country as countrySelector,
  externalProviderState,
} from '../../../selectors/company';
import Wompi from '../../../assets/images/wompi.png';
import { IconBook, IconLoader2, IconPlus } from '@tabler/icons-react';
import { onHelpClick } from './utils';

const PaymentMethods = () => {
  const dispatch = useDispatch();
  const deviceType = useDeviceType();
  const loadingPaymentMethods = useSelector(loadingPaymentMethodsSelector);
  const paymentMethods = useSelector(allPaymentMethods);
  const country = useSelector(countrySelector);
  const nequiState = useSelector(externalProviderState('nequi'));
  const wompiState = useSelector(externalProviderState('wompi'));

  const [mainList, setMainList] = useState([]);
  const [secondaryList, setSecondaryList] = useState([]);
  const [displayIntegrations, setDisplayIntegrations] = useState(false);

  useEffect(() => {
    let main = [];
    let secondary = [];
    if (!isEmpty(paymentMethods)) {
      main.push(paymentMethods.find((method) => method.name === 'cash') || []);
      main.push(
        paymentMethods.find((method) => method.name === 'credit') || []
      );
      main.push(
        paymentMethods.find((method) => method.name === 'transfer') || []
      );
    }

    paymentMethods.forEach((method) => {
      if (
        get(method, 'name') === 'cash' ||
        get(method, 'name') === 'credit' ||
        get(method, 'name') === 'transfer'
      ) {
        //mainList.push(method);
      } else {
        secondary.push(method);
      }
    });

    setMainList(main);
    setSecondaryList(secondary);
  }, [paymentMethods]);

  useEffect(() => {
    if (nequiState === 'unregistered' || wompiState === 'unregistered') {
      setDisplayIntegrations(true);
    }
  }, [nequiState, wompiState]);
  const handleOpenModal = () => {
    dispatch(openModal({ modal: 'paymentMethod' }));
  };

  const onRedirectPaymentMethod = (method) => {
    window.open(
      `${process.env.REACT_APP_ALEGRA_SMILE}integrations/e-payments/${method}/configuration`,
      '_blank'
    );
  };

  return (
    <ModalProvider>
      <div className={` container p-5 `}>
        <div className='d-flex flex-column'>
          <Header
            title={I18n.get('paymentMethods', 'métodos de pago')}
            subtitle={
              <p className='h5 text-muted text-capitalize-first'>
                {I18n.get(
                  'paymentMethodsSubtitle',
                  'Configura y activa los métodos de pago que tus clientes podrán usar para pagar en tu punto de venta.'
                )}
              </p>
            }
            actions={
              <div className='d-flex gap-3'>
                <div className='d-flex gap-3'>
                  <button
                    type='button'
                    className='btn btn-sm-default-outline pl-3 pr-3'
                    onClick={() => onHelpClick({ country })}
                  >
                    <Icon icon={IconBook} className='mr-3' />
                    <Typography
                      type='label-2'
                      text={I18n.get('help', 'Ayuda')}
                    />
                  </button>
                </div>
                <div className='d-flex'>
                  <button
                    type='button'
                    className='btn btn-submit pl-3 pr-3'
                    onClick={handleOpenModal}
                  >
                    <Icon icon={IconPlus} className='icon-white mr-2' />
                    {I18n.get('newMethod', 'Nuevo método')}
                  </button>
                </div>
              </div>
            }
          />

          <div className='card bg-white shadowp-4 rounded-xlg'>
            <div className='d-flex flex-column p-5 '>
              <div className='d-flex flex-column'>
                <Typography
                  type='body-2-bold'
                  text={I18n.get('configuredMethods', 'Métodos configurados')}
                />
                {loadingPaymentMethods ? (
                  <div className='d-flex justify-content-center py-5'>
                    <Icon
                      icon={IconLoader2}
                      animated
                      extraClass=' icon-primary icon x2'
                    />
                  </div>
                ) : (
                  <div
                    className={`d-flex flex-wrap payment-method__container my-4`}
                  >
                    {isEmpty(paymentMethods) && (
                      <div className='d-flex justify-content-center align-items-center py-5'>
                        <Typography
                          type='body-3-regular'
                          text={I18n.get(
                            'noPaymentMethods',
                            'No hay métodos de pago configurados'
                          )}
                        />
                      </div>
                    )}
                    {mainList.map((method) => (
                      <PaymentMethod
                        id={method.idLocal}
                        method={method.name}
                        status={method.status}
                        instructions={method.instructions}
                        logo={method.logo}
                        qr={method.qr}
                        key={method.id}
                        main={true}
                      />
                    ))}
                    {secondaryList.map((method) => (
                      <PaymentMethod
                        id={method.idLocal}
                        method={method.name}
                        status={method.status}
                        instructions={method.instructions}
                        logo={method.logo}
                        qr={method.qr}
                        key={method.id}
                      />
                    ))}
                    {nequiState === 'active' && (
                      <PaymentMethod
                        method={'Nequi'}
                        status={'active'}
                        main={true}
                      />
                    )}
                    {wompiState === 'active' && (
                      <>
                        <PaymentMethod
                          method={'QR Bancolombia'}
                          status={'active'}
                          main={true}
                        />
                        <PaymentMethod
                          method={'wompiNequi'}
                          status={'active'}
                          main={true}
                        />
                      </>
                    )}
                  </div>
                )}
              </div>
              {deviceType !== 'mobile' &&
                country === COUNTRIES.COLOMBIA &&
                displayIntegrations && (
                  <div className='d-flex flex-column'>
                    <Typography
                      type='body-2-bold'
                      text={I18n.get('integrations', 'Integraciones')}
                    />
                    <div className='integrations__container d-flex flex-wrap mt-4'>
                      {wompiState !== 'active' && (
                        <div className='payment-method__card integration'>
                          <div className='d-flex flex-column p-4 h-100 justify-content-between'>
                            <Typography
                              type='body-3-bold'
                              text={capitalize(I18n.get('wompi', 'Wompi'))}
                            />
                            <div>
                              <Typography
                                type='caption-regular'
                                text={I18n.get(
                                  'wompi.description',
                                  'Recibe tus pagos desde Bancolombia y Nequi'
                                )}
                                variant='tertiary'
                              />
                            </div>
                            <div className='mx-auto'>
                              <img src={Wompi} height={64} width={145} />
                            </div>
                            <div className='d-flex justify-content-center'>
                              <button
                                className='btn w-100 integrations__btn'
                                onClick={() => onRedirectPaymentMethod('wompi')}
                              >
                                <Typography type='label-2' text='Configurar' />
                                <ArrowRight />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {nequiState !== 'active' && (
                        <div className='payment-method__card integration'>
                          <div className='d-flex flex-column p-4 h-100 justify-content-between'>
                            <Typography
                              type='body-3-bold'
                              text={I18n.get('nequi', 'Nequi')}
                            />
                            <div>
                              <Typography
                                type='caption-regular'
                                text={I18n.get(
                                  'nequi.description',
                                  'Integra tu cuenta de Nequi con tu número de cédula '
                                )}
                                variant='tertiary'
                              />
                            </div>
                            <div className='mx-auto'>
                              <Nequi height={64} width={64} />
                            </div>
                            <div className='d-flex justify-content-center'>
                              <button
                                className='btn w-100 integrations__btn'
                                onClick={() => onRedirectPaymentMethod('nequi')}
                              >
                                <Typography type='label-2' text='Configurar' />
                                <ArrowRight />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          {deviceType === 'mobile' &&
            country === COUNTRIES.COLOMBIA &&
            displayIntegrations && (
              <div className='card bg-white shadowp-4 rounded-xlg mt-4'>
                <div className='d-flex flex-column p-5'>
                  <Typography
                    type='body-2-bold'
                    text={I18n.get('integrations', 'Integraciones')}
                  />
                  {wompiState !== 'active' && (
                    <div className='integrations_container d-flex mt-4'>
                      <div className='payment-method__card_mobile'>
                        <div className='mx-auto d-flex p-3 integrations__info'>
                          <img
                            style={{ objectFit: 'contain' }}
                            src={Wompi}
                            width={40}
                          />
                          <div>
                            <Typography
                              type='body-3-bold'
                              text={capitalize(I18n.get('wompi', 'Wompi'))}
                            />
                            <Typography
                              type='caption-regular'
                              text={I18n.get(
                                'wompi.description',
                                'Recibe tus pagos desde Bancolombia y Nequi'
                              )}
                              variant='tertiary'
                            />
                          </div>
                        </div>
                        <div className='d-flex justify-content-center pl-3 pr-3'>
                          <button
                            className='btn w-100 integrations__btn_mobile'
                            onClick={() => onRedirectPaymentMethod('wompi')}
                          >
                            <Typography
                              type='caption-regular'
                              text='Configurar'
                            />
                            <ArrowRight mobile={true} />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  {nequiState !== 'active' && (
                    <div className='integrations_container d-flex mt-4'>
                      <div className='payment-method__card_mobile'>
                        <div className='mx-auto d-flex p-3 integrations__info'>
                          <Nequi />
                          <div>
                            <Typography
                              type='body-3-bold'
                              text={I18n.get('nequi', 'Nequi')}
                            />
                            <Typography
                              type='caption-regular'
                              text={I18n.get(
                                'nequi.description',
                                'Integra tu cuenta de Nequi con tu número de cédula '
                              )}
                              variant='tertiary'
                            />
                          </div>
                        </div>
                        <div className='d-flex justify-content-center pl-3 pr-3'>
                          <button
                            className='btn w-100 integrations__btn_mobile'
                            onClick={() => onRedirectPaymentMethod('nequi')}
                          >
                            <Typography
                              type='caption-regular'
                              text='Configurar'
                            />
                            <ArrowRight mobile={true} />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
        </div>
      </div>
    </ModalProvider>
  );
};

export default PaymentMethods;

import { get } from 'lodash';
import {
  country as countrySelector,
  isOnlyInvoicingPlan,
} from '../selectors/company';
import { InvoiceCountryStrategyFactory } from '../strategies/Invoice/InvoiceCountryStrategyFactory';
import { checkClient, createInvoice, syncOffline } from './activeInvoice';
import { put } from '../database/invoicesDB';
import { removeInvoice } from './pendingInvoices';
import { setPrint } from './print';
import { handleError } from '../utils/errors';

/**
 * Processes and dispatches actions to pay an invoice.
 *
 * This function utilizes a strategy pattern to handle country-specific
 * invoice preparation and validation. It checks the client, validates
 * the invoice, and creates the invoice if required. If not, it stores
 * the invoice offline. It also handles printing and error management.
 *
 * @param {Object} props - The properties required to process the invoice.
 * @param {boolean} isElectronicOffline - A flag indicating if the invoice is electronic and the user can do offline the invoice.
 * @returns {Promise<void>} - A promise that resolves when the invoice is processed.
 * @throws Will throw an error if any operation within the process fails.
 */
export const payInvoice = (props, isElectronicOffline) => {
  return async (dispatch, getState) => {
    try {
      const state = getState();
      const onlyInvoicingPlan = isOnlyInvoicingPlan(state);
      const country = countrySelector(state);

      const invoiceStrategy =
        InvoiceCountryStrategyFactory.createStrategy(country);
      let invoice = await invoiceStrategy.prepareInvoice(props, state);
      invoice = await dispatch(checkClient(invoice));
      invoiceStrategy.validateInvoice({ invoice, state });

      if (!!get(invoice, 'stamp.generateStamp') || onlyInvoicingPlan) {
        invoice = await dispatch(createInvoice(invoice, isElectronicOffline));
      } else {
        await put(invoice, () => dispatch(syncOffline()));
      }
      await dispatch(removeInvoice());
      dispatch(setPrint({ type: 'invoice', value: invoice }));
    } catch (error) {
      if (!!get(error, 'createProvisionalTicket', null)) {
        throw {
          createProvisionalTicket: !!get(error, 'createProvisionalTicket', null),
          message: handleError(error)
        };
      } else {
        throw handleError(error);
      }
    }
  };
};

import { Grid } from '@alegradev/smile-ui-react';
import React from 'react';

import renderCommom from './CommomFields';
import renderStatementAttached from './StatementAttached';
import renderEconomicActivity from './EconomicActivity';

const AdvancedData = (props) => {
  const { Row } = Grid;
  return (
    <Row spacing='lg'>
      {renderEconomicActivity(props)}

      {renderCommom(props)}

      {renderStatementAttached(props)}
    </Row>
  );
};

export default AdvancedData;

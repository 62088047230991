import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { I18n } from '@aws-amplify/core';
import { useSelector, useDispatch } from 'react-redux';
import { capitalize, get, includes } from 'lodash';

import { setClient, setSettings } from '../../../reducers/activeInvoice';
import { hasPermissionTo } from '../../../selectors/auth';
import { stationInvoiceNumeration } from '../../../selectors/app';
import {
  activePriceLists,
  getMainPriceList,
} from '../../../selectors/priceLists';
import {
  getElectronicNumerations,
  getInvoiceNumerations,
  getOnlyInvoiceNumerations,
  groupedNumerations as groupedNumerationsSelector,
} from '../../../selectors/numerations';
import {
  priceList,
  numeration,
  client as clientSelector,
  economicActivity as economicActivitySelector,
} from '../../../selectors/activeInvoice';
import {
  companySelector,
  country as countrySelector,
  regime as regimeSelector,
} from '../../../selectors/company';
import { economicActivitiesSelector } from '../../../selectors/auth';
import { checkNumerationBlock } from './utils';

import Select from '../../common/Select';
import useElectronicPayrollStatus from '../../../hooks/useElectronicPayrollStatus/hook';
import Typography from '../../common/Typography';
import { stationPriceList as stationPriceListSelector } from '../../../selectors/app';
import { COUNTRIES } from '../../../utils/enums/countries';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { MEXICO_DEFAULT_RFC } from '../../countriesData/mexico/rfc';
import { basicPOSClientData } from '../../../utils';
import { isMexicoFeAvailable } from '../../../utils/deploys/mexicoFeDeployGroups';
import { useApiAvailable } from '../../../hooks/useApiAvailable';
import { useElectronicInvoiceOfflineGroup } from '../../../hooks/useElectronicInvoiceOfflineGroup';
import { Tooltip } from '@alegradev/smile-ui-react';

import { Icon } from '@alegradev/smile-ui-react';
import { IconStar } from '@tabler/icons-react';

const FastOptions = () => {
  const isOnline = useConnectionStatus()
  const { isElectronicOffline } = useElectronicInvoiceOfflineGroup();
  const isApiAvailable = useApiAvailable();
  const dispatch = useDispatch();
  const can = useSelector(hasPermissionTo);
  const priceLists = useSelector(activePriceLists);
  const currentNumeration = useSelector(stationInvoiceNumeration);
  const numerations = useSelector(getInvoiceNumerations);
  const invoicePriceList = useSelector(priceList);
  const invoiceNumeration = useSelector(numeration);
  const mainPriceList = useSelector(getMainPriceList);
  const economicActivities = useSelector(economicActivitiesSelector);
  const country = useSelector(countrySelector);
  const aditionalSettings = useSelector(
    (state) => state.aditionalSettings.settings
  );
  const economicActivity = useSelector(economicActivitySelector);
  const client = useSelector(clientSelector);
  const groupedNumerations = useSelector(groupedNumerationsSelector);
  const [numerationBlocked, setNumerationBlocked] = useState(null);
  const { availableCountries, isActive } = useElectronicPayrollStatus();
  const { registryDate } = useSelector(companySelector);
  const noViewNumerationPermission = !can('index', 'number-templates');
  const stationPriceList = useSelector(stationPriceListSelector);
  const regime = useSelector(regimeSelector);

  const economicActivityValue = useCallback(() => {
    if (!economicActivity) {
      const activity = economicActivities.find(
        (activity) => activity.main === true
      );

      return activity;
    }
    return economicActivity;
  }, [economicActivity, economicActivities]);

  useEffect(() => {
    if(!invoiceNumeration && country === COUNTRIES.PERU) {
      if(currentNumeration) {
        dispatch(setSettings({ numeration: currentNumeration }))
      }
    }
  }, [invoiceNumeration,currentNumeration, country, dispatch])

  useEffect(() => {
    if (country !== COUNTRIES.PERU) return;
    if (!regime?.includes('RUS')) return;

    const currentDefaultNumeration = currentNumeration || invoiceNumeration;
    const saleTicketNumeration = numerations?.find(
      (numeration) => numeration.documentType === 'saleTicket'
    );

    if (!currentDefaultNumeration) {
      if (saleTicketNumeration) {
        dispatch(setSettings({ numeration: saleTicketNumeration }));
      }
    }

    if (currentDefaultNumeration?.documentType !== 'saleTicket') {
      if (saleTicketNumeration) {
        dispatch(setSettings({ numeration: saleTicketNumeration }));
      }
    }
  }, [country, currentNumeration, regime, numerations, dispatch]);

  const isFeMexActive = useMemo(() => {
    return isMexicoFeAvailable(country, isActive);
  },[country, registryDate, isActive])

  useEffect(() => {
    if(!isFeMexActive) return;
    if (country !== COUNTRIES.MEXICO) return;
    const TICKET_NUMERATION_TYPE = 'saleTicket';
    if (client && client?.identification === MEXICO_DEFAULT_RFC) return;
    if (invoiceNumeration?.documentType !== TICKET_NUMERATION_TYPE) return;

    const ticketNumeration = find(numerations, {
      documentType: TICKET_NUMERATION_TYPE,
    });

    const defaultClient = basicPOSClientData(
      country,
      ticketNumeration ? ticketNumeration : currentNumeration
    );

    dispatch(setClient(defaultClient));
  }, [invoiceNumeration,currentNumeration, isActive, country, numerations, dispatch, registryDate])
  
  const setDefaultPriceList = useCallback(() => {
    if (!stationPriceList) {
      return;
    }

    const priceList = priceLists.find(
      (priceList) => Number(priceList.id) === Number(stationPriceList.id)
    );

    if (!priceList) {
      dispatch(setSettings({ mainPriceList }));
      return;
    }

    dispatch(setSettings({ priceList }));
  }, [priceLists, mainPriceList, stationPriceList, dispatch, isFeMexActive]);

  useEffect(() => {
    setDefaultPriceList();
  }, [setDefaultPriceList]);

  const getNumerationBlock = useCallback(() => {
    setNumerationBlocked(checkNumerationBlock(invoiceNumeration ?? currentNumeration, country, registryDate, false, isOnline, isApiAvailable, isElectronicOffline))
  }, [invoiceNumeration, currentNumeration, country, registryDate,isOnline, isApiAvailable])

  const getNumerationOptions = useMemo(() => {
    if (
      country === COUNTRIES.MEXICO &&
      client?.identification !== MEXICO_DEFAULT_RFC
    ) {
      return groupedNumerations;
    }

    if (availableCountries.includes(country)) {
      return groupedNumerations;
    }
    return numerations;
  }, [numerations, groupedNumerations, client, country]);

  useEffect(() => {
    getNumerationBlock();
  }, [getNumerationBlock]);

  if (!get(priceLists, 'length') && !get(numerations, 'length')) return null;

  return (
    <div className='px-3 pt-3 pb-1 d-sm-flex align-items-center form-row m-0 d-none'>
      {!!get(priceLists, 'length') && aditionalSettings['priceList'] && (
        <div
          style={{ paddingRight: '.9rem' }}
          className='col-6 pl-3'
          data-testid="select-price-list-invoice"
        >
          <p className='text-capitalize-first fast-option-title'>
            {I18n.get('priceList', 'lista de precio')}
          </p>
          <Select
            value={!!invoicePriceList ? invoicePriceList : stationPriceList}
            options={
              !can('index', 'price-lists') || !can('view', 'price-lists')
                ? []
                : priceLists
            }
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            backspaceRemovesValue={false}
            placeholder={capitalize(I18n.get('priceList', 'lista de precio'))}
            onChange={(value) => dispatch(setSettings({ priceList: value }))}
          />
        </div>
      )}

      {!!get(numerations, 'length') && (
        <div data-testid="numeration-select" style={{ paddingLeft: '.9rem' }} className='col-6 pr-3'>
          <p className='text-capitalize-first fast-option-title'>
            {I18n.get('numeration', 'Numeración')}
          </p>

          <Select
            value={!!invoiceNumeration ? invoiceNumeration : currentNumeration}
            options={getNumerationOptions}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            backspaceRemovesValue={false}
            placeholder={capitalize(I18n.get('numeration', 'Numeración'))}
            onChange={(value) => dispatch(setSettings({ numeration: value }))}
            hasError={!!numerationBlocked}
            tooltip={{
              active: !!numerationBlocked,
              overlay: (
                <Typography
                  type='caption-bold'
                  variant='white'
                  text={I18n.get(
                    numerationBlocked?.name,
                    'La numeración se quedo sin consecutivos.'
                  )}
                />
              ),
            }}
          />
        </div>
      )}

      {noViewNumerationPermission && (
        <div
          style={{ paddingLeft: '.9rem' }}
          className='col-6 pr-3'
        >
          <p className='text-capitalize-first fast-option-title'>
            {I18n.get('numeration', 'Numeración')}
          </p>
          <Tooltip
            overlay={I18n.get(
              'youNeedToAskForPermissions.view-numerations',
              'Solicita el permiso a un administrador para ver el listado de numeraciones.'
            )}
            placement='top'
          >
            <Select
              options={[]}
              backspaceRemovesValue={false}
              placeholder={capitalize(I18n.get('numeration', 'Numeración'))}
              onChange={(value) => dispatch(setSettings({ numeration: value }))}
              isDisabled
              hasError={true}
            />
          </Tooltip>
          <div className='pt-1'>
            <Typography
              type='label-3'
              variant='danger'
              text={I18n.get(
                'youNeedPermissionsToViewList',
                'Necesitas permiso para ver el listado'
              )}
            />
          </div>
        </div>
      )}

      {!!get(economicActivities, 'length') &&
        aditionalSettings['economicActivity'] && (
          <div className='col-6 pl-3'>
            <p className='text-capitalize-first fast-option-title'>
              {I18n.get('economicActivity', 'Actividad económica')}
            </p>
            <Select
              value={economicActivityValue()}
              getOptionLabel={(props) => (
                <div className='m-0'>
                  <p className='m-0 d-flex align-items-center'>
                    {props.code} - {props.value}{' '}
                    {props.main && (
                      <span>
                        <Icon icon={IconStar} extraClass='svg-icon-primary' size='small' />
                      </span>
                    )}
                  </p>
                </div>
              )}
              options={economicActivities}
              onChange={(value) =>
                dispatch(setSettings({ economicActivity: value }))
              }
            />
          </div>
        )}
    </div>
  );
};

export default FastOptions;

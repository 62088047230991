import BigNumber from 'bignumber.js';
import { calculateInvoiceReceived } from '../util';
import { useEffect, useState } from 'react';

const useInvoiceChange = (values, total) => {
  const [payment, setPayment] = useState(null);
  const [change, setChange] = useState(new BigNumber(0));
  const [received, setReceived] = useState(null);

  useEffect(() => {
    const cash = new BigNumber(!!values.cash ? +values.cash : 0);
    const debit = new BigNumber(!!values.debit ? +values.debit : 0);
    const credit = new BigNumber(!!values.credit ? +values.credit : 0);
    const transfer = new BigNumber(!!values.transfer ? +values.transfer : 0);
    let invoiceReceived = calculateInvoiceReceived(values);

    setReceived(invoiceReceived);
    setPayment(cash.plus(debit).plus(credit).plus(transfer));
  }, [values]);

  useEffect(() => {
    setChange(total.minus(payment));
  }, [received, payment, total]);

  return { payment, change, received };
};

export default useInvoiceChange;

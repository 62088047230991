import { useDispatch, useSelector } from 'react-redux';
import Modal from '../../common/Modal';
import DesktopBanner from './assets/650x476.jpg';
import MobileBanner from './assets/330x327.jpg';
import DesktopBannerSpain from './assets/650x476-spain.jpg';
import MobileBannerSpain from './assets/330x327-spain.jpg';
import DesktopBannerCri from './assets/650x476-cri.jpg';
import MobileBannerCri from './assets/330x327-cri.jpg';
import { get } from 'lodash';
import { closeModal } from '../../../reducers/modals';
import { Button, useDeviceType } from '@alegradev/smile-ui-react';
import { IconX } from '@tabler/icons-react';
import useNotification from '../../../hooks/useNotification/hook';
import {
  EVENT_NAME,
  FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION,
  getNotificationsAttempts,
  MIGRATE_TO_ALEGRA_2025_ATTEMPT_BASE,
} from './utils';
import {
  country as countrySelector,
  notificationsSelector,
} from '../../../selectors/company';
import { COUNTRIES } from '../../../utils/enums/countries';
import { sendNewGTMEvent } from '../../../reducers/company';
export const MigrationToAlegra2025 = () => {
  const country = useSelector(countrySelector);
  const isOpen = useSelector((state) =>
    get(state, 'modals.MigrationToAlegra2025.isOpen', false)
  );
  const deviceType = useDeviceType();
  const dispatch = useDispatch();
  const {
    viewed: firstViewed,
    setViewed: setFirstViewed,
    createAndCloseNotification,
  } = useNotification({
    notificationName: FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION,
  });
  const notifications = useSelector(notificationsSelector);
  const notificationsAttempts = getNotificationsAttempts(notifications);

  const close = () => {
    if (!firstViewed) {
      setFirstViewed();
    } else {
      const attemptNumber = notificationsAttempts.length
        ? notificationsAttempts.length + 1
        : 1;

      createAndCloseNotification(
        `${MIGRATE_TO_ALEGRA_2025_ATTEMPT_BASE}-${attemptNumber}`
      );
    }
    dispatch(
      sendNewGTMEvent(EVENT_NAME, {
        action: 'skipped',
      })
    );
    dispatch(closeModal({ modal: 'MigrationToAlegra2025' }));
  };

  const getBanner = () => {
    if (country === COUNTRIES.COSTA_RICA) {
      return deviceType === 'mobile' ? MobileBannerCri : DesktopBannerCri;
    }
    if (country === COUNTRIES.SPAIN) {
      return deviceType === 'mobile' ? MobileBannerSpain : DesktopBannerSpain;
    }

    return deviceType === 'mobile' ? MobileBanner : DesktopBanner;
  };

  const handleSeePricesClick = () => {
    dispatch(
      sendNewGTMEvent(EVENT_NAME, {
        action: 'started-payment',
      })
    );
    window.open(
      `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`,
      '_blank'
    );
  };

  const handleTyClick = () => {
    window.open(
      'https://docs.google.com/document/d/e/2PACX-1vSwAYIwmwXUt_NuUmm-Ry4H8MOV14ke7LjVENT14xCj1WcGrCqZEb-XcpQES1PTWx4Mz7AeS02GU4VU/pub',
      '_blank'
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={close}
      includeHeader={false}
      className={'modal__migrate-to-alegra '}
    >
      {country === COUNTRIES.COSTA_RICA ? (
        <div className='close-button-cri' onClick={close}></div>
      ) : (
        <Button onlyIcon={IconX} className='close-button' onClick={close} />
      )}

      <img src={getBanner()} />
      <a
        className={`see-prices-button ${country === COUNTRIES.SPAIN ? 'spain' : ''} ${deviceType === 'mobile' ? 'mobile' : ''}`}
        onClick={handleSeePricesClick}
      ></a>
      <a
        className={`terms-and-conditions ${deviceType === 'mobile' ? 'mobile' : ''} ${country === COUNTRIES.SPAIN ? 'spain' : ''}`}
        onClick={handleTyClick}
      ></a>
    </Modal>
  );
};

export default MigrationToAlegra2025;

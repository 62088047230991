import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import { renderField } from '../../fields/V0/Fields';
import UseField from '../components/UseField';
import { Divider, Typography, useDeviceType } from '@alegradev/smile-ui-react';
import SellerField from '../components/SellerField';
import FastOptionButton from '../components/FastOptionButton';
import {
  countryDenominations,
  getFastOptionFontSize,
  getFastOptionFormated,
} from '../components/utils';
import { useFormat } from '../../../../hooks/useFormat';
import AnnotationField from '../components/AnnotationField';

const fastOptions = (code, total) => {
  const denominations = countryDenominations(code);
  const aux = Math.floor(total / denominations[0]);
  return [total, denominations[0] * (aux + 1), denominations[0] * (aux + 2)];
};

const NewCash = ({ values, total, currencyCode, anotationMaxLimit }) => {
  const cashFastOptions = fastOptions(currencyCode, total.toNumber());
  const deviceType = useDeviceType();
  const { decimal, fmt } = useFormat();

  return (
    <>
      <div className='col-sm-12 form-row pl-2 pr-0'>
        <div className='col-sm-12 col-md-6'>
          <Field
            name='cash'
            className={`col-sm-12 p-0 mb-5`}
            type='number'
            label={
              <Typography
                type='label-2'
                text={capitalize(
                  I18n.get('cashValueInput', 'valor del pago en efectivo')
                )}
                variant='secondary'
              />
            }
            placeholder={I18n.get('enterTheValue', 'Ingresa el valor')}
            required
          >
            {(props) => (
              <>
                {renderField({
                  ...props,
                  fontSize: '16px',
                  height: '46px',
                  fontColor: '#334155',
                  paddingLeft: '10px',
                })}

                {deviceType !== 'mobile' && (
                  <Divider text={I18n.get('fastOptions', 'opciones rápidas')} />
                )}

                {!!total && (
                  <div
                    className={`col-sm-12 p-0 d-flex form__invoice-cash-options ${deviceType === 'mobile' ? 'mobile' : ''}`}
                  >
                    {cashFastOptions.map((option, index) => {
                      const fastOptionFormatted = getFastOptionFormated({
                        cashOption: option,
                        decimal,
                        fmt,
                      });
                      return (
                        <FastOptionButton
                          key={index}
                          onChange={props.input.onChange}
                          cash={option}
                          text={fastOptionFormatted}
                          fontSize={getFastOptionFontSize(fastOptionFormatted)}
                        />
                      );
                    })}
                  </div>
                )}
              </>
            )}
          </Field>
        </div>

        <div
          className={`col-sm-12 col-md-6 px-0 ${deviceType === 'mobile' ? '' : 'form__invoice-advanced-data'}`}
        >
          <UseField values={values} />
          <div className='d-flex justify-content-center'>
            <SellerField />
          </div>
          <div
            className={`col-sm-12 ${deviceType === 'mobile' ? 'p-0' : 'pl-2 pr-0'}`}
          >
            <AnnotationField
              anotationMaxLimit={anotationMaxLimit}
              type='textarea'
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default NewCash;

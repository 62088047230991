export const GLOBAL_DISCOUNTS_TYPES = {
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
};

export const GLOBAL_DISCOUNTS_INITIAL_VALUES = {
  selectedItems: [],
  type: GLOBAL_DISCOUNTS_TYPES.PERCENTAGE,
  value: null,
  nature: null
};

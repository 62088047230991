import { I18n } from 'aws-amplify';
import { get, lowerCase } from 'lodash';
import { handleError } from '../../../utils/errors';
import { NavLink } from 'react-router-dom';

const ERROR_CODES = {
  TERMINAL_WITH_SHIFTS: 'P01005',
  TERMINAL_WITH_INVOICES: 'P01006',
};
export const getTerminalErrorTitle = (error) => {
  const errorCode = get(error, 'errors[0].errorInfo.code', '');
  if (errorCode === ERROR_CODES.TERMINAL_WITH_SHIFTS) {
    return I18n.get('terminalHasAssociatedShifts', 'Tiene turnos asociados');
  }

  if (errorCode === ERROR_CODES.TERMINAL_WITH_INVOICES) {
    return I18n.get('terminalHasAssociatedInvoices', 'Tiene ventas asociadas');
  }
  return I18n.get('stationDeletedInterrupted', 'Eliminación interrumpida');
};

export const getTerminalErrorMessage = (error) => {
  const errorCode = get(error, 'errors[0].errorInfo.code', '');
  if (errorCode === ERROR_CODES.TERMINAL_WITH_SHIFTS) {
    return I18n.get(
      'terminalHasAssociatedShifts.message',
      'Solo puedes eliminar terminales en las que no hayas usado la función de turnos.'
    );
  }

  if (errorCode === ERROR_CODES.TERMINAL_WITH_INVOICES) {
    return I18n.get(
      'terminalHasAssociatedInvoices.message',
      'Solo puedes eliminar terminales en las que no hayas realizado ventas.'
    );
  }
  return handleError(error).replace(
    'terminal',
    lowerCase(I18n.get('station', 'terminal'))
  );
};

export const getVisibleTooltip = ({
  can,
  station,
  currentStation,
  onlyInvoicing,
  stations,
}) => {
  if (
    !can('delete', 'pos-station') ||
    (station.id !== currentStation.id && onlyInvoicing)
  ) {
    return true;
  }

  if (station.id === currentStation.id || stations.length === 1) {
    return true;
  }
  return false;
};

export const getTooltipContent = ({
  can,
  onlyInvoicing,
  currentStation,
  station,
  stations,
}) => {
  if (!onlyInvoicing && !can('delete', 'pos-station')) {
    return (
      <p className='text-capitalize-first text-white'>
        {I18n.get(
          'userNotAllowed.pos-station.view',
          'no tienes permisos para ver detalle de terminales'
        )}
      </p>
    );
  }
  if (onlyInvoicing) {
    return (
      <p className='text-capitalize-first text-white'>
        {I18n.get(
          'onlyInvoicingTooltip',
          'Para disfrutar de esta funcionalidad, conoce los planes todo incluido'
        )}{' '}
        <NavLink
          to={{
            pathname: `${process.env.REACT_APP_ALEGRA_SMILE}subscriptions/products/alegra-pos/plans`,
          }}
          className='btn-link'
          target='_blank'
        >
          {I18n.get('here', 'aquí')}
        </NavLink>
      </p>
    );
  }

  if (stations.length === 1) {
    return (
      <p className='text-capitalize-first text-white'>
        {I18n.get(
          'thisIsTheUniqueTerminal',
          'Esta es tu única terminal activa. Para eliminarla, primero crea otra que puedas usar para vender.'
        )}
      </p>
    );
  }

  if (station.id === currentStation.id) {
    return (
      <p className='text-capitalize-first text-white'>
        {I18n.get(
          'thisIsTheTerminalYouAre',
          'Esta es tu terminal actual. Es necesario que cambies a otra para poder eliminarla.'
        )}
      </p>
    );
  }
};

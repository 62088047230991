import React from 'react'
import { I18n } from '@aws-amplify/core';
// import { graphqlOperation } from '@aws-amplify/api';
import { Field, Form } from 'react-final-form';
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

// import * as mutations from '../../../graphql/mutations'
// import { formError } from '../../../utils/errors';
import { country as countrySelector, electronicInvoicing, localSettings as localSettingsSelector, settings as settingsSelector } from '../../../selectors/company'
// import { setUser } from '../../../reducers/auth';
// import { APIGraphqlSelector, stationFENumberTemplate } from '../../../selectors/app';
import { renderCheckbox, renderExternalActivateButton } from '../../forms/fields/V1/Fields';
// import { openModal } from '../../../reducers/modals';
// import { isAdmin as isAdminSelector } from '../../../selectors/auth';
// import { sendGTMEvent } from '../../../reducers/company';
// import { getSaleTicketNumerations } from '../../../selectors/numerations';

import Header from '../common/Header';
// import { getColombiaUVT } from '../../../utils/enums/colombiaUVT';
import { isElectronicPOSDocumentAvailableSelector } from '../../../selectors/numerations';
import { SwitchTicketOffline } from './SwitchTicketOffline';
import { useElectronicInvoiceOfflineGroup } from '../../../hooks/useElectronicInvoiceOfflineGroup';

// import { Icon } from '@alegradev/smile-ui-react';
// import { COUNTRIES } from '../../../utils/enums/countries';

const Invoices = () => {
  // const dispatch = useDispatch()
  const localSettings = useSelector(localSettingsSelector)
  const settings = useSelector(settingsSelector)
  const { isInGroup } = useElectronicInvoiceOfflineGroup();
  // const feNumeration = useSelector(stationFENumberTemplate)
  const isElectronic = useSelector(electronicInvoicing)
  // const isAdmin = useSelector(isAdminSelector)
  // const country = useSelector(countrySelector)
  // const colombiaUVT = getColombiaUVT();
  // const APIGraphql = useSelector(APIGraphqlSelector);
  // const saleTicketnumerations = useSelector(getSaleTicketNumerations);
  const isElectronicPOSDocumentAvailable = useSelector(isElectronicPOSDocumentAvailableSelector);

  // const shouldRender5UVTField = () => {
  //   const isActiveAutomationElectronicInvoice = get(settings, 'isActiveAutomationElectronicInvoice', false);
  //   const isElectronicPOSDocumentAvailable = useSelector(isElectronicPOSDocumentAvailableSelector);

  //   if (country !== COUNTRIES.COLOMBIA) return false;
  //   if (!isActiveAutomationElectronicInvoice) return false;
  //   if (isElectronicPOSDocumentAvailable) return false;

  //   return true;
  // };

  // const handleFE = async (value, onChange) => {
  //   if (!isAdmin) {
  //     dispatch(openModal({ modal: 'limitedUser5UVT', params: { isActivate: value } }));
  //     return;
  //   }

  //   if (!value)
  //     dispatch(openModal({
  //       modal: 'deactivate5UVT', params: {
  //         onConfirm: () => {
  //           onChangeFE(value);
  //           onChange(value);
  //         }
  //       }
  //     }));
  //   else {
  //     onChangeFE(value);
  //     onChange(value);
  //     dispatch(sendGTMEvent("uvt-feature-enabled", {
  //       creationOrigin: "from pos settings",
  //     }));
  //     dispatch(openModal({ modal: 'stationSetting5UVT' }));
  //   }
  // }

  // const onChangeFE = async (value) => {
  //   try {
  //     const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
  //       user: {
  //         company: {
  //           settings: {
  //             isActiveAutomationElectronicInvoice: value,
  //             automationElectronicInvoiceIdResolution: get(feNumeration, 'id', null),
  //           },
  //           localSettings: {
  //             automationFEEnabled: value
  //           }
  //         }
  //       }
  //     }))
  //     if (!!get(response, 'data.updateUser.id', null))
  //       dispatch(setUser(get(response, 'data.updateUser')))
  //     else
  //       throw new Error("error");
  //   } catch (error) {
  //     return formError(error, I18n.get('updateInvoicesError', 'hubo un error al actualizar la configuración de facturas'))
  //   }
  // }

  // const onChangeDocPOS = async (value) => {
  //   try {
  //     const response = await APIGraphql(graphqlOperation(mutations.updateUser, {
  //       user: {
  //         company: {
  //           settings: {
  //             showSaleTickets: value,
  //           }
  //         }
  //       }
  //     }))
  //     if (!!get(response, 'data.updateUser.id', null))
  //       dispatch(setUser(get(response, 'data.updateUser')))
  //     else
  //       throw new Error("error");
  //   } catch (error) {
  //     return formError(error, I18n.get('updateInvoicesError', 'hubo un error al actualizar la configuración de facturas'))
  //   }
  // }

  return (
    <div className="container p-5">
      <div className="d-flex flex-column">
        <Form
          onSubmit={() => { }}
          initialValues={{
            automationFEEnabled: get(localSettings, 'automationFEEnabled', false),
            equivalentDocumentPOS: get(settings, 'showSaleTickets', false)
          }}
          keepDirtyOnReinitialize
        >
          {({ handleSubmit }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Header
                title={`${I18n.get('invoicesSettingTitle', 'Mis facturas')}`}
                titleClassName='inter-semibold'
                subtitle={
                  <p>
                    {I18n.get('invoicesSettingSubtitle', 'configura tu forma de facturar y el documento que usas para registrar las ventas de tu negocio.')}
                  </p>
                }
                subtitleClassName='inter-regular'
              />

              <div className="v1 card borderless-card d-flex flex-column bg-white my-3 px-4 pt-4 pb-0 rounded-xlg">
                <div className="form-body w-100 p-3 overflow-hidden">
                  <div className="form-body__fields form-row justify-content-start">
                    <Field
                      name="electronicPOSDocument"
                      className="col-12"
                      component={renderExternalActivateButton}
                      label={I18n.get('', 'Documento equivalente POS electrónico')}
                      help={
                        <p className='mb-0'>
                          {I18n.get('', 'Emite tus documentos POS electrónicos para registrar las ventas de tu negocio.')}
                        </p>
                      }
                      onClick={() => window.open(`${process.env.REACT_APP_ALEGRA_SMILE}pos-wizard/redirect/alegra-pos`)}
                      activated={isElectronicPOSDocumentAvailable}
                    />
                    <Field
                      name="electronicInvoicing"
                      className="col-12"
                      component={renderExternalActivateButton}
                      label={I18n.get('electronicInvoicing', 'Facturación electrónica')}
                      help={
                        <p className='mb-0'>
                          {I18n.get('eletronicInvoicingHelp', 'Emite tus facturas electrónicas y cumple con todos los requisitos de la DIAN.')}
                        </p>
                      }
                      onClick={() => window.open(process.env.REACT_APP_ALEGRA_FE_LINK)}
                      activated={isElectronic}
                    />
                    
                    {(isInGroup && isElectronic) && (
                      <SwitchTicketOffline />
                    )}

                    {/* <Field
                      name="equivalentDocumentPOS"
                      className="col-12"
                      component={renderCheckbox}
                      label={I18n.get('equivalentDocumentPOS', 'Documentos equivalente POS')}
                      help={
                        <p className='mb-0'>
                          {I18n.get('equivalentDocumentPOSHelp', 'Crea documentos equivalentes para registrar y soportar las ventas de tu negocio.')}
                        </p>
                      }
                      disabled={get(settings, 'showSaleTickets', false) && saleTicketnumerations.length > 0}
                      disabledHelp={I18n.get('equivalentDocumentPOSTooltip', 'Debes mantener activa esta opción porque estás usando numeraciones de documento equivalente POS.')}
                      onClick={(value, onChange) => {
                        onChangeDocPOS(value);
                        onChange(value);
                      }}
                    /> */}
                    {/* {shouldRender5UVTField() && (
                      <Field
                        name="automationFEEnabled"
                        className="col-12"
                        component={renderCheckbox}
                        label={I18n.get('automationFEEnabled', 'Cambio automático de documento POS a factura electrónica ')}
                        help={
                          <>
                            <p className='mb-5'>
                              {I18n.get('automationFEEnabledHelp', `Crea una factura electrónica en lugar de documento POS siempre que una venta supere $${colombiaUVT?.UVT_FORMATED} antes de IVA.`)}
                            </p>
                            <div className='w-100 d-flex flex-column flex-sm-row justify-content-lg-start justify-content-center align-items-center' style={{ gap: "0.8rem" }}>
                              <button
                                type='button'
                                className='btn-action'
                                onClick={() => window.open('https://ayuda.alegra.com/es/configura-sistema-pos-para-ventas-superiores-5-uvt-colombia', '_blank')}
                              >
                                <p className='m-0 p-0 inter-medium'>
                                  {I18n.get("learnHowItWorks", "Aprender cómo funciona")}
                                </p>
                                <Icon icon={IconExternalLink} extraClass='ml-3' />
                              </button>
                              {(!feNumeration && isElectronic) && (
                                <button
                                  type='button'
                                  className='btn-action'
                                  onClick={() => window.open(`${process.env.REACT_APP_ALEGRA}resolution`, '_blank')}
                                >
                                  <Icon icon={IconConfigure} extraClass='ml-3' />
                                  <p className='m-0 p-0 inter-medium'>
                                    {I18n.get("configureNumberTemplate", "Configurar numeración")}
                                  </p>
                                </button>
                              )}
                              {(country === 'colombia' && get(localSettings, 'automationFEEnabled', false)) && (
                                <button
                                  className='btn-action'
                                  onClick={() => dispatch(openModal({ modal: 'editFENumerationTerminal' }))}
                                >
                                  <Icon icon={IconEdit} extraClass='ml-3' />
                                  <p className='m-0 p-0 inter-medium'>
                                    {I18n.get("editNumberTemplate", "Editar numeración")}
                                  </p>
                                </button>
                              )}
                            </div>
                          </>
                        }
                        disabled={!isElectronic || !feNumeration}
                        activeHelp={I18n.get('', 'Esta función estará disponible hasta el próximo 1 de julio o hasta que actives el documento POS electrónico.')}
                        // disabledHelp={!isElectronic ? I18n.get('automationFEEnabledTooltip', 'Para usar esta funcionalidad debes tener activa tu facturación electrónica') : I18n.get('electronicNumerationNeeded', 'Para usar esta funcionalidad debes configurar una numeración electrónica.')}
                        onClick={handleFE}
                      />
                    )} */}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  )
}

export default Invoices;
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as mutations from '../../../graphql/mutations';

import { toastHandler } from '@alegradev/smile-ui-react';
import { APIGraphqlSelector, changePrincipalWarehouseBill, firstBillCreated } from '../../../selectors/app';
import { get, uniqueId } from 'lodash';
import { billKeys } from './utils';
import { graphqlOperation, I18n } from 'aws-amplify';
import { handleError } from '../../../utils/errors';
import { sendNewGTMEvent } from '../../../reducers/company';

export const useCreateUpdateBillMutation = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const api = useSelector(APIGraphqlSelector);
  const firstBill = useSelector(firstBillCreated)
  const principalWarehouseBillChanged = useSelector(changePrincipalWarehouseBill)

  return useMutation(async (values) => {
    const isEditable = get(values, 'id', false);

    if (isEditable && !values?.id) {
      throw new Error(
        I18n.get(
          'billIdRequired',
          'No se ha encontrado el id de la factura a editar'
        )
      );
    }
    const itemsBill = get(values, 'purchases.items', []);
    const TOAST_ID = `create-bill-${uniqueId()}`;
    try {
      const message = [
        isEditable
          ? I18n.get('billContact', 'Editando factura...')
          : I18n.get('billContact', 'Creando factura...'),
        isEditable
          ? I18n.get('billUpdated', 'Factura editada')
          : I18n.get('billCreated', 'Factura creada'),
        isEditable
          ? I18n.get(
              'billUpdatedDescription',
              'La factura ha sido editado correctamente'
            )
          : I18n.get(
              'billCreatedDescription',
              'La factura ha sido creado correctamente'
            ),
      ];

      toastHandler({
        id: TOAST_ID,
        title: message[0],
        type: 'neutral',
        loader: true,
      });

      const mutation = isEditable ? mutations.updateBill : mutations.createBill;

      const data = await api(graphqlOperation(mutation, { bill: values }));
      const dataValues = isEditable
        ? get(data, 'data.updateBill', {})
        : get(data, 'data.createBill', {});

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: message[1],
        description: message[2],
        type: 'success',
      });

      if (isEditable) {
        queryClient.setQueriesData(billKeys.all, (items) => {
          if (Array.isArray(items?.data) && items?.data?.length > 0) {
            return {
              ...items,
              data: items?.data?.map((item) => {
                if (item?.id === values?.id) {
                  return {
                    ...item,
                    ...dataValues,
                  };
                }
                return item;
              }),
            };
          }
        });

        queryClient.removeQueries({ queryKey: [billKeys.bill, values?.id] });

      } else {
        queryClient.setQueriesData([billKeys.all], (oldData) => {
          return {
            data: [dataValues, ...(oldData?.data || [])],
            metadata: {
              ...oldData?.metadata,
              total: oldData?.metadata?.total + 1,
            },
          };
        });

        sendNewGTMEvent('pos-bill-created', {
          id: dataValues?.id,
          TotalItems: itemsBill.length,
          First: firstBill,
          OtherWharehouseselect: principalWarehouseBillChanged,
          error: null,
        });
      }

      history.push(`/bills/bill/${dataValues?.id}/detail`);

      return get(data, 'data', {});
    } catch (error) {
      const errorMessage = isEditable
        ? I18n.get('errorUpdatingBill', 'Error editando la factura')
        : I18n.get('errorCreatingBill', 'Error creando la factura');

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: errorMessage,
        description: handleError(error),
        type: 'error',
      });

      if (!isEditable) {
        sendNewGTMEvent('pos-bill-created', {
          id: null,
          TotalItems: itemsBill.length,
          First: firstBill,
          OtherWharehouseselect: principalWarehouseBillChanged,
          error: handleError(error),
        });
      }
    }
  });
};

export const useDeleteBillMutation = () => {
  const queryClient = useQueryClient();
  const api = useSelector(APIGraphqlSelector);
  const history = useHistory();

  return useMutation(async (id) => {
    const TOAST_ID = `deleteBill-${id}`;
    try {
      const deleteMessage = [
        I18n.get('deletingBill', 'Eliminando factura...'),
        I18n.get('billDeleted', 'Factura eliminada'),
        I18n.get(
          'billDeletedDescription',
          'La factura ha sido eliminada correctamente'
        ),
      ];

      toastHandler({
        id: TOAST_ID,
        title: deleteMessage[0],
        type: 'neutral',
        loader: true,
      });

      const data = await api(graphqlOperation(mutations.deleteBill, { id }));

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: deleteMessage[1],
        description: deleteMessage[2],
        type: 'success',
      });

      queryClient.setQueriesData([billKeys.all], (old) => ({
        data: old?.data?.filter((od) => od?.id !== id),
        metadata: {
          ...old?.metadata,
          total: old?.metadata?.total - 1,
        },
      }));

      queryClient.removeQueries({ queryKey: [billKeys.bill, id] });

      history.push('/bills');
      return get(data, 'data', {});
    } catch (error) {
      const errorMessage = I18n.get(
        'errorDeletingBill',
        'Error eliminando factura'
      );

      toastHandler({
        id: TOAST_ID,
        update: true,
        title: errorMessage,
        description: handleError(error),
        type: 'error',
      });
    }
  });
};

import { I18n } from '@aws-amplify/core';
import { canVoid } from '../../detail/utils';
import {
  IconNote,
  IconPencil,
  IconPrinter,
  IconRotate,
  IconShare,
} from '@tabler/icons-react';
import { openModal } from '../../../../reducers/modals';
import { canEdit, canSendOrPrint } from '../../utils';
import { sendNewGTMEvent } from '../../../../reducers/company';
import { setPrint, setTypeToPrint } from '../../../../reducers/print';
import { capitalize, get } from 'lodash';
import { setInvoice as setEditInvoice } from '../../../../reducers/editInvoice';
import { syncOffline } from '../../../../reducers/activeInvoice';
import ShareInvoice from '../../../../components/modals/ShareInvoice/ShareInvoice';

function invoicesActions({
  row,
  country,
  company,
  isShiftEnabled,
  isShiftOpen,
  currentShift,
  can,
  dispatch,
  setInvoice,
  handleOpenPartible,
  setFromTableAction,
  openNewModal,
}) {
  const invoice = row;
  const actions = [];

  if (
    !!invoice.offlineStatus &&
    (invoice.offlineStatus === 'error' ||
      invoice.offlineStatus === 'syncing' ||
      (invoice.offlineStatus === 'pending' && invoice.statusInProcess === 1))
  ) {
    actions.push(
      {
        icon: IconPencil,
        title: I18n.get('editInvoice', 'Editar factura'),
        action: () => {
          handleOpenPartible(invoice);
          dispatch(setEditInvoice(invoice));
          dispatch(openModal({ modal: 'editInvoice' }));
        },
      },
      {
        icon: IconRotate,
        title: I18n.get('sync', 'Sincronizar'),
        action: () => {
          dispatch(syncOffline(false, true));
        },
      }
    );
  } else {
    if (canEdit({ invoice, can })) {
      actions.push({
        icon: IconPencil,
        title: I18n.get('editInvoice', 'Editar factura'),
        action: () => {
          handleOpenPartible(invoice);
          setFromTableAction(true);
        },
      });
    }
  }

  if (
    !invoice.offlineStatus &&
    canVoid({
      invoice,
      country,
      company,
      isShiftEnabled,
      isShiftOpen,
      currentShift,
      can,
    })
  ) {
    actions.push({
      icon: IconNote,
      title: I18n.get('voidInvoice', 'Anular venta'),
      action: () => {
        setInvoice(invoice);
        dispatch(openModal({ modal: 'voidInvoice' }));
      },
    });
  }

  if (canSendOrPrint({ invoice, country })) {
    actions.push(
      {
        icon: IconShare,
        title: I18n.get('share', 'Compartir'),
        action: () => {
          openNewModal({
            component: ShareInvoice,
            name: 'ShareInvoice',
            title: capitalize(
              I18n.get('share', 'Compartir') +
                ' ' +
                I18n.get('invoice', 'factura')
            ),
            size: 'large',
            props: { invoice },
          });
        },
      },
      {
        icon: IconPrinter,
        title: I18n.get('print', 'Imprimir'),
        action: () => {
          dispatch(setPrint({ type: 'invoice', value: invoice }));
          dispatch(setTypeToPrint('invoice'));
          dispatch(
            sendNewGTMEvent('pos-sale-managed', {
              id: get(invoice, 'id', ''),
              action: 'print',
            })
          );
        },
      }
    );
  }

  return actions;
}

export default invoicesActions;

import {
  Button,
  ModalProvider,
  useDeviceType,
  useModal,
} from '@alegradev/smile-ui-react';
import { IconPrinter } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { setTypeToPrint } from '../../../reducers/print';
import { sendNewGTMEvent } from '../../../reducers/company';
import { get } from 'lodash';
import { currency as companyCurrencySelector } from '../../../selectors/company';
import useNpsSurvey from '../../../hooks/useNpsSurvey/hook';

export const Bottom = ({ primaryButton, secondaryButton, invoiceCreated }) => {
  const { closeModal: closeNewModal } = useModal();
  const device = useDeviceType();
  const dispatch = useDispatch();
  const companyCurrency = useSelector(companyCurrencySelector);
  const { handleNps } = useNpsSurvey();

  const handlePrint = () => {
    dispatch(setTypeToPrint('invoice'));
    dispatch(
      sendNewGTMEvent('invoice-printed', {
        isElectronicInvoice: get(
          invoiceCreated,
          'numberTemplate.isElectronic',
          false
        ),
        customerPaymentMethod: get(invoiceCreated, 'paymentMethod'),
        currency: get(
          invoiceCreated,
          'currency.code',
          get(companyCurrency, 'code')
        ),
        invoiceTotal: get(invoiceCreated, 'total'),
        totalItems: get(invoiceCreated, 'items').length,
      })
    );

    dispatch(
      sendNewGTMEvent('pos-invoice-shared', {
        medium: 'print',
      })
    );
  };

  return (
    <div
      className={`d-flex mt-4 ${device === 'mobile' ? 'mobile' : 'justify-content-end'} bottom-invoice-container`}
    >
      <Button
        text={secondaryButton?.text}
        size='medium'
        type='button'
        emphasis='outline'
        leftIcon={IconPrinter}
        onClick={() => handlePrint()}
      />
      <ModalProvider>
        <Button
          data-testid='new-invoice-btn'
          text={primaryButton?.text}
          onClick={() => {
            closeNewModal('NewInvoiceSaved');
            handleNps();
          }}
          size='medium'
          type='button'
        />
      </ModalProvider>
    </div>
  );
};

export default Bottom;

import { toastHandler } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useEffect, useRef, useState } from 'react';
import { replaceAndParse } from '../../utils';
import { showNewStockFeature } from '../../components/home/Items/utils';
import { useSelector } from 'react-redux';
import { country as countrySelector } from '../../selectors/company';

const useOutOfStockIndicator = ({ itemsOutOfStock }) => {
  const displayedErrorToastRef = useRef(false);
  const [disableByStock, setDisableByStock] = useState(false);
  const country = useSelector(countrySelector);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (
        !displayedErrorToastRef.current &&
        itemsOutOfStock.length > 0 &&
        showNewStockFeature({ country })
      ) {
        displayedErrorToastRef.current = true;
        toastHandler({
          type: 'error',
          title: I18n.get(
            'thereAreProductsOutOfStock',
            'Hay productos agotados'
          ),
          description: replaceAndParse(
            I18n.get(
              'productsOutOfStock.description',
              'Revisa si tienes compras por registrar o edita los productos marcados con {} y actívales la opción de "venta en negativo"'
            ),
            ['⊝']
          ),
          shadow: true,
          width: '50rem',
          autoClose: 2000,
        });
      } else if (
        displayedErrorToastRef.current &&
        itemsOutOfStock.length === 0
      ) {
        displayedErrorToastRef.current = false;
      }

      setDisableByStock(itemsOutOfStock.length > 0);
    }, 500);

    return () => {
      clearTimeout(timeoutId);
      displayedErrorToastRef.current = false;
    };
  }, [itemsOutOfStock]);

  return { disableByStock };
};

export default useOutOfStockIndicator;

import React, { useCallback, useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, debounce } from 'lodash';

import { Grid } from '@alegradev/smile-ui-react';
import { renderField } from '../../../../../components/forms/fields/V2';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import { costaRicaEconomicActivities } from '../../../../../components/countriesData/costaRica/economicActivities';

const renderCostaRicaEconomicActivity = () => {
  const { Col } = Grid;

  const [filteredOptions, setFilteredOptions] = useState(costaRicaEconomicActivities.slice(0, 20));

  const handleInputChange = useCallback(
    debounce((inputValue) => {
      const filtered = costaRicaEconomicActivities.filter((option) =>
        option.value.toLowerCase().includes(inputValue.toLowerCase())
      ).slice(0, 20);
      setFilteredOptions(filtered);
    }, 300),
    [costaRicaEconomicActivities]
  );

  return (
    <Col xs={12}>
      <Field
        name='economicActivity'
        component={renderField}
        placeholder={I18n.get('select', 'Seleccionar')}
        fieldType='rselect'
        label={capitalize(I18n.get('economicActivity', 'Actividad económica'))}
        options={filteredOptions}
        onInputChange={handleInputChange}
        getOptionLabel={(option) => option?.value}
        getOptionValue={(option) => option}
        required
      />
    </Col>
  );
};

const renderEconomicActivity = ({ country }) => {
  switch (country) {
    case COUNTRIES.COSTA_RICA:
      return renderCostaRicaEconomicActivity();
    default:
      return null;
  }
};

export default renderEconomicActivity;

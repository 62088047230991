import { cleanObject } from '../../../../../utils/cleanObject';
import { trimObjectStrings } from '../../../../../utils/trimObject';
import { calculateSingleItemValuesWithoutDecimals } from '../../../../../utils';
import { format } from 'date-fns';

const taxTransformer = (tax) => ({
  id: tax.id,
  name: tax.name,
  percentage: tax.percentage,
  description: tax.description,
  type: tax.type,
  status: tax.status,
  idCategoryToBePaid: tax?.categoryToBePaid?.id,
  idCategoryFavorable: tax?.categoryFavorable?.id,
});

const productTransformer = (product, decimal) => {
  if (!product) return null;
  if (Object.keys(product).length === 0) return null;
  const values = calculateSingleItemValuesWithoutDecimals(
    product.product,
    decimal
  );
  return {
    id: product.product.id,
    name: product.product.name,
    observations: product.product.observations,
    price: values.price.toNumber(),
    quantity: values.quantity,
    tax: Array.isArray(product.product?.tax)
      ? product.product?.tax?.map((item) => taxTransformer(item))
      : [taxTransformer(product?.product?.tax)],
    discount: values.discount,
    total: values.total.toNumber(),
    subtotal: values.subtotal.toNumber(),
  };
};

export const transform = (values, decimal, idStation) => {
  const provider = {
    id: values?.provider?.id,
  };
  let currency = {};
  if (values?.currency?.status === 'active') {
    currency = {
      code: values?.currency?.code,
      exchangeRate: values?.currency?.exchangeRate,
    };
  }
  const purchases = {
    items:
      values?.products
        ?.map((item) => productTransformer(item, decimal))
        ?.filter((item) => item) || [],
  };
  const numberTemplate = {
    number: values?.billNumber,
  };
  let newValues = {
    id: values?.id,
    date: format(values?.billBuyDate, 'yyyy-MM-dd'),
    dueDate: format(values?.expiredDate, 'yyyy-MM-dd'),
    observations: values?.notes,
    provider,
    warehouse: values?.warehouse?.id,
    costCenter: values?.costCenter?.id,
    numberTemplate,
    purchases,
    currency,
  };

  if (
    values?.bankAccounts &&
    Array.isArray(values?.bankAccounts) &&
    values?.bankAccounts.length > 0
  ) {
    newValues = {
      ...newValues,
      payments: values?.bankAccounts.map((item) => ({
        idStation,
        client: provider.id,
        date: format(new Date(), 'yyyy-MM-dd'),
        type: 'out',
        paymentMethod: 'cash',
        categories: [
          {
            id: item.bankAccount.id,
            price: item.bankAccount.amount,
            quantity: 1,
          },
        ],
      }))[0],
    };
  }

  return trimObjectStrings(cleanObject(newValues));
};

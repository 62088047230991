import { Container, PageHeading } from '@alegradev/smile-ui-react';
import { useHistory } from 'react-router-dom';
import { I18n } from 'aws-amplify';
import { memo, useEffect } from 'react';

import Table from './table/Table';
import { IconPlus } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { hasPermissionTo } from '../../../selectors/auth';
import { firstTimeInBills } from '../../../selectors/app';
import { setFirstTimeInBills } from '../../../reducers/app';
import { sendNewGTMEvent } from '../../../reducers/company';

function Main() {
  const can = useSelector(hasPermissionTo);
  const history = useHistory();
  const firstTimeInBillsSelector = useSelector(firstTimeInBills);
  const dispatch = useDispatch();

  useEffect(() => {
    if(firstTimeInBillsSelector) {
      dispatch(setFirstTimeInBills(false));
    }

    sendNewGTMEvent('pos-index-bill-visited', { First: firstTimeInBillsSelector })
  }, [firstTimeInBillsSelector]);

  if(!can('index', 'bills')) return null;

  return (
    <Container marginTop={30}>
      <PageHeading
        title={I18n.get('bills', 'Compras')}
        description={I18n.get(
          'billDescription',
          'Registra tus compras de productos y mantén actualizadas las cantidades en tu inventario.'
        )}
        actions={[
          {
            leftIcon: IconPlus,
            text: I18n.get('newBill', 'Nueva compra'),
            action: () => history.push('/bills/bill'),
            disabled: !can('add', 'bills'),
          },
        ]}
      />
      <Table />
    </Container>
  );
}

export default memo(Main);

import { useModal } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useNotification from '../../../hooks/useNotification/hook';
import { useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
} from '../../../selectors/company';
import illustration from '../illustrations/Cart-Logo.svg';
import { COUNTRIES } from '../../../utils/enums/countries';
import { isAfter } from 'date-fns';

const MODAL_NAME = 'billFeature';

const BillFeatureModal = () => {
  const { openModal, closeModal, openendModals } = useModal();
  const { registryDate } = useSelector(companySelector);
  const [checked, setChecked] = useState(false);
  const country = useSelector(countrySelector);
  const { viewed, setViewed } = useNotification({
    notificationName: `pos-bill-feature-notification`,
  });
  const history = useHistory();

  useEffect(() => {
    if (checked) return;
    if ([COUNTRIES.PERU, COUNTRIES.REPUBLICA_DOMINICANA].includes(country))
      return;
    if (isAfter(new Date(registryDate), new Date('2025/01/24'))) return;
    if (viewed || openendModals.includes(MODAL_NAME)) return;
    setChecked(true);
    openModal({
      name: MODAL_NAME,
      title: I18n.get('billFeatureTitle', '¡Registra compras desde POS!'),
      illustration,
      type: 'feature',
      description: I18n.get(
        'billFeatureDescription',
        'Ahora puedes generar facturas de compra fácil y rápido desde Alegra POS para que sigas teniendo actualizado tu inventario'
      ),
      onClose: () => setViewed(),
      primaryAction: {
        text: I18n.get('createFirstBill', 'Registrar mi primera compra'),
        onClick: () => history.push('/bills/bill'),
        closeOnClick: true,
      },
      secondaryAction: {
        text: I18n.get('omit', 'Omitir'),
        onClick: () => closeModal(MODAL_NAME),
      },
    });
  }, [viewed, checked]);

  return null;
};

export default BillFeatureModal;

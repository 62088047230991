import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import { graphqlOperation } from '@aws-amplify/api';
import { get, difference, isEmpty, trim } from 'lodash';
import { BigNumber } from 'bignumber.js';
import * as queries from '../graphql/queries';

import * as mutations from '../graphql/mutations';
import { client as clientSelector } from '../selectors/activeInvoice';
import { openSideModal } from '../reducers/sideModals';
import { setClient } from '../reducers/activeInvoice';
import { fetch, getInitialState } from '../utils';
import { handleError } from '../utils/errors';
import { clientTransformer } from '../utils/transformers';
import * as contactDB from '../database/contactsDB';
import * as invoiceDB from '../database/invoicesDB';
import { setOfflineStatus } from './app';
import { updateInvoice } from './invoices';
import alegraAPI from './alegraAPI';
import { sendGTMEvent, sendNewGTMEvent } from './company';
import { APIGraphqlSelector } from '../selectors/app';
import { fetchById, fetchWithNextPage } from '../utils/fetchPaginated';
import dayjs from 'dayjs';
import { country, idCompanySelector } from '../selectors/company';
import { endAction, startAction } from './monitoring';
import { calculateActionTimeSelector, calculateActionTimeSelectorWrapper } from '../selectors/monitoring';

const initialState = {
  entities: {},
  ids: [],
  loading: 'idle',
  error: null,
  loadingPercent: 0,
  synchronizingClient: false,
  filters: {
    text: '',
    limit: 30,
  },
  version: 1
};

const MAX_FETCH_PER_MIN = 10;

const fetchClientsPartialFromAlegra =
  (dispatch, state) => async (metadata, params) => {
    const response = await alegraAPI.get('/contacts', {
      metadata: true,
      status: 'active',
      ...metadata,
      ...params,
    });

    const data = get(response, 'data.data', null);
    let total = 0;
    if (data) {
      total = new BigNumber(get(response, 'data.metadata.total', 0));
      contactDB.bulkPut(data, async () => {
        dispatch(refresh());

        if (get(state, 'clients.loadingPercent') !== 100) {
          let localTotal = await contactDB.total();
          if (total.isZero()) dispatch(setLoadingPercent(100));
          else {
            if (!!localTotal) {
              localTotal = new BigNumber(localTotal);
              const loadingPercent = localTotal
                .div(total)
                .multipliedBy(100)
                .decimalPlaces(0)
                .toNumber();

              dispatch(setLoadingPercent(loadingPercent));
            }
          }
        }
      });
      return { data, total };
    }
    return { data: [], total };
  };

const fetchClientsByIdFromAlegra = (dispatch, state) => async (id, rest) => {
  const response = await alegraAPI.get(`/contacts/${id}`);

  const data = get(response, 'data', null);
  if (data) {
    contactDB.put(data, async () => {
      dispatch(refresh());

      if (get(state, 'clients.loadingPercent') !== 100) {
        let localTotal = await contactDB.total();
        if (rest <= 0) dispatch(setLoadingPercent(100));
        else {
          if (!!localTotal) {
            const _localTotal = localTotal;
            localTotal = new BigNumber(localTotal);
            const loadingPercent = localTotal
              .minus(rest - 1)
              .div(_localTotal)
              .multipliedBy(100)
              .decimalPlaces(0)
              .toNumber();

            dispatch(setLoadingPercent(loadingPercent));
          }
        }
      }
    });

    if (data.status !== 'active') contactDB.remove(data.id);
    return { data };
  }
  return { data: null };
};

export const fetchClientsUpdatedPartial =
  (api, lastSyncDatetime) => async (metadata, params) => {
    const response = await api(
      graphqlOperation(queries.getClientsUpdated, {
        lastSyncDatetime,
        includeMetadata: true,
        batch: metadata,
        ...params,
      })
    );

    const data = get(response, 'data.getClientsUpdated.data', null);
    const hasNextPage = get(
      response,
      'data.getClientsUpdated.metadata.hasNextPage',
      0
    );
    if (data) return { data, hasNextPage };
    return { data: [], hasNextPage: false };
  };

export const fetchClients = createAsyncThunk(
  'clients/fetch',
  async (
    { lastSyncDatetime, ...params },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      let clientsUpdated = [];
      let outdatedClients = [];
      let useClientsUpdated = false;

      if (!!lastSyncDatetime) {
        try {
          const APIGraphql = APIGraphqlSelector(getState());
          clientsUpdated = await fetchWithNextPage(
            fetchClientsUpdatedPartial(APIGraphql, lastSyncDatetime),
            100,
            [],
            params
          );

          for (let idx = 0; idx < clientsUpdated.length; idx++) {
            const clientUpdated = clientsUpdated[idx];
            const localClient = await contactDB.getClientById(
              parseInt(clientUpdated.id)
            );

            if (
              !localClient ||
              dayjs(clientUpdated.lastUpdateDatetime) -
              dayjs(localClient.updated_at || localClient.created_at) >
              60000
            )
              outdatedClients.push(clientUpdated);
          }

          const totalLocalClients = await contactDB.total();
          useClientsUpdated =
            outdatedClients.length < (totalLocalClients / 30) * 0.2;
        } catch (error) {
          console.log(error);
          useClientsUpdated = false;
        }
      }

      if (useClientsUpdated) {
        if (outdatedClients.length === 0)
          dispatch(setLoadingPercent(100));

        return await fetchById(
          fetchClientsByIdFromAlegra(dispatch, getState()),
          outdatedClients,
          MAX_FETCH_PER_MIN
        );
      }

      return await fetch(
        fetchClientsPartialFromAlegra(dispatch, getState()),
        [],
        params,
        MAX_FETCH_PER_MIN
      );
    } catch (error) {
      return rejectWithValue(handleError(error));
    }
  }
);

export const syncClients = createAsyncThunk(
  'clients/sync',
  async (params, { dispatch, getState }) => {
    try {
      let data = await fetch(
        fetchClientsPartialFromAlegra(dispatch, getState()),
        [],
        params,
        MAX_FETCH_PER_MIN
      );
      let localData = await contactDB.getAll();

      data = data.map((client) => get(client, 'id'));
      localData = localData.map((client) => get(client, 'id'));

      await contactDB.bulkDelete(difference(localData, data));
    } catch { }
  }
);

const filterFromAlegra = async (metadata, params) => {
  const response = await alegraAPI.get('/contacts', {
    metadata: true,
    status: 'active',
    ...metadata,
    ...params,
  });

  const data = get(response, 'data.data', null)
  let total = 0;
  if (data) {
    total = new BigNumber(get(response, 'data.metadata.total', 0))
    contactDB.bulkPut(data)
    return { data, total };
  }

  return { data: [], total: 0 }
}

const searchFromAlegra = async (text, metadata = {}) => {
  try {
    if (!text || isEmpty(trim(text)))
      return []

    let response = {}
    // try to search by name
    response = await filterFromAlegra(metadata, { name: text })

    if (response.data && response.data.length > 0)
      return response.data;

    // then by national id
    response = await filterFromAlegra(metadata, { identification: text })
    return response.data;
  } catch (error) {
    return []
  }
}


const search = async (state, params, useAlegraAPI = false) => {
  let data = []

  // first try to search locally
  data = await contactDB.filter(params);

  // then try to search in Alegra
  if (useAlegraAPI && get(state, 'clients.loadingPercent') !== 100 && (!data || data.length === 0))
    data = await searchFromAlegra(params.text)

  return data;
}

export const filter = createAsyncThunk(
  'clients/filter',
  async (params, { rejectWithValue, getState }) => {
    try {
      const data = await search(getState(), params, true);

      return { data, params };
    } catch (error) {
      console.log(error)
      return rejectWithValue(error);
    }
  }
);

export const refresh = createAsyncThunk(
  'clients/refresh',
  async (_, { rejectWithValue, getState }) => {
    try {
      const filters = getState().clients.filters;
      const data = await contactDB.filter(filters);
      return { data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const adapter = createEntityAdapter();

const appSlice = createSlice({
  name: 'clients',
  initialState: getInitialState('clients', initialState),
  reducers: {
    setLoadingPercent: (state, action) => {
      state.loadingPercent = action.payload;
    },
    setSynchronizingClient: (state, action) => {
      state.synchronizingClient = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchClients.pending, (state) => {
      if (state.loading === 'idle') {
        state.loading = 'pending';
      }
    });
    builder.addCase(fetchClients.rejected, (state, action) => {
      if (state.loading === 'pending') {
        state.loading = 'idle';
        state.error = action.error;
      }
    });
    builder.addCase(fetchClients.fulfilled, (state) => {
      state.loading = 'idle';
      state.error = null;
    });
    builder.addCase(filter.fulfilled, (state, action) => {
      state.filters = action.payload.params;
      adapter.setAll(state, action.payload.data);
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      adapter.setAll(state, action.payload.data);
    });
  },
});

const { reducer, actions } = appSlice;

export const { setLoadingPercent, setSynchronizingClient } = actions;

export const clientsSelector = adapter.getSelectors((state) => state.clients);

export default reducer;

export const updateClient = (id, client) => {
  return async (dispatch, getState) => {
    const activeInvoiceClient = clientSelector(getState());

    try {
      const invoices = await invoiceDB.getByClient(id);

      invoices.map(async (invoice) => {
        await invoiceDB.update(invoice.id, { client: client.id });
        dispatch(
          updateInvoice({
            id: invoice.id,
            changes: { client },
          })
        );
        return null;
      });
      if (!!activeInvoiceClient && activeInvoiceClient.id === id) {
        dispatch(setClient(client));
      }
    } catch (error) {
      throw handleError(error);
    }
  };
};

// const trackCreatedClient = companyId => {
//   window.dataLayer.push({
//     event: 'eventActivity',
//     eventActivityAction: 'contact / add / *',
//     eventActivityLabel: companyId,
//   });
// }

export const createClient = (client, update = false, fromInvoice = false) => {
  return async (_dispatch, getState) => {
    // const APIGraphql = APIGraphqlSelector(getState());
    const appVersion = country(getState());
    const calculateActionTime = calculateActionTimeSelectorWrapper(getState);

    _dispatch(startAction({ action: 'createContact' }));

    if (fromInvoice) {
      const type = get(client, 'identificationObject.key', null);
      const number = get(client, 'identificationObject.number', null);
      const result = await contactDB.searchIdentification(
        { type, number },
        appVersion
      );

      if (result?.identification) {
        return result;
      }

      if (navigator?.onLine) {
        const response = await alegraAPI.get(
          `/contacts?limit=1&identification=${number}`
        );
        if (response?.data && response?.data?.length > 0) {
          return response.data[0];
        }
      }
    }
    // const mutation = update ? mutations.updateClient : mutations.createClient;
    let _client = clientTransformer(client);

    if (appVersion === 'panama') {
      _client.thirdsType = _client.thirdType

      delete _client.thirdType
    }

    if (update) {
      _client.id = client.id;
    }

    const response = update
      ? await alegraAPI.put(`/contacts/${client.id}`, _client)
      : await alegraAPI.post('/contacts', _client);

    // const response = await APIGraphql(
    //   graphqlOperation(mutation, {
    //     client: _client,
    //   })
    // );


    _dispatch(endAction({ action: 'createContact' }));
    _dispatch(
      sendGTMEvent('client-created', {
        emailIsConfigured: !!get(client, 'email', false),
        phoneIsConfigured: !!get(client, 'phonePrimary', false),
      })
    );

    if (!update) {
      _dispatch(
        sendNewGTMEvent('pos-contact-created', {
          id: get(response, 'data.id') ?? get(response, 'data.createClient.id'),
          error: 'no error',
          responseTime: calculateActionTime("createContact"),
          type: get(_client, 'type', 'client'),
          formType: 'Básico',
          origin: 'invoicing',
          scraping: false,
        })
      );
    }

    return response.data;
    // return get(response, update ? 'data.updateClient' : 'data.createClient');
  };
};

export const syncOffline = (
  throwError = false,
  from = 'sideModal',
  update = false,
  fromInvoice = false
) => {
  return async (dispatch) => {
    let current = null;
    let currentClient = null;

    try {
      await dispatch(setSynchronizingClient(true));
      const offlineContacts = await contactDB.getAllOffline();

      for (let index = 0; index < offlineContacts.length; index++) {
        const client = offlineContacts[index];
        current = client.id;
        currentClient = client;

        const newClient = await dispatch(createClient(client, update, fromInvoice));
        newClient.offlineId = current;

        await Promise.all([
          contactDB.remove(current),
          contactDB.put(newClient),
          dispatch(updateClient(current, newClient)),
        ]);
      }

      dispatch(setSynchronizingClient(false));
      dispatch(setOfflineStatus({ contacts: false }));
    } catch (error) {
      dispatch(setSynchronizingClient(false));
      dispatch(setOfflineStatus({ contacts: true }));

      if (
        get(error, 'errors.0.message') !== 'Network Error' &&
        !!currentClient
      ) {
        const parsedError = handleError(error);
        if (from === 'sideModal')
          dispatch(
            openSideModal({
              sideModal: 'contact',
              params: {
                contact: currentClient,
                error: parsedError,
              },
            })
          );

        const getError = handleError(error);
        dispatch(
          sendNewGTMEvent('pos-contact-created', {
            error: getError,
            type: 'client',
            formType: 'Básico',
            origin: 'invoicing',
            scraping: false,
          })
        );
      }

      if (throwError) throw handleError(error);
    }
  };
};

export const saveClient = (client, from = 'sideModal') => {
  return async (dispatch) => {
    try {
      await contactDB.put(client, () => dispatch(syncOffline(false, from)));
      return client;
    } catch (error) {
      throw handleError(error);
    }
  };
};

export const replaceOfflineClient = (
  id,
  client,
  from = 'sideModal',
  update = false,
  throwError = false
) => {
  return async (dispatch) => {
    try {
      await contactDB.update(id, client, () =>
        dispatch(syncOffline(throwError, from, update))
      );
    } catch (error) {
      throw handleError(error);
    }
  };
};

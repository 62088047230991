import { I18n } from '@aws-amplify/core'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { country as countrySelector } from '../../../../selectors/company'
import { COUNTRIES } from '../../../../utils/enums/countries'

const ProvisionalAdvice = (props) => {
  const { invoice } = props
  const country = useSelector(countrySelector)
  const numeration = get(invoice, "numberTemplate", null)
  const isPosElectronicNumeration = country === COUNTRIES.COLOMBIA &&
    get(numeration, 'isElectronic', false) &&
    get(numeration, 'documentType', null) === 'saleTicket'

  return (
    <div className="print-provisional-invoice-advice">
      <p className="lh-lg">
        {
          isPosElectronicNumeration
          ? I18n.get('provisionalAdvicePosDocument', 'Este es un documento provisional, recibirás el documento POS electrónico en tu correo o puedes descargarlo escaneando el código QR.')
          : I18n.get('provisionalAdvice', 'Este es un documento provisional, recibirás la factura electrónica en tu correo o puedes descargarla escaneando el código QR.')
        }
      </p>
    </div>
  )
}

export default ProvisionalAdvice
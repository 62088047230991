import InvoicesHeader from './header';
import InvoicesTable from './table';
import '../../invoices/_invoices.scss';
import useNpsHistorySurvey from './hooks';
import { useCallback, useEffect } from 'react';
import { throttle } from 'lodash';
import useNotification from '../../../hooks/useNotification/hook';
import { POS_INVOICES_FIRST_VIEW } from '../utils/npsUtils';

const Invoices = () => {
  const { handleNps } = useNpsHistorySurvey();

  const { viewed, setViewed } = useNotification({
    notificationName: POS_INVOICES_FIRST_VIEW,
  });

  const throttledHandleNps = useCallback(
    throttle(() => {
      handleNps();
    }, 1000),
    [handleNps]
  );

  useEffect(() => {
    if (!viewed) {
      setViewed();
    }
    throttledHandleNps();
  }, []);
  return (
    <div className='invoices-container'>
      <InvoicesHeader />
      <InvoicesTable />
    </div>
  );
};

export default Invoices;

import { Icon, Typography } from '@alegradev/smile-ui-react';
import {
  IconAlertOctagonFilled,
  IconAlertTriangleFilled,
  IconCheck,
  IconDotsCircleHorizontal,
} from '@tabler/icons-react';
import { I18n } from 'aws-amplify';

export const InvoiceStatus = ({ invoice }) => {
  const { status, offlineStatus } = invoice;

  const offlineStatusMap = {
    pending: {
      icon: (
        <Icon icon={IconAlertTriangleFilled} color='#FBBF24' size='small' />
      ),
      text: I18n.get('pending', 'pendiente'),
    },
    syncing: {
      icon: (
        <Icon icon={IconAlertTriangleFilled} color='#FBBF24' size='small' />
      ),
      text: I18n.get('syncing', 'syncing'),
    },
    error: {
      icon: <Icon icon={IconAlertOctagonFilled} color='#E11D48' size='small' />,
      text: I18n.get('error', 'error'),
    },
  };

  const defaultStatus = {
    icon:
      status === 'closed' ? (
        <Icon icon={IconCheck} color='#16A34A' size='small' />
      ) : (
        <Icon icon={IconDotsCircleHorizontal} color='#94A3B8' size='small' />
      ),
    text: I18n.get(`invoiceStatus${status}`, `${status}`),
  };

  const { icon, text } = offlineStatus
    ? offlineStatusMap[offlineStatus] || defaultStatus
    : defaultStatus;

  return (
    <div className='d-flex align-items-center gap-2'>
      {icon}
      <Typography type='body-3-regular' text={text} />
    </div>
  );
};

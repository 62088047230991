import { get } from 'lodash';
import { INVOICE_DOCUMENT_TYPES } from '../../components/modals/invoiceRefactored/utils/constants';
import {
  clientDataAreEquals,
  REGIME,
  VALIDATION_ERROR_MESSAGES,
} from '../../utils/invoice/activeInvoice';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';

export class PeruInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * Modify the invoice to create an electronic invoice for Peru.
   *
   * @param {Object} invoice - The invoice object.
   * @param {Object} numberTemplate - The number template object.
   * @returns {Object} - The modified invoice with the stamp generateStamp field set to true if the numberTemplate is electronic, or false if it's not.
   */
  electronicInvoice({ invoice, numberTemplate }) {
    return {
      ...invoice,
      stamp: {
        generateStamp: !!numberTemplate?.isElectronic,
      },
    };
  }
  /**
   * Modifies the given invoice based on various parameters provided.
   *
   * @param {Object} invoice - The invoice object to be modified.
   * @param {Object} state - The current state object.
   * @param {boolean} isElectronic - Indicates if the invoice is electronic.
   * @param {Object} numberTemplate - The template for numbering the invoice.
   * @param {Date} today - The current date.
   * @param {string} cfdiUse - The CFDI use for the invoice.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    cfdiUse,
    idPaymentMethodLocal,
  }) {
    const modifiedInvoice = {
      ...invoice,
      operationType: 'INTERNAL_SALE',
      paymentMethod: 'CASH',
      idPaymentMethodLocal,
    };

    delete modifiedInvoice.type;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    if (isElectronic) {
      return this.electronicInvoice({
        invoice: modifiedInvoice,
        numberTemplate,
      });
    }

    return modifiedInvoice;
  }

  /**
   * Returns the default client object for Peru invoices.
   *
   * @param {Object} params - The parameters object.
   * @param {Object} params.address - The client's address.
   * @param {boolean} params.isElectronic - Indicates if the invoice is electronic.
   * @returns {Object} - The default client object.
   */
  defaultClient({ address, isElectronic }) {
    return {
      name: 'Cliente general',
      identification: { type: 'DNI', number: '00000000' },
      address: null,
      type: ['client'],
      ignoreRepeated: true,
    };
  }
  /**
   * Checks if the given client is the default client for Peru.
   *
   * @param {Object} params - An object containing the client data.
   * @param {Object} params.client - The client object to be compared.
   * @returns {boolean} - True if the client matches the default client criteria, otherwise false.
   */
  isDefaultClient({ client }) {
    const defaultC = this.defaultClient();
    return (
      clientDataAreEquals(defaultC.name, client.name) &&
      clientDataAreEquals(
        defaultC.identification.type,
        client.identification.type
      ) &&
      clientDataAreEquals(
        defaultC.identification.number,
        client.identification.number
      )
    );
  }
  /**
   * Modifies the validation of the invoice for Peru.
   *
   * @param {Object} params - An object containing the invoice data.
   * @param {Object} params.invoice - The invoice object to be validated.
   * @param {string} params.ivaCondition - The IVA condition of the invoice.
   * @param {Object} params.numberTemplate - The number template of the invoice.
   * @param {boolean} params.isElectronic - Indicates if the invoice is electronic.
   * @param {string} params.regime - The regime of the invoice.
   *
   * @throws {Error} - If the invoice is electronic and the regime is NUEVO_RUS.
   * @throws {Error} - If the invoice is electronic and the client does not have a valid RUC.
   */
  modifyValidateInvoice({
    invoice,
    ivaCondition,
    numberTemplate,
    isElectronic,
    regime,
  }) {
    if (
      isElectronic &&
      !!get(invoice, 'stamp.generateStamp') &&
      get(numberTemplate, 'documentType') === INVOICE_DOCUMENT_TYPES.INVOICE
    ) {
      if (regime === REGIME.NUEVO_RUS)
        throw new Error(VALIDATION_ERROR_MESSAGES.ACTUAL_REGIME_ERROR_EMITTED);
      if (get(invoice, 'client.identificationObject.type') !== REGIME.RUC)
        throw new Error(VALIDATION_ERROR_MESSAGES.INVALID_RUC);
    }
  }

  transform(invoice) {
    let result = super.transform(invoice);
    const payments = result?.payments || [];

    result = {
      ...result,
      payments: payments.map((payment) => ({
        ...payment,
        idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
      })),
    };

    return result;
  }
}

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get, capitalize, replace, lowerCase } from 'lodash';
import * as mutations from '../../../graphql/mutations';
import { formError } from '../../../utils/errors';
import { closeModal, openModal } from '../../../reducers/modals';
import {
  country as countrySelector,
  email as companyEmail,
  identificationSelector,
  currency,
  companySelector,
  membershipSelector,
} from '../../../selectors/company';
import { validate } from '../../forms/sendInvoiceEmail/utils';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';
import { APIGraphqlSelector } from '../../../selectors/app';
import { Icon, Popover, Tooltip, Typography } from '@alegradev/smile-ui-react';
import SendInvoiceEmail from '../../forms/sendInvoiceEmail/SendInvoiceEmail';
import SendInvoiceWhatsapp from '../../forms/SendInvoiceWhatsapp/SendInvoiceWhatsapp';
import { COUNTRIES_PHONE_PREFIXES } from '../../../utils/enums/countries';
import {
  completeMessageTemplate,
  getExternalClientHash,
  getMobileOS,
  handleCopyLink,
  WHATSAPP_BASE_URL,
} from '../ShareInvoice/utils';
import useNotification from '../../../hooks/useNotification/hook';
import countries from '../../countriesData/general/countries';
import {
  IconBrandWhatsapp,
  IconLink,
  IconLoader2,
  IconMail,
  IconSparkles,
} from '@tabler/icons-react';
import { SHARE_METHODS } from '../invoiceSaved/util';
import { NavLink } from 'react-router-dom';
import { replaceAndParse } from '../../../utils';
import { showNewInvoiceModal } from '../newInvoice/utils';

const NewBodyInvoiceSaved = () => {
  const fetchHashRef = useRef(false);
  const [hash, setHash] = useState(null);
  const [whatsappTemplate, setWhatsappTemplate] = useState(null);
  const [sendBy, setSendBy] = useState('email');
  const [templateLoaded, setTemplateLoaded] = useState(false);
  const [loadingHash, setLoadingHash] = useState(false);
  const [countryKey, setCountryKey] = useState(null);
  const [phoneValue, setPhoneValue] = useState(null);
  const [phonePrefix, setPhonePrefix] = useState(null);
  const [hashError, setHashError] = useState(false);
  const [os, setOs] = useState('desktop');

  const dispatch = useDispatch();
  const invoiceCreated = useSelector((state) =>
    get(state, 'print.invoice', null)
  );
  const email = useSelector(companyEmail);
  const identification = useSelector(identificationSelector);
  const country = useSelector(countrySelector);
  const companyCurrency = useSelector(currency);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const company = useSelector(companySelector);
  const membership = useSelector(membershipSelector);

  const { viewed, setViewed } = useNotification({
    notificationName: `pos-news-share-by-whatsapp`,
  });

  const getDocumentType = () => {
    if (country === 'spain') {
      if (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket') {
        return I18n.get('invoiceModalTitle.saleTicket', 'Ticket');
      }
    }
    return I18n.get(
      get(invoiceCreated, 'numberTemplate.documentType'),
      'invoice'
    );
  };

  useEffect(() => {
    if (country) {
      const defaultDialCode = COUNTRIES_PHONE_PREFIXES[country.toUpperCase()];
      const countryInfo = countries.find((c) => c.dialCode === defaultDialCode);
      setCountryKey(get(countryInfo, 'key', null));
    }

    setOs(getMobileOS());
  }, [country]);

  useEffect(() => {
    const fetchHash = async () => {
      setLoadingHash(true);
      const idClient = get(invoiceCreated, 'client.id', null);
      const idInvoice = get(invoiceCreated, 'id', null);
      const idCompany = get(company, 'id', null);
      if (idClient && idInvoice && idCompany) {
        try {
          const result = await getExternalClientHash({
            body: {
              idClient: parseInt(idClient),
              idInvoice: parseInt(idInvoice),
              idCompany: parseInt(idCompany),
            },
          });
          setHashError(false);
          setLoadingHash(false);
          setHash(get(result, 'data.link'));
        } catch (error) {
          setHashError(true);
        }
      }
    };
    if (!fetchHashRef.current) {
      fetchHashRef.current = true;
      setPhoneValue(
        get(invoiceCreated, 'client.mobile', null) ||
          get(invoiceCreated, 'client.phonePrimary', null)
      );
      fetchHash();
    }
  }, [invoiceCreated, company]);
  const submit = async (values) => {
    try {
      if (sendBy === SHARE_METHODS.EMAIL) {
        await APIGraphql(
          graphqlOperation(mutations.sendInvoice, {
            id: invoiceCreated.id,
            emails: values.email,
          })
        );

        dispatch(
          sendGTMEvent('invoice-email-sent', {
            isElectronicInvoice: get(
              invoiceCreated,
              'numberTemplate.isElectronic',
              false
            ),
            customerPaymentMethod: get(invoiceCreated, 'paymentMethod'),
            currency: get(
              invoiceCreated,
              'currency.code',
              get(companyCurrency, 'code')
            ),
            invoiceTotal: get(invoiceCreated, 'total'),
            totalItems: get(invoiceCreated, 'items').length,
          })
        );

        dispatch(
          sendNewGTMEvent('pos-invoice-shared', {
            medium: 'mail',
          })
        );
      } else if (sendBy === SHARE_METHODS.WHATSAPP) {
        const phonePrefix =
          get(values, 'phonePrefix') ||
          COUNTRIES_PHONE_PREFIXES[country.toUpperCase()];
        const phoneNumber = get(values, 'phone');

        let msg = get(whatsappTemplate, 'whatsappTemplateMessage');

        if (hash && msg) {
          msg = completeMessageTemplate(msg, hash, company, invoiceCreated);
          dispatch(
            sendNewGTMEvent('pos-invoice-shared', {
              medium: 'whatsapp',
            })
          );
          window.open(
            `${WHATSAPP_BASE_URL[os === 'desktop' ? 'desktop' : 'mobile']}?phone=${phonePrefix + phoneNumber}&text=${encodeURIComponent(msg)}`,
            '_blank'
          );
        }
      }
    } catch (error) {
      if (!email || !identification) {
        dispatch(openModal({ modal: 'companyEmail' }));
        return formError(
          null,
          I18n.get(
            'youNeedToConfigYourCompanyEmail',
            'Primero necesitas configurar el correo electrónico de tu negocio. Luego reintenta'
          )
        );
      }
      return formError(
        error,
        I18n.get(
          'invoiceSendByEmailError',
          'ocurrió un error al intentar enviar la venta por correo'
        )
      );
    }
  };

  if (
    !!invoiceCreated &&
    !!invoiceCreated.timestamp &&
    !showNewInvoiceModal(country)
  )
    return (
      <div className='p-5'>
        <p className='text-center h4 text-capitalize-first'>
          {replaceAndParse(
            I18n.get(
              'anyDocumentSavedInOffline',
              'Sigue vendiendo mientras tu {} se guarda o consulta el detalle en el'
            ),
            [lowerCase(getDocumentType())]
          )}
        </p>

        <p className='text-center h4 text-capitalize-first'>
          <NavLink
            to='/invoices'
            onClick={() =>
              dispatch(
                closeModal({
                  modal: showNewInvoiceModal(country)
                    ? 'newInvoiceSaved'
                    : 'invoiceSaved',
                })
              )
            }
          >
            {I18n.get('salesHistoryLink', 'Historial de ventas →')}
          </NavLink>
        </p>
      </div>
    );

  if (
    !!invoiceCreated &&
    country === 'panama' &&
    !!get(invoiceCreated, 'numberTemplate.isElectronic') &&
    !get(invoiceCreated, 'stamp') &&
    !showNewInvoiceModal(country)
  )
    return (
      <div className='p-5'>
        <p className='text-center h4 text-capitalize-first font-weight-bold'>
          {I18n.get(
            'yourInvoiceIsBeingAuthorizedByTheDGI',
            'Tu factura está siendo autorizada por la DGI y en un momento podrás imprimirla desde la sección de ventas.'
          )}
        </p>
      </div>
    );

  const handleSendByButton = (value, form) => {
    setSendBy(value);
    setTemplateLoaded(false);
    form.reset();
  };

  return (
    <div
      className={`px-sm-5 py-5 d-flex flex-column ${!showNewInvoiceModal(country) ? 'w-75' : 'w-90 '} m-auto modal__body-invoice-saved share-invoice-saved`}
    >
      <Form
        onSubmit={submit}
        validate={(values) => validate(values, sendBy)}
        initialValues={{
          email: get(invoiceCreated, 'client.email', null),
          countryKey,
          phone: phoneValue,
          phonePrefix,
        }}
      >
        {({ handleSubmit, submitting, submitError, submitSucceeded, form }) => (
          <>
            <div className='d-flex justify-content-center align-items-center'>
              <Typography
                type='label-2'
                variant={
                  !!invoiceCreated && !!invoiceCreated.timestamp
                    ? 'disabled'
                    : 'secondary'
                }
                text={I18n.get('shareBy', 'Compartir por') + ' :'}
              />
              <Tooltip
                visible={!!invoiceCreated && !!invoiceCreated.timestamp}
                placement='top'
                overlay={I18n.get(
                  'youCanShareWhenInvoiceIsSaved',
                  'Podrás compartirla cuando se guarde'
                )}
                width='fit'
              >
                <div className='d-flex align-items-center'>
                  <button
                    className={`btn ${sendBy === 'email' ? 'btn-selected' : ''} ml-2`}
                    disabled={!!invoiceCreated && !!invoiceCreated.timestamp}
                    onClick={() => handleSendByButton('email', form)}
                  >
                    <Icon icon={IconMail} color='#334155' />
                  </button>
                  {!hashError && (
                    <Popover
                      placement='bottom'
                      defaultVisible={
                        !viewed &&
                        get(membership, 'pay', false) &&
                        !get(invoiceCreated, 'firstInvoice', true) &&
                        !window.Cypress
                      }
                      showCloseButton
                      onClose={() => {
                        setViewed();
                      }}
                      title={
                        <div className='d-flex align-items-center gap-2'>
                          <Icon icon={IconSparkles} color='#FBBF24' />
                          <Typography
                            type='label-1'
                            text={I18n.get(
                              'shareYourInvoices',
                              '¡Comparte tus facturas!'
                            )}
                            variant='inverse'
                          />
                        </div>
                      }
                      description={I18n.get(
                        'shareYourInvoices.message',
                        'Envíalas por correo, WhatsApp o copia un link para compartirlas por donde prefieras.'
                      )}
                    >
                      <button
                        className={`btn ${sendBy === SHARE_METHODS.WHATSAPP ? 'btn-selected' : ''}`}
                        onClick={() =>
                          handleSendByButton(SHARE_METHODS.WHATSAPP, form)
                        }
                        disabled={
                          !!invoiceCreated && !!invoiceCreated.timestamp
                        }
                      >
                        {loadingHash ? (
                          <Icon icon={IconLoader2} animated color='#334155' />
                        ) : (
                          <Icon icon={IconBrandWhatsapp} color='#334155' />
                        )}
                      </button>
                    </Popover>
                  )}

                  {!hashError && (
                    <button
                      className='btn'
                      disabled={!!invoiceCreated && !!invoiceCreated.timestamp}
                      onClick={() => {
                        handleCopyLink(hash, invoiceCreated);
                        dispatch(
                          sendNewGTMEvent('pos-invoice-shared', {
                            medium: 'URL',
                          })
                        );
                      }}
                    >
                      {loadingHash ? (
                        <Icon icon={IconLoader2} animated color='#334155' />
                      ) : (
                        <Icon icon={IconLink} color='#334155' />
                      )}
                    </button>
                  )}
                </div>
              </Tooltip>
            </div>

            <Typography
              extraClassName='my-2'
              type='label-2'
              variant={
                !!invoiceCreated && !!invoiceCreated.timestamp
                  ? 'disabled'
                  : 'secondary'
              }
              text={capitalize(
                I18n.get('send', 'enviar') + ' ' + getDocumentType()
              )}
            />

            <form noValidate onSubmit={handleSubmit}>
              {sendBy === SHARE_METHODS.EMAIL ? (
                <SendInvoiceEmail
                  invalid={!!invoiceCreated && !!invoiceCreated.timestamp}
                  submitting={submitting}
                />
              ) : (
                <SendInvoiceWhatsapp
                  submitting={submitting}
                  invoiceCreated={invoiceCreated}
                  setHash={setHash}
                  setWhatsappTemplate={setWhatsappTemplate}
                  templateLoaded={templateLoaded}
                  setTemplateLoaded={setTemplateLoaded}
                  form={form}
                  setCountryKey={setCountryKey}
                  setPhoneValue={setPhoneValue}
                  setPhonePrefix={setPhonePrefix}
                />
              )}

              {!!submitError && !!email && !!identification && (
                <p className='h4 text-danger text-capitalize-first'>
                  {submitError}
                </p>
              )}

              {submitSucceeded && (
                <p className='h4 text-primary text-capitalize-first'>
                  {I18n.get(
                    'invoiceSendByEmailSuccessfully',
                    'la venta ha sido exitosamente enviada por correo'
                  )}
                </p>
              )}
            </form>
          </>
        )}
      </Form>
    </div>
  );
};

export default NewBodyInvoiceSaved;

import { get } from 'lodash';
import { electronicInvoicing } from '../../selectors/company';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';

export class RepublicaDominicanaInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * Given the total received and the total of an invoice, this function
   * calculates the payment type of the invoice.
   *
   * If the total received is 0 and the total is 0, the payment type is 'FREE'.
   * If the total received is less than the total, the payment type is 'CREDIT'.
   * Otherwise, the payment type is 'CASH'.
   *
   * @param {Object} obj - An object with the total received and the total of an invoice.
   * @return {string} The payment type of the invoice.
   */
  calculatePaymentType({ totalReceived, total }) {
    if (totalReceived === 0 && total === 0) return 'FREE';
    if (totalReceived < total) return 'CREDIT';

    return 'CASH';
  }
  /**
   * Modifies the invoice to make it an electronic invoice
   * according to the Republica Dominicana's rules.
   *
   * The incomeType is set to '01'.
   * The paymentType is calculated using the calculatePaymentType method.
   * The stamp is set to true if the numeration is electronic.
   *
   * @param {Object} obj - An object with the invoice and the number template.
   * @return {Object} The modified invoice.
   */
  electronicInvoice({ invoice, numberTemplate }) {
    return {
      ...invoice,
      incomeType: '01',
      paymentType: this.calculatePaymentType(invoice),
      stamp: {
        generateStamp: !!numberTemplate?.isElectronic,
      },
    };
  }
  /**
   * Modify the invoice to create an electronic or non-electronic invoice for Republica Dominicana.
   *
   * If the company is configured to use electronic invoicing, the due date is set to the current date plus the client's payment term.
   * The type, operationType, paymentTerm and deliveryTerm are removed from the invoice.
   * If the company is configured to use electronic invoicing, the invoice is modified to make it an electronic invoice.
   *
   * @param {Object} obj - An object with the invoice, state, isElectronic, numberTemplate and today.
   * @return {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    idPaymentMethodLocal,
  }) {
    const isCompanyElectronic = electronicInvoicing(state);
    const modifiedInvoice = {
      ...invoice,
      dueDate: today
        .add(get(invoice, 'client.term.days', 0), 'day')
        .format('YYYY-MM-DD'),
      idPaymentMethodLocal,
    };

    delete modifiedInvoice.type;
    delete modifiedInvoice.operationType;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    if (isCompanyElectronic) {
      return this.electronicInvoice({
        invoice: modifiedInvoice,
        numberTemplate,
      });
    }
    return modifiedInvoice;
  }
  /**
   * Return the default client for Republica Dominicana.
   * The client is set to 'Consumidor final' and ignoreRepeated is set to true.
   *
   * @param {Object} obj - An object with the address and isElectronic.
   * @returns {Object} The default client.
   */
  defaultClient({ address, isElectronic }) {
    return {
      name: 'Consumidor final',
      address,
      type: ['client'],
      ignoreRepeated: true,
    };
  }

  transform(invoice) {
    let result = super.transform(invoice);
    const payments = result?.payments || [];

    result = {
      ...result,
      payments: payments.map((payment) => ({
        ...payment,
        idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
      })),
    };

    return result;
  }
}

import { Divider, Table, Typography } from '@alegradev/smile-ui-react';
import { useMemo } from 'react';
import PaymentsReceivedTableColumns from './columns';
import { useInvoiceDetail } from '../../context';
import { get } from 'lodash';
import { useFormat } from '../../../../../hooks/useFormat';
import { I18n } from 'aws-amplify';
import PaymentEditTable from '../../table/PaymentEditTable';
import PaymentSummary from '../../content/payment-summary';

const PaymentsReceivedTable = () => {
  const { data, isEditable } = useInvoiceDetail();
  const payments = get(data, 'payments', []) || [];
  const { fmt, decimal } = useFormat();

  const columns = useMemo(
    () => PaymentsReceivedTableColumns(decimal, fmt),
    [decimal, fmt]
  );

  return (
    <div className='mt-5 pb-3'>
      <Typography type='label-2' text='Pagos recibidos' variant='secondary' />
      <Divider />
      {isEditable ? (
        <PaymentEditTable />
      ) : (
        <>
          <Table
            columns={columns}
            data={payments}
            simple
            noResultsComponent={{
              title: I18n.get('noPaymentsReceived', 'No hay pagos recibidos'),
              hideAction: true,
            }}
          />
          <PaymentSummary />
        </>
      )}
    </div>
  );
};

export default PaymentsReceivedTable;

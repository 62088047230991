import { Container, Loader } from '@alegradev/smile-ui-react';
import BillHeader from './Header';
import { BillForm } from './form';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { APIGraphqlSelector } from '../../../selectors/app';
import { useBillQuery, useCostCenterSettingsQuery } from '../services/hooks';
import { I18n } from 'aws-amplify';

function Bill() {
  const params = useParams();
  const api = useSelector(APIGraphqlSelector);
  const { isFetching } = useBillQuery(params?.id, api, true);
  const { isFetching: isFetchingCostCenters } = useCostCenterSettingsQuery();

  if (isFetching || isFetchingCostCenters) {
    return (
      <Loader
        size='large'
        emphasis='page'
        orientation='vertical'
        title={I18n.get('loadingBill', 'Cargando factura')}
      />
    );
  }

  return (
    <Container marginTop={30}>
      <BillHeader />
      <BillForm />
    </Container>
  );
}

export default Bill;

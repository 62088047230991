import { Button, Grid, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { useGlobalDiscounts } from '../GlobalDiscountsProvider';
import { GLOBAL_DISCOUNTS_TYPES } from '../utils/constants';

const GlobalDiscountsFooter = () => {
  const { Wrapper } = Grid;
  const { applyDiscount, canApplyDiscount, values, error } = useGlobalDiscounts();

  return (
    <Wrapper
      padding={{ lg: 'md' }}
      backgroundColor='white'
      direction={{ lg: 'column' }}
      gap={10}
    >
      <Button
        size='large'
        disabled={canApplyDiscount || error}
        onClick={applyDiscount}
        full
        text={I18n.get('applyDiscount', 'Aplicar descuento')}
      />
      {canApplyDiscount &&
        values.value &&
        values.selectedItems.length > 0 &&
        values.type === GLOBAL_DISCOUNTS_TYPES.AMOUNT && (
          <Typography
            type='body-3-regular'
            variant='error'
            text={I18n.get(
              'globalDiscountsAmountError',
              'El valor del descuento es igual o superior al subtotal de los ítems seleccionados'
            )}
          />
        )}
    </Wrapper>
  );
};

export default GlobalDiscountsFooter;

import db from './database';
export const put = (data) => {
  return db.provisionalConsecutive.put(data);
};

export const search = async ({ idStation }) => {
  try {
    return await db.provisionalConsecutive.filter((provisionalConsecutive) => {
      return provisionalConsecutive.idStation === idStation;
    }).toArray();
  } catch {
    return [];
  }
};

export const update = async (id, changes) => {
  try {
    await db.provisionalConsecutive.update(id, changes)
  } catch (error) {
    console.error('Error updating invoice:', error);
    throw error;
  }
}

export const getAll = async () => {
  try {
    return await db.provisionalConsecutive.toArray();
  } catch {
    return [];
  }
};

import { I18n } from 'aws-amplify';
import { get, isObject } from 'lodash';
import { COUNTRIES } from '../../../../utils/enums/countries';

import { personTypes } from '../../../../components/countriesData/colombia/personTypes';
import { regimes } from '../../../../components/countriesData/colombia/regimes';

// Mexico
import { allRegimes } from '../../../../components/countriesData/mexico/regimes';
import thirdType from '../../../../components/countriesData/mexico/thirdType';

// Colombia
import baseIdTypes from '../../../../components/countriesData/colombia/idTypes';
import ivaConditions from '../../../../components/countriesData/argentina/ivaConditions';

// Argentina
import identificationTypes from '../../../../components/countriesData/costaRica/identificationTypes';

// Panama
import panamaIdentificationTypes from '../../../../components/countriesData/panama/identificationTypes';
import { baseKindOfPerson } from '../../../../components/countriesData/panama/kindOfPerson';

// Peru
import peruIdentificationTypes from '../../../../components/countriesData/peru/identificationTypes';

// Spain
import spainIdentificationTypes from '../../../../components/countriesData/spain/identificationTypes';

// Republica Dominicana
import republicaDominicanaIdentificationTypes from '../../../../components/countriesData/republicaDominicana/identificationTypes';
import republicaDominicanaNumberTemplates from '../../../../components/countriesData/republicaDominicana/number-templates';
import BigNumber from 'bignumber.js';
import { costaRicaEconomicActivities } from '../../../../components/countriesData/costaRica/economicActivities';

const getCreditLimitValue = ({ contact, fmt, decimal, totalToCollect }) => {
  const amount = get(contact, 'creditLimit', null);

  if (amount === null) {
    return '-';
  }
  const number = new BigNumber(amount);
  const totalToCollectNumber = new BigNumber(totalToCollect || 0);

  return (
    totalToCollectNumber.toFormat(decimal, fmt) +
    ' / ' +
    number.toFormat(decimal, fmt)
  );
};

const defaultInformation = (contact) => {
  const priceList = get(contact, 'priceList')
    ? Array.isArray(get(contact, 'priceList'))
      ? get(contact, 'priceList')?.join(', ')
      : isObject(get(contact, 'priceList'))
        ? get(contact, 'priceList').name
        : get(contact, 'priceList')
    : '-';

  return {
    comercialInformation: {
      paymentDeadline: {
        value: get(contact, 'term.name'),
        label: I18n.get('paymentDeadline', 'Plazo de pago'),
        size: 6,
      },
      priceList: {
        value: priceList,
        label: I18n.get('priceList', 'Lista de precios'),
        size: 6,
      },
      salesperson: {
        value: get(contact, 'seller.name') || '-',
        label: I18n.get('salesperson', 'Vendedor'),
        size: 6,
      },
    },
    accountingAndTaxConfig: {
      accountReceivable: {
        value: get(contact, 'accounting.accountReceivable.name'),
        label: I18n.get('accountReceivable', 'Cuenta por cobrar'),
        size: 12,
      },
      debtToPay: {
        value: get(contact, 'accounting.debtToPay.name'),
        label: I18n.get('accountToPay', 'Cuenta por pagar'),
        size: 12,
      },
    },
  };
};

const defaultontactInformation = (contact) => {
  return {
    email: {
      value: get(contact, 'email'),
      label: I18n.get('email', 'Correo electrónico'),
      size: 6,
    },
    phoneTwo: {
      value: get(contact, 'phonePrimary'),
      label: I18n.get('phone', 'Teléfono'),
      size: 6,
    },
    cellPhone: {
      value: get(contact, 'mobile'),
      label: I18n.get('cellPhone', 'Celular'),
      size: 6,
    },
   
  };
};

const argentinaInformation = (contact) => {
  return {
    personalDetails: {
      name: {
        value: get(contact, 'name'),
        label: I18n.get('name', 'Nombre'),
        size: 6,
      },
      documentType: {
        value: get(contact, 'identificationObject.type'),
        label: I18n.get('documentType', 'Tipo de documento'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('documentNumber', 'Número de documento'),
        size: 6,
      },
      ivaCondition: {
        value:
          ivaConditions.find(
            (condition) => condition.key === get(contact, 'ivaCondition')
          )?.value || '-',
        label: I18n.get('ivaCondition', 'Condición de IVA'),
        size: 6,
      },
      province: {
        value: [get(contact, 'address.city'), get(contact, 'address.province')]
          .filter((value) => !!value)
          .join(', '),
        label: I18n.get('localityProvince', 'Localidad / Provincia'),
        size: 6,
      },
      address: {
        value: get(contact, 'address.address'),
        label: I18n.get('address', 'Domicilio'),
        size: 6,
      },
      postalCode: {
        value: get(contact, 'address.postalCode'),
        label: I18n.get('postalCode', 'Código postal'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
  };
};

const colombiaInformation = (contact, fmt, decimal, totalToCollect) => {
  return {
    personalDetails: {
      documentType: {
        value: baseIdTypes.find(
          (type) => type.key === get(contact, 'identificationObject.type')
        )?.value,
        label: I18n.get('documentType', 'Tipo de documento'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('documentNumber', 'Número de documento'),
        size: get(contact, 'identificationObject.dv') ? 4 : 6,
      },
      dv: {
        value: get(contact, 'identificationObject.dv'),
        label: I18n.get('dv', 'DV'),
        size: 2,
        hide: !get(contact, 'identificationObject.dv'),
      },
      kindOfPerson: {
        value:
          personTypes.find(
            (personType) => personType.id === get(contact, 'kindOfPerson')
          )?.name || '-',
        label: I18n.get('kindOfPerson', 'Tipo de persona'),
        size: 6,
      },
      taxLiability: {
        value: get(contact, 'regime'),
        value:
          regimes.find((regime) => regime.key === get(contact, 'regime'))
            ?.value || '-',
        label: I18n.get('taxLiability', 'Responsabilidad fiscal'),
        hide: !get(contact, 'regime'),
        size: 6,
      },
      businessName: {
        value: get(contact, 'name'),
        label: I18n.get('businessName', 'Razón social'),
        size: 12,
      },
      municipalityAndDepartment: {
        value: [
          get(contact, 'address.city'),
          get(contact, 'address.department'),
        ]
          .filter((value) => !!value)
          .join(' / '),
        label: I18n.get(
          'municipalityAndDepartment',
          'Municipio / Departamento'
        ),
        size: 6,
      },
      address: {
        value: get(contact, 'address.address'),
        label: I18n.get('address', 'Dirección'),
        size: 6,
      },
      postalCode: {
        value: get(contact, 'address.zipCode'),
        label: I18n.get('postalCode', 'Código postal'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
    comercialInformation: {
      creditLimit: {
        value: getCreditLimitValue({ contact, fmt, decimal, totalToCollect }),
        label: I18n.get('creditLimit', 'Límite de crédito'),
        size: 6,
      },
      ...defaultInformation(contact).comercialInformation,
    },
  };
};

const costaRicaInformation = (contact, fmt, decimal, totalToCollect) => {
  return {
    personalDetails: {
      name: {
        value: get(contact, 'name'),
        label: I18n.get('name', 'Nombre'),
        size: 6,
      },
      documentType: {
        value:
          identificationTypes.find(
            (type) => type.key === get(contact, 'identificationObject.type')
          )?.value || get(contact, 'identificationObject.type'),
        label: I18n.get('documentType', 'Tipo de documento'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('documentNumber', 'Número de documento'),
        size: 6,
      },
      districtDepartmentCity: {
        value: [
          get(contact, 'address.district'),
          get(contact, 'address.department'),
          get(contact, 'address.city'),
        ]
          .filter((value) => !!value)
          .join(', '),
        label: I18n.get(
          'districtDepartmentCity',
          'Distrito / Cantón / Provincia'
        ),
        size: 6,
      },
      neighborhood: {
        value: get(contact, 'address.neighborhood'),
        label: I18n.get('neighborhood', 'Barrio'),
        size: 6,
      },
      address: {
        value: get(contact, 'address.address'),
        label: I18n.get('address', 'Domicilio'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
    comercialInformation: {
      creditLimit: {
        value: getCreditLimitValue({ contact, fmt, decimal, totalToCollect }),
        label: I18n.get('creditLimit', 'Límite de crédito'),
        size: 6,
      },
      economicActivity: {
        value: costaRicaEconomicActivities.find(option => option.code === get(contact, 'economicActivity'))?.value || '-',
        label: I18n.get('economicActivity', 'Actividad económica'),
        size: 6,
      },
      ...defaultInformation(contact).comercialInformation,
    },
  };
};

const internationalInformation = (contact) => {
  return {
    personalDetails: {
      name: {
        value: get(contact, 'name'),
        label: I18n.get('name', 'Nombre'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identification'),
        label: I18n.get('identification', 'Identificación'),
        size: 6,
      },
      address: {
        value: get(contact, 'address.address'),
        label: I18n.get('address', 'Dirección'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
  };
};

const mexicoInformation = (contact) => {
  const { accountingAndTaxConfig, comercialInformation } =
    defaultInformation(contact);

  return {
    personalDetails: {
      ficalRegimes: {
        value:
          get(contact, 'regimeObject') &&
          Array.isArray(get(contact, 'regimeObject'))
            ? get(contact, 'regimeObject')
                .map((regime) => allRegimes[regime].label)
                .join(', ')
            : '-',
        label: I18n.get('ficalRegimes', 'Regímenes fiscales'),
        size: 6,
      },
      thirdType: {
        value:
          thirdType.find((type) => type.value === get(contact, 'thirdType'))
            ?.label || '-',
        label: I18n.get('thirdType', 'Tipo de contacto'),
        size: 6,
      },
      rfc: {
        value: get(contact, 'identification'),
        label: I18n.get('rfc', 'RFC'),
        size: 6,
      },
      ficalId: {
        value: get(contact, 'fiscalId'),
        label: I18n.get('ficalId', 'Id fiscal'),
        size: 6,
      },
      colony: {
        value: get(contact, 'address.colony'),
        label: I18n.get('colony', 'Colonia'),
        size: 6,
      },
      street: {
        value: get(contact, 'address.street'),
        label: I18n.get('street', 'Calle'),
        size: 6,
      },
      exterior: {
        value: get(contact, 'address.exteriorNumber'),
        label: I18n.get('exterior', 'Exterior'),
        size: 3,
      },
      interior: {
        value: get(contact, 'address.interiorNumber'),
        label: I18n.get('interior', 'Interior'),
        size: 3,
      },
      postalCode: {
        value: get(contact, 'address.zipCode'),
        label: I18n.get('postalCode', 'Código postal'),
        size: 6,
      },
      townHallDelegation: {
        value: get(contact, 'address.municipality'),
        label: I18n.get('townHallDelegation', 'Municipio/Delegación'),
        size: 6,
      },
      locality: {
        value: get(contact, 'address.locality'),
        label: I18n.get('locality', 'Localidad'),
        size: 6,
      },
      state: {
        value: get(contact, 'address.state'),
        label: I18n.get('state', 'Estado'),
        size: 6,
      },
      country: {
        value: get(contact, 'address.country'),
        label: I18n.get('country', 'País'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
    comercialInformation: {
      operationType: {
        value: get(contact, 'operationType'),
        label: I18n.get('operationType', 'Tipo de operación'),
        size: 6,
      },
      ...comercialInformation,
      paymentMethod: {
        value: get(contact, 'paymentMethod'),
        label: I18n.get('paymentMethod', 'Metodo de pago'),
        size: 6,
      },
      paymentWay: {
        value: get(contact, 'paymentType'),
        label: I18n.get('paymentWay', 'Forma de pago'),
        size: 6,
      },
      cdfiUse: {
        value: get(contact, 'cfdiUse'),
        label: I18n.get('cdfiUse', 'Uso CFDI'),
        size: 6,
      },
    },
    accountingAndTaxConfig,
  };
};

const panamaInformation = (contact, fmt, decimal, totalToCollect) => {
  return {
    personalDetails: {
      name: {
        value: get(contact, 'name'),
        label: I18n.get('name', 'Nombre'),
        size: 6,
      },
      receptorType: {
        value:
          panamaIdentificationTypes.find(
            (type) => type.key === get(contact, 'thirdsType')
          )?.value || get(contact, 'thirdsType'),
        label: I18n.get('receotorType', 'Tipo de receptor'),
        size: 6,
      },
      kindOfPerson: {
        value:
          baseKindOfPerson.find(
            (personType) =>
              personType.key ===
              get(contact, 'identificationObject.kindOfPerson')
          )?.value || '-',
        label: I18n.get('kindOfPerson', 'Tipo de persona'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('documentNumber', 'Número de documento'),
        size: get(contact, 'identificationObject.dv') ? 4 : 6,
      },
      dv: {
        value: get(contact, 'identificationObject.dv'),
        label: I18n.get('dv', 'DV'),
        size: 2,
        hide: !get(contact, 'identificationObject.dv'),
      },
      provinceDistrictTownship: {
        value: [
          get(contact, 'address.province'),
          get(contact, 'address.district'),
          get(contact, 'address.township'),
        ]
          .filter((value) => !!value)
          .join(', '),
        label: I18n.get(
          'provinceDistrictTownship',
          'Provincia / Distrito / Corregimiento'
        ),
        size: 12,
      },
      address: {
        value: get(contact, 'address.address'),
        label: I18n.get('address', 'Dirección'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
    comercialInformation: {
      creditLimit: {
        value: getCreditLimitValue({ contact, fmt, decimal, totalToCollect }),
        label: I18n.get('creditLimit', 'Límite de crédito'),
        size: 6,
      },
      ...defaultInformation(contact).comercialInformation,
    },
  };
};

const peruInformation = (contact) => {
  return {
    personalDetails: {
      businessName: {
        value: get(contact, 'name'),
        label: I18n.get('businessName', 'Razón social o nombre completo'),
        size: 12,
      },
      documentType: {
        value: peruIdentificationTypes.find(
          (type) => type.key === get(contact, 'identificationObject.type')
        ).value,
        label: I18n.get('documentType', 'Tipo de identificación'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('documentNumber', 'Número de documento'),
        size: 6,
      },
      fiscalAddress: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('documentNumber', 'Número de documento'),
        size: 6,
      },
      districtProvinceDepartment: {
        value: [
          get(contact, 'address.province'),
          get(contact, 'address.district'),
          get(contact, 'address.township'),
        ]
          .filter((value) => !!value)
          .join(', '),
        label: I18n.get(
          'districtProvinceDepartment',
          'Distrito / Provincia / Departmento'
        ),
        size: 6,
      },
      urbanization: {
        value: get(contact, 'address.urbanization'),
        label: I18n.get('urbanization', 'Urbanización'),
        size: 6,
      },
      ubigeo: {
        value: get(contact, 'address.ubigeoCode'),
        label: I18n.get('ubigeoINEI', 'Ubigeo INEI'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
  };
};

const republicaDominicanaInformation = (
  contact,
  fmt,
  decimal,
  totalToCollect
) => {
  return {
    personalDetails: {
      name: {
        value: get(contact, 'name'),
        label: I18n.get('name', 'Nombre'),
        size: 6,
      },
      documentType: {
        value: republicaDominicanaIdentificationTypes.find(
          (type) => type.key === get(contact, 'identificationObject.type')
        )?.value,
        label: I18n.get('documentType', 'Tipo de identificación'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('documentNumber', 'Número de documento'),
        size: 6,
      },
      municipalityAndProvince: {
        value: [
          get(contact, 'address.municipality'),
          get(contact, 'address.province'),
        ]
          .filter((value) => !!value)
          .join(' / '),
        label: I18n.get('municipalityAndProvince', 'Municipio / Provincia'),
        size: 6,
      },
      address: {
        value: get(contact, 'address.description'),
        label: I18n.get('address', 'Dirección'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
    comercialInformation: {
      creditLimit: {
        value: getCreditLimitValue({ contact, fmt, decimal, totalToCollect }),
        label: I18n.get('creditLimit', 'Límite de crédito'),
        size: 6,
      },
      ...defaultInformation(contact).comercialInformation,
    },
  };
};

const spainInformation = (contact) => {
  return {
    personalDetails: {
      name: {
        value: get(contact, 'name'),
        label: I18n.get('name', 'Nombre'),
        size: 6,
      },
      documentType: {
        value: spainIdentificationTypes.find(
          (type) => type.key === get(contact, 'identificationObject.type')
        )?.value,
        label: I18n.get('documentType', 'Tipo de identificación'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identificationObject.number'),
        label: I18n.get('nif', 'NIF/CIF'),
        size: 6,
      },
      province: {
        value: get(contact, 'address.province'),
        label: I18n.get('province', 'Provincia'),
        size: 6,
      },
      city: {
        value: get(contact, 'address.city'),
        label: I18n.get('city', 'Ciudad'),
        size: 6,
      },
      postalCode: {
        value: get(contact, 'address.zipCode'),
        label: I18n.get('postalCode', 'Código postal'),
        size: 6,
      },
      address: {
        value: get(contact, 'address.address'),
        label: I18n.get('address', 'Domicilio'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
  };
};

const usaInformation = (contact) => {
  return {
    personalDetails: {
      name: {
        value: get(contact, 'name'),
        label: I18n.get('name', 'Nombre'),
        size: 6,
      },
      documentNumber: {
        value: get(contact, 'identification'),
        label: I18n.get('identification', 'Identificación'),
        size: 6,
      },
      address: {
        value: get(contact, 'address.address'),
        label: I18n.get('address', 'Dirección'),
        size: 6,
      },
      address: {
        value: get(contact, 'address.city'),
        label: I18n.get('city', 'Ciudad'),
        size: 6,
      },
      ...defaultontactInformation(contact),
    },
    ...defaultInformation(contact),
  };
};

function getInformationByCountry(
  country,
  contact,
  fmt,
  decimal,
  totalToCollect
) {
  switch (country) {
    case COUNTRIES.ARGENTINA:
      return argentinaInformation(contact);
    case COUNTRIES.COLOMBIA:
      return colombiaInformation(contact, fmt, decimal, totalToCollect);
    case COUNTRIES.COSTA_RICA:
      return costaRicaInformation(contact, fmt, decimal, totalToCollect);
    case COUNTRIES.INTERNATIONAL:
      return internationalInformation(contact);
    case COUNTRIES.MEXICO:
      return mexicoInformation(contact);
    case COUNTRIES.PANAMA:
      return panamaInformation(contact, fmt, decimal, totalToCollect);
    case COUNTRIES.PERU:
      return peruInformation(contact);
    case COUNTRIES.REPUBLICA_DOMINICANA:
      return republicaDominicanaInformation(
        contact,
        fmt,
        decimal,
        totalToCollect
      );
    case COUNTRIES.SPAIN:
      return spainInformation(contact);
    case COUNTRIES.USA:
      return usaInformation(contact);
    default:
      return {};
  }
}

export default getInformationByCountry;

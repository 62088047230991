import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { allPaymentMethods } from '../../../../selectors/paymentMethods';
import { Typography, useDeviceType } from '@alegradev/smile-ui-react';
import { Field } from 'react-final-form';
import { renderField } from '../../fields/V0/Fields';
import UseField from '../components/UseField';
import SellerField from '../components/SellerField';
import BankField from '../components/BankField';
import AnnotationField from '../components/AnnotationField';
import CustomPaymentMethodQrBody from './CustomPaymentMethodQrBody';
import { useEffect, useRef } from 'react';

const CustomPaymentMethod = ({ form, values, total, anotationMaxLimit }) => {
  const transferInputRef = useRef(null);
  const paymentMethods = useSelector(allPaymentMethods);
  const paymentMethod = paymentMethods.find(
    (p) => p.name === values.paymentMethodSelected
  );

  const deviceType = useDeviceType();

  useEffect(() => {
    if (transferInputRef.current && values?.transfer) {
      console.log('transferInputRef.current', transferInputRef.current);
      setTimeout(() => {
        transferInputRef.current.click();
      }, 500);
    }
  }, [transferInputRef, values]);

  return (
    <>
      {get(paymentMethod, 'qr.url', null) ? (
        <CustomPaymentMethodQrBody
          paymentMethod={paymentMethod}
          total={total}
          values={values}
          anotationMaxLimit={anotationMaxLimit}
        />
      ) : (
        <>
          <div className='form-row col-sm-12 p-0'>
            <div className='modal__invoice-custom-payment-instruction col-md-12 mb-4'>
              <Typography
                type='body-3-bold'
                text={get(paymentMethod, 'instructions', null)}
              />
            </div>
            <div className='col-sm-12 col-md-6'>
              <Field
                name='transfer'
                className='col-md-12 pl-0 pr-0'
                //defaultValue={total}
                type='number'
                label={
                  <Typography
                    type='label-2'
                    text={'Valor del pago en ' + get(paymentMethod, 'name', '')}
                    variant='secondary'
                  />
                }
                required
                placeholder={'$'}
              >
                {(props) => (
                  <>
                    {renderField({
                      ...props,
                      fontSize: '16px',
                      height: '46px',
                      ref: transferInputRef,
                    })}
                  </>
                )}
              </Field>
              <BankField name='transferBank' />
              <UseField values={values} />
            </div>
            <div
              className={`col-sm-12 col-md-6 ${deviceType === 'mobile' ? 'mobile' : 'form__invoice-advanced-data pl-0 pr-0'}`}
            >
              <div className='d-flex justify-content-center'>
                <SellerField />
              </div>
              <div className='col-sm-12 pl-2 pr-0'>
                <AnnotationField
                  anotationMaxLimit={anotationMaxLimit}
                  type='textarea'
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CustomPaymentMethod;

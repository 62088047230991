import { billTransformer } from './transformers';

import * as queries from '../../../graphql/queries';
import alegraAPI from "../../../reducers/alegraAPI";

import { get } from 'lodash';
import { graphqlOperation } from 'aws-amplify';
import { toastHandler } from '@alegradev/smile-ui-react';
import { handleError } from '../../../utils/errors';

export const fetchBills = async (metadata, api) => {
  const params = {
    batch: {
      start: metadata?.pagination?.start || 0,
      limit: metadata?.pagination?.limit || 10,
      sortDirection: metadata?.pagination?.order_direction || 'DESC',
    },
    includeMetadata: true,
  };
  const data = await api(graphqlOperation(queries.allBills, params));
  return get(data, 'data.allBills', {});
};

export const fetchCostCenterSettings = async () => {
  const data = await alegraAPI.get('/cost-centers/settings');
  return get(data, 'data', []);
};

export const fetchBill = async (id, api, raw = false) => {
  try {
    const data = await api(graphqlOperation(queries.getBillDetail, { id }));
    if(raw) return get(data, 'data.getBillById', {});
    return billTransformer(get(data, 'data.getBillById', {}));
  } catch (error) {
    toastHandler({
      id: 'fetchBill',
      type: 'error',
      title: handleError(error),
    });
    return null;
  }
};

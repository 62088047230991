import React, { useState } from 'react';
import { Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { useLocation, useHistory } from 'react-router';
import { initialValuesFromModal, validate } from '../../forms/item/utils';
import Primary from './Primary';
import Secondary from './Secondary';
import ItemCategory from '../../modals/itemCategory/ItemCategory';
import PriceList from '../../modals/priceList/PriceList';
import Warehouse from '../../modals/warehouse/Warehouse';
import useReduxData from '../hooks/useReduxData';
import { useSubmitItem } from '../hooks/useSubmitItem';
import { get } from 'lodash';

const NewItem = () => {
  const {
    dispatch,
    country,
    customFields,
    station,
    mainWarehouse,
    regime,
    isElectronic,
    mainCategory,
    isItemCategoryOpen,
    isPriceListOpen,
    isWarehouseOpen,
    APIGraphql,
  } = useReduxData();

  const location = useLocation();
  const history = useHistory();
  const [priceListIndex, setPriceListIndex] = useState(0);
  const [warehouseIndex, setWarehouseIndex] = useState(0);

  const submit = useSubmitItem({ APIGraphql, station, dispatch, history });

  const initialValues = !!get(location, 'state.type') ? initialValuesFromModal({
          type: get(location, 'state.type'),
          customFields,
          initValues: !!get(location, 'state.type') ? get(location, 'state.initValues') : null,
          mainCategory,
          mainWarehouse,
        }) : null
  return (
    <Form
      onSubmit={submit}
      mutators={{ ...arrayMutators }}
      initialValues={
        initialValues ? {
          ...initialValues,
          onSubmitEvent: false
        }: {
          onSubmitEvent: false
        }
      }
      validate={(values) => validate(values, { country, customFields, regime, isElectronic })}
      keepDirtyOnReinitialize
    >

      {
        ({ handleSubmit, values, form, submitting, hasValidationErrors }) => (
          <>
            <form
              noValidate
              className='py-5 row new-item'
              onSubmit={handleSubmit}
            >
              <Primary
                form={form}
                values={values}
                submitting={submitting}
                setPriceListIndex={setPriceListIndex}
                setWarehouseIndex={setWarehouseIndex}
              />

              <Secondary
                form={form}
                values={values}
                submitting={submitting}
                hasValidationErrors={hasValidationErrors}
              />
            </form>

            {isItemCategoryOpen && (
              <ItemCategory
                onCreated={(category) => form.change('category', category)}
              />
            )}

            {isPriceListOpen && (
              <PriceList
                onCreated={(priceList) => {
                  try {
                    form.change(
                      `priceLists.${priceListIndex}.priceList`,
                      priceList
                    );
                  } catch {
                    //
                  }
                }}
              />
            )}

            {isWarehouseOpen && (
              <Warehouse
                onCreated={(warehouse) => {
                  try {
                    form.change(
                      `warehouses.${warehouseIndex}.warehouse`,
                      warehouse
                    );
                  } catch {
                    //
                  }
                }}
              />
            )}
          </>
        )
      }
    </Form>
  );
};

export default NewItem;
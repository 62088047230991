import React, { useEffect, useState } from 'react';
import QRTransfer from '../invoice/BasicData/QRTransfer';
import NewCash from './methods/NewCash';
import NewCard from './methods/NewCard';
import NewTransfer from './methods/NewTransfer';
import NewCombined from './methods/NewCombined';
import { includes } from 'lodash';
import CustomPaymentMethod from './methods/CustomPaymentMethod';
import { useSelector } from 'react-redux';
import { allPaymentMethods } from '../../../selectors/paymentMethods';
import {
  client as clientSelector,
  isPaymentMethodSelected,
  total as totalSelector,
} from '../../../selectors/activeInvoice';
import NewChange from './components/NewChange';
import NewBancolombiaQR from './methods/NewBancolombiaQR';
import NewPushNotification from './methods/NewPushNotification';
import NewNequiQR from './methods/NewNequiQR';
import CreditLimitNotification from '../invoice/CreditLimitNotification';
import ReportsAPI from '../../../reducers/balanceByClient';
import { addTotalToCollect } from '../../../reducers/totalToCollect';
import { country as countrySelector } from '../../../selectors/company';
import { useConnectionStatus } from '../../../hooks/useConnectionStatus';
import { allTotalToCollectData } from '../../../selectors/totalToCollect';
import { showCreditLimitField } from '../../../pages/contacts/utils/limitCredit';

const NewBasicData = ({
  anotationMaxLimit,
  values,
  form,
  total,
  currencyCode,
  activeNequiPush,
  setExternalPaymentSuccess,
  editInvoice = false,
  isTotalToCollectLoading,
  totalToCollect,
  canCredit,
  setCanCredit,
}) => {
  const paymentMethods = useSelector(allPaymentMethods);
  const isPaymentSelected = useSelector(isPaymentMethodSelected);
  const totalPrice = useSelector(totalSelector);
  const client = useSelector(clientSelector);
  const country = useSelector(countrySelector);

  if (!values) return null;
  const defaultMethods = [
    'cash',
    'combined',
    'transfer',
    'nequi',
    'bancolombiaQR',
    'nequiPush',
    'debit',
    'credit',
  ];

  const checkCustomPaymentMethod = () => {
    const isCustomPaymentMethod = !includes(
      defaultMethods,
      values.paymentMethodSelected
    );
    if (isCustomPaymentMethod) {
      const paymentMethod = paymentMethods.find(
        (pm) => pm.name === values.paymentMethodSelected
      );
    }
    return isCustomPaymentMethod;
  };

  return (
    <div className='form-body w-100 overflow-hidden new-basic-data'>
      <div className='form-body__fields  w-100'>
        {values.paymentMethodSelected === 'cash' && isPaymentSelected && (
          <NewCash
            values={values}
            total={total}
            currencyCode={currencyCode}
            anotationMaxLimit={anotationMaxLimit}
          />
        )}

        {(values.paymentMethodSelected === 'debit' ||
          values.paymentMethodSelected === 'credit') && (
          <NewCard
            form={form}
            values={values}
            total={total}
            annotationMaxLimit={anotationMaxLimit}
          />
        )}

        {values.paymentMethodSelected === 'combined' && (
          <NewCombined
            values={values}
            form={form}
            annotationMaxLimit={anotationMaxLimit}
          />
        )}

        {values.paymentMethodSelected === 'transfer' && (
          <NewTransfer
            values={values}
            total={total}
            annotationMaxLimit={anotationMaxLimit}
          />
        )}

        {values.paymentMethodSelected === 'nequi' && (
          <QRTransfer form={form} values={values} total={total} />
        )}

        {values.paymentMethodSelected === 'bancolombiaQR' && (
          <NewBancolombiaQR
            form={form}
            values={values}
            total={total}
            anotationMaxLimit={anotationMaxLimit}
            setExternalPaymentSuccess={setExternalPaymentSuccess}
          />
        )}

        {values.paymentMethodSelected === 'nequiPush' && activeNequiPush && (
          <NewPushNotification
            form={form}
            values={values}
            total={total}
            anotationMaxLimit={anotationMaxLimit}
            setExternalPaymentSuccess={setExternalPaymentSuccess}
          />
        )}

        {values.paymentMethodSelected === 'nequiPush' && !activeNequiPush && (
          <NewNequiQR
            form={form}
            values={values}
            total={total}
            anotationMaxLimit={anotationMaxLimit}
            setExternalPaymentSuccess={setExternalPaymentSuccess}
          />
        )}
        {checkCustomPaymentMethod() && isPaymentSelected ? (
          <CustomPaymentMethod
            form={form}
            values={values}
            total={total}
            annotationMaxLimit={anotationMaxLimit}
          />
        ) : (
          !includes(defaultMethods, values.paymentMethodSelected) &&
          isPaymentSelected && (
            <NewTransfer
              values={values}
              total={total}
              annotationMaxLimit={anotationMaxLimit}
            />
          )
        )}
      </div>
      {isPaymentSelected && (
        <>
          {showCreditLimitField(country) && !editInvoice && (
            <div className='mt-4'>
              <CreditLimitNotification
                client={client}
                values={values}
                total={totalPrice}
                totalToCollect={totalToCollect}
                setCanCredit={setCanCredit}
              />
            </div>
          )}
          <div className='w-100 d-flex justify-content-end '>
            <NewChange values={values} total={totalPrice} />
          </div>
        </>
      )}
    </div>
  );
};

export default NewBasicData;

import dayjs from 'dayjs';
import {
  rawSubtotal,
  discSubtotal as subtotalSelector,
  tipSelector,
  total as totalSelector,
} from '../../selectors/activeInvoice';
import {
  electronicInvoicing,
  stationInvoiceNumeration,
  station as stationSelector,
} from '../../selectors/app';
import {
  country as countrySelector,
  tipsSelector,
  timezone as tzSelector,
  decimalPrecision,
  termsAndConditions,
  regime as regimeSelector,
  ivaConditionSelector,
  companySelector,
} from '../../selectors/company';
import { getMainCurrency } from '../../selectors/currencies';
import { get } from 'lodash';
import {
  getNumerationById,
  isCashReceiptNumerationActive,
} from '../../selectors/numerations';
import BigNumber from 'bignumber.js';
import { v4 } from 'uuid';
import { hasPermissionTo } from '../../selectors/auth';
import { checkNumerationBlock } from '../../components/home/NewActiveInvoice/utils';
import {
  clientDataAreEquals,
  TIPS_TYPE,
  VALIDATION_ERROR_MESSAGES,
} from '../../utils/invoice/activeInvoice';
import { InvoiceTransformerStrategy } from './InvoiceTransformerStrategy';

export const prepareNumeration = async (numeration, timestamp) => {
  if (!!numeration) {
    const invoiceNumber = +get(numeration, 'nextInvoiceNumber', 0);
    try {
      const offlineInvoices = await getAll();
      const offlineInvoicesByNum = offlineInvoices.filter(
        (invoice) =>
          get(invoice, 'numberTemplate.id') === get(numeration, 'id') &&
          dayjs(get(invoice, 'timestamp')).isBefore(dayjs(timestamp))
      );
      return {
        ...numeration,
        number: invoiceNumber + get(offlineInvoicesByNum, 'length', 0),
      };
    } catch {
      return {
        ...numeration,
        number: invoiceNumber,
      };
    }
  }
  return numeration;
};

export class BaseInvoiceStrategy extends InvoiceTransformerStrategy {
  /**
   * This function takes the greater payment method, country, totalReceived,
   * and isElectronicPOSDocument as parameters and returns the payment method
   * to be used in the invoice.
   *
   * If the greater payment method is not specified, it defaults to 'cash'.
   *
   * It also maps payment methods as follows:
   * - debit-card to debit-card
   * - credit-card to credit-card
   * - transfer to cash
   *
   * If the greater payment method is not in the mapping, it defaults to 'cash'.
   * @param {string} greaterPaymentMethod The greater payment method.
   * @param {string} country The country.
   * @param {BigNumber} totalReceived The total received.
   * @param {boolean} isElectronicPOSDocument Whether the invoice is an electronic
   *     POS document.
   * @return {string} The payment method.
   */
  calculatePaymentMethod({
    greaterPaymentMethod,
    country,
    totalReceived,
    isElectronicPOSDocument,
  }) {
    // Handle the case where no payment method is specified
    if (!greaterPaymentMethod) return 'cash';

    const defaultMethods = {
      'debit-card': 'debit-card',
      'credit-card': 'credit-card',
      transfer: 'cash',
    };
    return defaultMethods[greaterPaymentMethod] || 'cash';
  }
  /**
   * Calculates the payments for an invoice based on given payment information.
   *
   * This function processes the received payments, determines the dominant payment
   * method, and calculates the total received and cash to be returned if applicable.
   *
   * @param {Object} params - The parameters for calculating payments.
   * @param {Object} params.payments - An object representing different payment types and their amounts.
   * @param {Object} params.banks - An object mapping payment types to bank accounts.
   * @param {Object} params.today - A dayjs object representing the current date.
   * @param {BigNumber} params.total - The total amount to be received.
   * @param {BigNumber} params.subtotal - The subtotal before any additional charges.
   * @param {string} params.country - The country where the invoice is being issued.
   * @param {number} params.decimal - The number of decimal places to consider for currency calculations.
   * @param {Object} params.cashReceiptNumberTemplate - Template for the cash receipt numbering.
   * @param {Object} params.currency - The currency object which may include exchange rate.
   * @param {Object} params.numberTemplate - Template for the invoice numbering.
   *
   * @returns {Object} An object containing the total, subtotal, total received, cash returned, processed payments,
   *                   original payment method, and final payment method.
   */
  calculatePayments({
    payments,
    banks,
    today,
    total,
    subtotal,
    country,
    decimal,
    cashReceiptNumberTemplate,
    currency,
    numberTemplate,
  }) {
    const methods = {
      cash: 'cash',
      debit: 'debit-card',
      credit: 'credit-card',
      transfer: 'transfer',
    };

    let greaterPayment = new BigNumber(-1);
    let greaterPaymentMethod = null;

    let totalReceived = new BigNumber(0);
    let totalInNotCash = new BigNumber(0);
    let cashIndex = 0;

    const finalPayments = Object.keys(payments)
      .map((key, index) => {
        const amount = new BigNumber(payments[key]).decimalPlaces(decimal);

        totalReceived = totalReceived.plus(amount);

        if (key !== 'cash') totalInNotCash = totalInNotCash.plus(amount);
        else cashIndex = index;

        if (amount.gt(greaterPayment)) {
          greaterPayment = amount;
          greaterPaymentMethod = methods[key];
        }

        let finalPayment = {
          date: today.format('YYYY-MM-DD'),
          account: key !== 'cash' ? banks[key] : null,
          amount: amount.toNumber(),
          paymentMethod: methods[key],
          numberTemplate: cashReceiptNumberTemplate,
        };

        if (!!get(currency, 'exchangeRate'))
          finalPayment = { ...finalPayment, currency };

        return finalPayment;
      })
      .map((payment, index) =>
        index === cashIndex && totalReceived.gt(total)
          ? {
              ...payment,
              amount: total
                .decimalPlaces(decimal)
                .minus(totalInNotCash)
                .toNumber(),
            }
          : payment
      )
      .filter((payment) => !!payment.amount);

    return {
      total: total.toNumber(),
      subtotal: subtotal.toNumber(),
      totalReceived: totalReceived.toNumber(),
      cashReturned: totalReceived.gt(total)
        ? total.minus(totalReceived).abs().toNumber()
        : 0,
      payments: finalPayments,
      originalPaymentMethod: greaterPaymentMethod,
      paymentMethod: this.calculatePaymentMethod({
        greaterPaymentMethod,
        country,
        totalReceived: totalReceived.toNumber(),
        isElectronicPOSDocument: false,
      }),
    };
  }

  /**
   * @function prepareItems
   * @description Returns the items for the invoice.
   * @param {Object} params - The function parameters.
   * @param {Object} params.state - The state of the application.
   * @returns {Array} The invoice items.
   */
  prepareItems({ state }) {
    const items = get(state, 'activeInvoice.items');

    console.log('prepare items', items);

    return items;
  }
  /**
   * Prepares an invoice based on the provided payment information and application state.
   *
   * This function calculates the total and subtotal amounts, prepares necessary invoice
   * metadata, and processes additional charges such as tips. It also handles currency
   * conversion and number template preparation for electronic invoicing.
   *
   * @param {Object} params - The parameters for preparing the invoice.
   * @param {Object} params.payments - An object representing the payment methods and amounts.
   * @param {Object} params.seller - Information about the seller.
   * @param {string} params.anotation - Any additional notes for the invoice.
   * @param {Object} params.banks - An object mapping payment methods to bank accounts.
   * @param {Object} params.externalPayment - Information about any external payment.
   * @param {string} params.cfdiUse - The CFDI usage code for invoicing.
   * @param {Object} state - The current application state.
   *
   * @returns {Object} The modified and prepared invoice ready for processing.
   */
  async prepareInvoice(
    { payments, seller, anotation, banks, externalPayment, cfdiUse, idPaymentMethodLocal },
    state
  ) {
    const total = totalSelector(state);
    const subtotal = subtotalSelector(state);
    const subtotalToTip = rawSubtotal(state);
    const station = stationSelector(state);
    const country = countrySelector(state);
    const mainCurrency = getMainCurrency(state);
    const tips = tipSelector(state);
    const tipsSettings = tipsSelector(state);
    const today = dayjs(new Date()).tz(tzSelector(state));
    const decimal = decimalPrecision(state);
    let currency = get(state, 'activeInvoice.currency');
    const numberTemplate = !!get(state, 'activeInvoice.numeration')
      ? get(state, 'activeInvoice.numeration')
      : stationInvoiceNumeration(state);
    const isElectronic = electronicInvoicing(numberTemplate)(state);
    const activeCashReceipt = isCashReceiptNumerationActive(state);
    const cashReceiptNumberTemplate = !!activeCashReceipt
      ? { id: get(station, 'idCashReceiptNumberTemplate') }
      : null;

    if (get(currency, 'code', '') === get(mainCurrency, 'code', ''))
      currency = null;

    const includeTip = get(tips, 'include', false);
    const additionalChargeId = get(tipsSettings, 'additionalChargeId', null);

    const aditionalCharge = includeTip
      ? [
          {
            id: additionalChargeId,
            percentage: 0,
            amount:
              get(tips, 'type') === TIPS_TYPE.PERCENTAGE
                ? subtotalToTip
                    .multipliedBy(get(tips, 'percentage', 0))
                    .dividedBy(100)
                    .decimalPlaces(decimal)
                    .toNumber()
                : new BigNumber(tips.value).decimalPlaces(decimal).toNumber(),
            metadata: {
              items: [],
              thirdParty: {
                thirdPartyName: '',
                identificationNumber: '',
              },
            },
          },
        ]
      : [];

    const numberTemplatePrepared = await prepareNumeration(
      numberTemplate,
      today.format()
    );

    let invoice = {
      id: v4(),
      status: 'open',
      date: today.format('YYYY-MM-DD'),
      dueDate: today.format('YYYY-MM-DD'),
      timestamp: today.format('YYYY-MM-DD HH:mm:ss'),
      termsConditions: termsAndConditions(state),
      numberTemplate: numberTemplatePrepared,
      costCenter: get(station, 'idCostCenter', null),
      warehouse: get(station, 'idWarehouse', null),
      externalPayment,
      anotation,
      seller,
      ...this.calculatePayments({
        payments,
        banks,
        today,
        total,
        subtotal,
        country,
        decimal,
        cashReceiptNumberTemplate,
        currency,
        numberTemplate: numberTemplatePrepared,
      }),
      ...state.activeInvoice,
      aditionalCharge,
      currency,
      originApp: 'POS',
      items: this.prepareItems({ state }),
    };

    if (!get(tips, 'include', false) || !get(tipsSettings, 'enabled', false)) {
      delete invoice.aditionalCharge;
    }
    return this.modifyPreparedInvoice({
      invoice,
      state,
      isElectronic,
      numberTemplate,
      today,
      cfdiUse,
      payments,
      idPaymentMethodLocal,
    });
  }

  /**
   * Modifies the given invoice based on various parameters provided.
   *
   * @param {Object} invoice - The invoice object to be modified.
   * @param {Object} state - The current state object.
   * @param {boolean} isElectronic - Indicates if the invoice is electronic.
   * @param {Object} numberTemplate - The template for numbering the invoice.
   * @param {Date} today - The current date.
   * @param {string} cfdiUse - The CFDI use for the invoice.
   * @param {Object} payments - Payments related information.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    cfdiUse,
    payments,
  }) {
    return invoice;
  }
  defaultClient() {
    return {
      name: 'POS',
      type: ['client'],
      address,
      ignoreRepeated: true,
    };
  }
  /**
   * Check if the given client is the default client for the current country.
   * @param {Object} params - Object containing the client object.
   * @param {Object} params.client - Client object to check.
   * @returns {boolean} - True if the client matches the default client criteria, otherwise false.
   */
  isDefaultClient({ client }) {
    const defaultC = this.defaultClient();
    return clientDataAreEquals(defaultC.name, client.name);
  }
  /**
   * Validate the invoice before sending it to the server.
   * @param {object} invoice Invoice to validate.
   * @param {object} state State of the application.
   * @returns {object} Validated invoice.
   * @throws {object} Error if the invoice is not valid.
   */
  validateInvoice({ invoice, state }) {
    const can = hasPermissionTo(state);
    const country = countrySelector(state);
    const regime = regimeSelector(state);
    const numberTemplate = getNumerationById(
      get(invoice, 'numberTemplate.id', null)
    )(state);
    const isElectronic = electronicInvoicing(numberTemplate)(state);
    const ivaCondition = ivaConditionSelector(state);
    const { registryDate } = companySelector(state);

    if (
      !!get(invoice, 'numberTemplate.number', null) &&
      !!checkNumerationBlock(numberTemplate, country, registryDate)
    )
      if (
        get(invoice, 'numberTemplate.number') >
        parseInt(get(numberTemplate, 'maxInvoiceNumber'))
      )
        throw new Error(VALIDATION_ERROR_MESSAGES.CONSECUTIVE_NUMERATION);

    if (!can('view', 'contacts'))
      throw new Error(VALIDATION_ERROR_MESSAGES.CONTACTS_NOT_ALLOWED);

    if (!get(invoice, 'client'))
      throw new Error(VALIDATION_ERROR_MESSAGES.CLIENT_NOT_FOUND);

    return this.modifyValidateInvoice({
      invoice,
      ivaCondition,
      numberTemplate,
      isElectronic,
      regime,
    });
  }
  /**
   * @function
   * @param {Object} invoice - The invoice object to be validated.
   * @param {String} ivaCondition - The iva condition of the invoice.
   * @param {Object} numberTemplate - The number template object of the invoice.
   * @param {Boolean} isElectronic - A boolean indicating if the invoice is electronic.
   * @param {String} regime - The regime of the invoice.
   * @returns {undefined}
   * @desc
   * The method to validate an invoice object.
   * This method is meant to be overridden by each country's strategy.
   * The method should throw an error if the invoice is not valid.
   */
  modifyValidateInvoice({
    invoice,
    ivaCondition,
    numberTemplate,
    isElectronic,
    regime,
  }) {}
  /**
   * @function
   * @param {Object} invoice - The invoice object to be transformed.
   * @returns {Object} The transformed invoice object.
   * @desc
   * The main method to transform an invoice object.
   * This method is meant to be overridden by each country's strategy.
   * Each country's strategy should call this method with the invoice object to be transformed.
   * This method will call the transform method of the superclass.
   * The transform method of the superclass will add the missing fields to the invoice object.
   */
  transform(invoice, company) {
    return super.transform(invoice, company);
  }
}

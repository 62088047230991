import { Icon, TableCellRegular, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { formatMoney } from '../../../../../utils';
import { GLOBAL_DISCOUNTS_TYPES } from '../utils/constants';
import BigNumber from 'bignumber.js';
import { IconAlertTriangle } from '@tabler/icons-react';

export const globalDiscountsTableColumns = (
  type,
  value,
  selectedItems,
  totalItems = 0,
  discountValueByAmount
) => [
  {
    Header: I18n.get('selectAll', 'Seleccionar todo'),
    accessor: 'code',
    width: '50%',
    Cell: ({ row }) => (
      <TableCellRegular
        value={row?.original?.name}
        description={formatMoney(row?.original?.price || 0)}
      />
    ),
  },
  {
    Header: `${totalItems} ${I18n.get('products', 'productos')}`,
    accessor: 'description',
    alignment: 'right',
    width: '50%',
    Cell: ({ row }) => {
      const item = row?.original;
      const isSelected = selectedItems.find((selectedItem) => selectedItem.id === item?.id);
      const price = item?.price || 0;
      const calculatedPrice =
        type === GLOBAL_DISCOUNTS_TYPES.PERCENTAGE
          ? price * (value / 100)
          : price * (discountValueByAmount / 100);

      return (
        <div className='global-discounts__item-price'>
          {(item?.discount > 0 || item?.discount?.discount > 0) && (
            <Icon color='#FBBF24' icon={IconAlertTriangle} />
          )}
          <TableCellRegular
            value={formatMoney(price)}
            description={
              isSelected
                ? `-${formatMoney(new BigNumber(calculatedPrice).decimalPlaces(2).toNumber())}`
                : item?.discount > 0
                  ? `-${formatMoney(new BigNumber(price * (item?.discount / 100)).decimalPlaces(2).toNumber())}`
                  : '--'
            }
          />
        </div>
      );
    },
  },
];

import { useEffect, useState } from 'react';
import membershipsAPI from '../../reducers/membershipsAPI';
import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  notificationsSelector,
} from '../../selectors/company';
import { isEmpty } from 'lodash';
import { seenWelcome as seenWelcomeSelector } from '../../selectors/onboarding';
import { openModal } from '../../reducers/modals';
import useNotification from '../useNotification/hook';
import {
  FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION,
  getNotificationsAttempts,
} from '../../components/modals/migrationToAlegra2025/utils';
import dayjs from 'dayjs';

const useMigrationToAlegra2025 = () => {
  const company = useSelector(companySelector);
  const seenWelcome = useSelector(seenWelcomeSelector);
  const dispatch = useDispatch();
  const { viewed: firstViewed } = useNotification({
    notificationName: FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION,
  });
  const notifications = useSelector(notificationsSelector);

  const [userIsElegible, setUserElegibility] = useState(false);

  const showMigrationToAlegra2025 = () => {
    if (seenWelcome && !firstViewed) {
      dispatch(openModal({ modal: 'MigrationToAlegra2025' }));
    } else if (seenWelcome && firstViewed) {
      const firstViewedNotification = notifications.find(
        (notification) =>
          notification[FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION]
      )[FIRST_MIGRATION_TO_ALEGRA_2025_NOTIFICATION];

      if (firstViewedNotification) {
        const now = dayjs();

        const notificationsAttempts = getNotificationsAttempts(notifications);

        const firstViewedNotificationDate = dayjs(firstViewedNotification);
        const twoDaysAgo = now.subtract(2, 'day');

        if (!isEmpty(notificationsAttempts)) {
          const mostRecentAttemptDate = notificationsAttempts
            .map((attempt) => dayjs(Object.values(attempt)[0]))
            .reduce((latest, current) =>
              current.isAfter(latest) ? current : latest
            );

          if (mostRecentAttemptDate.isBefore(twoDaysAgo)) {
            dispatch(openModal({ modal: 'MigrationToAlegra2025' }));
          }
        } else if (firstViewedNotificationDate.isBefore(twoDaysAgo)) {
          dispatch(openModal({ modal: 'MigrationToAlegra2025' }));
        }
      }
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const now = dayjs();
        if (now.isBefore(dayjs('2025-03-01'))) {
          const { data } = await membershipsAPI.get(
            `/subscribers/${company?.id}/campaign/is-eligible`
          );
          setUserElegibility(data?.results);
        } else {
          setUserElegibility(false);
        }
      } catch (error) {
        console.log('error when trying to get campaign: ', error);
      }
    }
    fetchData();
  }, []);

  return { userIsElegible, showMigrationToAlegra2025 };
};

export default useMigrationToAlegra2025;

import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { canVoid } from '../detail/utils';
import {
  companySelector,
  country as countrySelector,
  shiftsEnabled,
} from '../../../selectors/company';
import { Icon, Tooltip, useModal } from '@alegradev/smile-ui-react';
import {
  IconLoader2,
  IconNote,
  IconPencil,
  IconPrinter,
  IconRotate,
  IconShare,
} from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import { openModal } from '../../../reducers/modals';
import { setInvoice } from '../../../reducers/editInvoice';
import { syncOffline } from '../../../reducers/activeInvoice';
import { canEdit, canSendOrPrint } from '../utils';
import { hasPermissionTo } from '../../../selectors/auth';
import { useInvoiceDetail } from '../detail/context';
import ShareInvoice from '../../../components/modals/ShareInvoice/ShareInvoice';
import { current, isOpen } from '../../../selectors/shifts';
import { setTypeToPrint } from '../../../reducers/print';
import { sendNewGTMEvent } from '../../../reducers/company';
import Button from '../../../components/common/Button';
import { get } from 'lodash';

export const InvoiceDetailActions = ({ invoice }) => {
  const { isEditable, setIsEditable } = useInvoiceDetail();
  const dispatch = useDispatch();
  const country = useSelector(countrySelector);
  const can = useSelector(hasPermissionTo);
  const isShiftEnabled = useSelector(shiftsEnabled);
  const company = useSelector(companySelector);
  const isShiftOpen = useSelector(isOpen);
  const currentShift = useSelector(current);
  const { openModal: openNewModal } = useModal();

  const actions = useMemo(() => {
    const result = [];

    // Acción para anular factura
    if (
      !invoice.offlineStatus &&
      canVoid({
        invoice,
        country,
        company,
        isShiftEnabled,
        isShiftOpen,
        currentShift,
        can,
      }) &&
      !isEditable
    ) {
      result.push(
        <Tooltip placement='bottom' overlay={I18n.get('void', 'anular')}>
          <Button
            type='button'
            variant='ghost'
            size='icon'
            onClick={() => {
              dispatch(openModal({ modal: 'voidInvoice' }));
            }}
          >
            <Icon icon={IconNote} size='small' />
          </Button>
        </Tooltip>
      );
    }

    // Acciones basadas en offlineStatus
    if (
      !!invoice.offlineStatus &&
      (invoice.offlineStatus === 'error' ||
        invoice.offlineStatus === 'syncing' ||
        (invoice.offlineStatus === 'pending' && invoice.statusInProcess === 1))
    ) {
      result.push(
        <Tooltip placement='bottom' overlay={I18n.get('edit', 'editar')}>
          <Button
            type='button'
            variant='ghost'
            size='icon'
            onClick={() => {
              dispatch(setInvoice(invoice));
              dispatch(openModal({ modal: 'editInvoice' }));
            }}
          >
            <Icon icon={IconPencil} size='small' />
          </Button>
        </Tooltip>
      );

      result.push(
        <Tooltip placement='bottom' overlay={I18n.get('sync', 'sincronizar')}>
          <Button
            type='button'
            variant='ghost'
            size='icon'
            disabled={
              invoice.offlineStatus !== 'error' &&
              !(
                invoice.offlineStatus === 'pending' &&
                invoice.statusInProcess === 1
              )
            }
            loading={invoice.offlineStatus === 'syncing'}
            onClick={() => dispatch(syncOffline(false, true))}
          >
            <Icon
              icon={
                invoice.offlineStatus === 'syncing' ? IconLoader2 : IconRotate
              }
              size='small'
              animated={invoice.offlineStatus === 'syncing'}
            />
          </Button>
        </Tooltip>
      );
    } else if (canEdit({ invoice, can }) && !isEditable) {
      result.push(
        <Tooltip placement='bottom' overlay={I18n.get('edit', 'editar')}>
          <Button
            type='button'
            variant='ghost'
            size='icon'
            onClick={() => setIsEditable(true)}
          >
            <Icon icon={IconPencil} size='small' />
          </Button>
        </Tooltip>
      );
    }

    // Acciones para compartir o imprimir
    if (canSendOrPrint({ invoice, country }) && !isEditable) {
      result.push(
        <Tooltip placement='bottom' overlay={I18n.get('share', 'compartir')}>
          <Button
            type='button'
            variant='ghost'
            size='icon'
            onClick={() =>
              openNewModal({
                component: ShareInvoice,
                name: 'ShareInvoice',
                title: `${I18n.get('share', 'Compartir')} ${I18n.get(
                  'invoice',
                  'factura'
                )}`,
                size: 'large',
                props: { invoice },
              })
            }
          >
            <Icon icon={IconShare} size='small' />
          </Button>
        </Tooltip>
      );

      result.push(
        <Tooltip placement='bottom' overlay={I18n.get('print', 'Imprimir')}>
          <Button
            type='button'
            variant='ghost'
            size='icon'
            onClick={() => {
              dispatch(setTypeToPrint('invoice'));
              dispatch(
                sendNewGTMEvent('pos-sale-managed', {
                  id: get(invoice, 'id', ''),
                  action: 'print',
                })
              );
            }}
          >
            <Icon icon={IconPrinter} size='small' />
          </Button>
        </Tooltip>
      );
    }

    return result;
  }, [
    invoice,
    country,
    company,
    isShiftEnabled,
    isShiftOpen,
    currentShift,
    can,
    isEditable,
    dispatch,
  ]);

  return <div className='d-flex gap-3'>{actions}</div>;
};

export default InvoiceDetailActions;

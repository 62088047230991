import { get, isEqual } from 'lodash';
import { useState, useEffect, useCallback } from 'react';
import { refresh } from '../../reducers/paymentMethods';
import { useDispatch } from 'react-redux';

const usePaymentMethodChange = () => {
  const [paymentMethods, setPaymentMethods] = useState({});
  const [hiddenImages, setHiddenImages] = useState([]);
  const dispatch = useDispatch();

  const safeJSONParse = (value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      console.warn('Valor inválido para JSON.parse:', value);
      return {};
    }
  };

  const handleStorageChange = useCallback(
    (event) => {
      const oldValue = safeJSONParse(get(event, 'oldValue', '{}'));
      const newValue = safeJSONParse(get(event, 'newValue', '{}'));
      const oldPaymentMethod = get(oldValue, 'paymentMethods.entities', {});
      const newPaymentMethod = get(newValue, 'paymentMethods.entities', {});

      if (!isEqual(oldPaymentMethod, newPaymentMethod)) {
        dispatch(refresh());
        setPaymentMethods(newPaymentMethod);

        const imagesToLoad = Object.entries(newPaymentMethod).reduce(
          (acc, [key, method]) => {
            if (method.logo?.url) {
              acc.push(
                <img
                  key={`logo-${key}`}
                  src={method.logo.url}
                  style={{ display: 'none' }}
                />
              );
            }
            if (method.qr?.url) {
              acc.push(
                <img
                  key={`qr-${key}`}
                  src={method.qr.url}
                  style={{ display: 'none' }}
                />
              );
            }
            return acc;
          },
          []
        );

        setHiddenImages(imagesToLoad);
      }
    },
    [dispatch]
  );

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [handleStorageChange]);

  return {
    paymentMethods,
    hiddenImages,
  };
};

export default usePaymentMethodChange;

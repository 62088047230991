import { createSlice } from '@reduxjs/toolkit';

const monitoringInitial = {
  startTime: null,
  endTime: null,
};

const initialState = {
  invoice: monitoringInitial,
  tryToInvoice: monitoringInitial,
  loadItems: monitoringInitial,
  openShift: monitoringInitial,
  closeShift: monitoringInitial,
  createStation: monitoringInitial,
  createSeller: monitoringInitial,
  createRefund: monitoringInitial,
  createCashFlow: monitoringInitial,
  createContact: monitoringInitial,
  createItem: monitoringInitial,
  createInventoryAdjustment: monitoringInitial,
  createWarehouse: monitoringInitial,
  createPriceList: monitoringInitial,
  createCategory: monitoringInitial,
  createVariant: monitoringInitial,
  createCustomField: monitoringInitial,
  version: 1
};

const appSlice = createSlice({
  name: 'monitoring',
  initialState,
  reducers: {
    startAction: (state, action) => {
      state[action.payload.action].startTime = new Date();
    },
    endAction: (state, action) => {
      state[action.payload.action].endTime = new Date();
    },
  },
});

const { actions, reducer } = appSlice;

export const {
    startAction,
    endAction
} = actions;

export default reducer;

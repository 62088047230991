export const pharmaceuticalForms = [
    {
        "id": "01",
        "value": "Anillo vaginal"
    },
    {
        "id": "02",
        "value": "Aposito"
    },
    {
        "id": "03",
        "value": "Capleta"
    },
    {
        "id": "04",
        "value": "Cápsula"
    },
    {
        "id": "05",
        "value": "Cápsula blanda"
    },
    {
        "id": "06",
        "value": "Capsula con granulado con recubrimiento enterico"
    },
    {
        "id": "07",
        "value": "Cápsula con gránulos de liberación retardada"
    },
    {
        "id": "08",
        "value": "Cápsula con pellets entéricos"
    },
    {
        "id": "09",
        "value": "Capsula de gelatina blanda"
    },
    {
        "id": "10",
        "value": "Cápsula de gelatina dura"
    },
    {
        "id": "11",
        "value": "Capsula de gelatina suave"
    },
    {
        "id": "12",
        "value": "Cápsula de liberación controlada"
    },
    {
        "id": "13",
        "value": "Capsula de liberacion prolongada"
    },
    {
        "id": "14",
        "value": "Capsula de liberacion retardada"
    },
    {
        "id": "15",
        "value": "Cápsula de liberación retardada (conteniendo gránulos con cubierta entéric)"
    },
    {
        "id": "16",
        "value": "Capsula de liberacion retardada gastrorresistente"
    },
    {
        "id": "17",
        "value": "Cápsula de liberación retardada(conteniendo gránulos con cubierta entérica)"
    },
    {
        "id": "18",
        "value": "Capsula dura"
    },
    {
        "id": "19",
        "value": "Capsula dura de liberacion prolongada"
    },
    {
        "id": "20",
        "value": "Capsula gastrorresistente"
    },
    {
        "id": "21",
        "value": "Cápsula para inhalación"
    },
    {
        "id": "22",
        "value": "Capsulas con granulos entericos"
    },
    {
        "id": "23",
        "value": "Cápsulas con microgránulos de liberación prolongada"
    },
    {
        "id": "24",
        "value": "Capsulas con microgranulos gastrorresistentes"
    },
    {
        "id": "25",
        "value": "Cápsulas con pellets de liberación prolongada"
    },
    {
        "id": "26",
        "value": "Cápsulas de liberación modificada"
    },
    {
        "id": "27",
        "value": "Capsulas duras de liberación prolongada"
    },
    {
        "id": "28",
        "value": "Cápsulas duras gastrorresistentes"
    },
    {
        "id": "29",
        "value": "Cápsulas para inhalación"
    },
    {
        "id": "30",
        "value": "Caramelo"
    },
    {
        "id": "31",
        "value": "Champu"
    },
    {
        "id": "32",
        "value": "Comprimido"
    },
    {
        "id": "33",
        "value": "Comprimido bicapa"
    },
    {
        "id": "34",
        "value": "Comprimido bucodispersable"
    },
    {
        "id": "35",
        "value": "Comprimido con cubierta pelicular"
    },
    {
        "id": "36",
        "value": "Comprimido con recubrimiento enterico"
    },
    {
        "id": "37",
        "value": "Comprimido de disolucion bucal"
    },
    {
        "id": "38",
        "value": "Comprimido de liberación modificada"
    },
    {
        "id": "39",
        "value": "Comprimido de liberación osmótica"
    },
    {
        "id": "40",
        "value": "Comprimido de liberacion prolongada"
    },
    {
        "id": "41",
        "value": "Comprimido de liberacion retardada"
    },
    {
        "id": "42",
        "value": "Comprimido dispersable"
    },
    {
        "id": "43",
        "value": "Comprimido dispersable / masticable"
    },
    {
        "id": "44",
        "value": "Comprimido efervescente"
    },
    {
        "id": "45",
        "value": "Comprimido entérico"
    },
    {
        "id": "46",
        "value": "Comprimido gastrorresistente"
    },
    {
        "id": "47",
        "value": "Comprimido masticable"
    },
    {
        "id": "48",
        "value": "Comprimido orodispersable"
    },
    {
        "id": "49",
        "value": "Comprimido peliculado de liberacion modificada"
    },
    {
        "id": "50",
        "value": "Comprimido peliculado de liberación prolongada"
    },
    {
        "id": "51",
        "value": "Comprimido ranurado"
    },
    {
        "id": "52",
        "value": "Comprimido recubierto"
    },
    {
        "id": "53",
        "value": "Comprimido recubierto con película"
    },
    {
        "id": "54",
        "value": "Comprimido recubierto de liberación prolongada"
    },
    {
        "id": "55",
        "value": "Comprimido recubierto gastroresistente"
    },
    {
        "id": "56",
        "value": "Comprimido sublingual"
    },
    {
        "id": "57",
        "value": "Comprimidos de liberación prolongada recubiertos con película"
    },
    {
        "id": "58",
        "value": "Comprimidos ranurados de liberación modificada"
    },
    {
        "id": "59",
        "value": "Comprimidos recubiertos de liberación controlada"
    },
    {
        "id": "60",
        "value": "Comprimidos recubiertos de liberación sostenida"
    },
    {
        "id": "61",
        "value": "Comprimidos revestidos"
    },
    {
        "id": "62",
        "value": "Concentrado para solución inyectable"
    },
    {
        "id": "63",
        "value": "Concentrado para solución para infusión"
    },
    {
        "id": "64",
        "value": "Concentrado para solución para perfusión"
    },
    {
        "id": "65",
        "value": "Crema"
    },
    {
        "id": "66",
        "value": "Crema vaginal"
    },
    {
        "id": "67",
        "value": "Dispersión liposomal inyectable endovenosa"
    },
    {
        "id": "68",
        "value": "Dispositivo vaginal con polvo"
    },
    {
        "id": "69",
        "value": "Elixir"
    },
    {
        "id": "70",
        "value": "Emulsion"
    },
    {
        "id": "71",
        "value": "Emulsion inyectable"
    },
    {
        "id": "72",
        "value": "Emulsion oral"
    },
    {
        "id": "73",
        "value": "Enema"
    },
    {
        "id": "74",
        "value": "Enjuague bucal"
    },
    {
        "id": "75",
        "value": "Espuma"
    },
    {
        "id": "76",
        "value": "Gas medicinal"
    },
    {
        "id": "77",
        "value": "Gas para inhalación"
    },
    {
        "id": "78",
        "value": "Gasa impregnada con ungüento"
    },
    {
        "id": "79",
        "value": "Gel"
    },
    {
        "id": "80",
        "value": "Gel capilar"
    },
    {
        "id": "81",
        "value": "Gel oftalmico"
    },
    {
        "id": "82",
        "value": "Gel oral"
    },
    {
        "id": "83",
        "value": "Gel vaginal"
    },
    {
        "id": "84",
        "value": "Gotas óticas en solución"
    },
    {
        "id": "85",
        "value": "Gragea"
    },
    {
        "id": "86",
        "value": "Gragea de liberación prolongada"
    },
    {
        "id": "87",
        "value": "Gragea gastroresistente"
    },
    {
        "id": "88",
        "value": "Granulado para solución oral"
    },
    {
        "id": "89",
        "value": "Granulado para suspension"
    },
    {
        "id": "90",
        "value": "Gránulo gastrorresistente para suspensión"
    },
    {
        "id": "91",
        "value": "Granulos"
    },
    {
        "id": "92",
        "value": "Granulos de liberacion prolongada"
    },
    {
        "id": "93",
        "value": "Gránulos de liberación prolongada para suspensión"
    },
    {
        "id": "94",
        "value": "Granulos efervescentes"
    },
    {
        "id": "95",
        "value": "Granulos gastro-resistentes"
    },
    {
        "id": "96",
        "value": "Granulos para solucion"
    },
    {
        "id": "97",
        "value": "Granulos para suspension"
    },
    {
        "id": "98",
        "value": "Implante"
    },
    {
        "id": "99",
        "value": "Jabon solido"
    },
    {
        "id": "100",
        "value": "Jalea"
    },
    {
        "id": "101",
        "value": "Jarabe"
    },
    {
        "id": "102",
        "value": "Jarabe (solucion oral)"
    },
    {
        "id": "103",
        "value": "Laca para uñas"
    },
    {
        "id": "104",
        "value": "Liofilizado para suspensión inyectable"
    },
    {
        "id": "105",
        "value": "Líquido"
    },
    {
        "id": "106",
        "value": "Líquido anestésico"
    },
    {
        "id": "107",
        "value": "Liquido para inhalación"
    },
    {
        "id": "108",
        "value": "Líquido para inhalación del vapor"
    },
    {
        "id": "109",
        "value": "Líquido viscoso"
    },
    {
        "id": "110",
        "value": "Locion"
    },
    {
        "id": "111",
        "value": "Microesferas liofilizadas estériles"
    },
    {
        "id": "112",
        "value": "Microesferas para suspensión inyectable"
    },
    {
        "id": "113",
        "value": "Mixtura"
    },
    {
        "id": "114",
        "value": "Óvulo"
    },
    {
        "id": "115",
        "value": "Parche"
    },
    {
        "id": "116",
        "value": "Parche transdermico"
    },
    {
        "id": "117",
        "value": "Pastilla"
    },
    {
        "id": "118",
        "value": "Pelicula bucodispersable"
    },
    {
        "id": "119",
        "value": "Polvo"
    },
    {
        "id": "120",
        "value": "Polvo  concentrado para solución para infusión"
    },
    {
        "id": "121",
        "value": "Polvo  para suspensión de lib. Prolong para jeringa  prellenada"
    },
    {
        "id": "122",
        "value": "Polvo cristalino para solución inyectable"
    },
    {
        "id": "123",
        "value": "Polvo efervescente"
    },
    {
        "id": "124",
        "value": "Polvo estéril para solución inyectable"
    },
    {
        "id": "125",
        "value": "Polvo granulado"
    },
    {
        "id": "126",
        "value": "Polvo granulado para solución oral"
    },
    {
        "id": "127",
        "value": "Polvo liofilizado"
    },
    {
        "id": "128",
        "value": "Polvo liofilizado para concentrado para solución para perfusión"
    },
    {
        "id": "129",
        "value": "Polvo liofilizado para solución inyectable"
    },
    {
        "id": "130",
        "value": "Polvo liofilizado para solución para infusión intravenosa"
    },
    {
        "id": "131",
        "value": "Polvo liofilizado para solución para perfusión"
    },
    {
        "id": "132",
        "value": "Polvo liofilizado para suspensión inyectable"
    },
    {
        "id": "133",
        "value": "Polvo liofilizado para suspensión inyectable de liberación prolongada"
    },
    {
        "id": "134",
        "value": "Polvo liofilizado para uso parenteral"
    },
    {
        "id": "135",
        "value": "Polvo para concentrado para solución para infusión"
    },
    {
        "id": "136",
        "value": "Polvo para concentrado para solución para infusión intravenosa"
    },
    {
        "id": "137",
        "value": "Polvo para concentrado para solución para perfusión"
    },
    {
        "id": "138",
        "value": "Polvo para concentrado para solución para perfusión intravenoso"
    },
    {
        "id": "139",
        "value": "Polvo para infusion"
    },
    {
        "id": "140",
        "value": "Polvo para inhalacion"
    },
    {
        "id": "141",
        "value": "Polvo para inhalación en cápsulas"
    },
    {
        "id": "142",
        "value": "Polvo para inhalacion en capsulas duras"
    },
    {
        "id": "143",
        "value": "Polvo para solucion"
    },
    {
        "id": "144",
        "value": "Polvo para solución estéril inyectable"
    },
    {
        "id": "145",
        "value": "Polvo para solucion inyectable"
    },
    {
        "id": "146",
        "value": "Polvo para solucion oral"
    },
    {
        "id": "147",
        "value": "Polvo para solución para infusión"
    },
    {
        "id": "148",
        "value": "Polvo para solución para perfusión"
    },
    {
        "id": "149",
        "value": "Polvo para suspensión"
    },
    {
        "id": "150",
        "value": "Polvo para suspension inyectable"
    },
    {
        "id": "151",
        "value": "Polvo para suspensión oral"
    },
    {
        "id": "152",
        "value": "Polvo para suspensión oral y rectal"
    },
    {
        "id": "153",
        "value": "Polvo topico"
    },
    {
        "id": "154",
        "value": "Polvo y disolvente para solución inyectable."
    },
    {
        "id": "155",
        "value": "Pomada"
    },
    {
        "id": "156",
        "value": "Sistema de liberación vaginal"
    },
    {
        "id": "157",
        "value": "Sistema intrauterino de liberación"
    },
    {
        "id": "158",
        "value": "Solución"
    },
    {
        "id": "159",
        "value": "Solución anestésica inhalatoria"
    },
    {
        "id": "160",
        "value": "Solucion concentrada"
    },
    {
        "id": "161",
        "value": "Solución concentrada para infusión"
    },
    {
        "id": "162",
        "value": "Solución de infusión para aplicación intravenosa"
    },
    {
        "id": "163",
        "value": "Solución en aerosol"
    },
    {
        "id": "164",
        "value": "Solución en spray"
    },
    {
        "id": "165",
        "value": "Solución estéril"
    },
    {
        "id": "166",
        "value": "Solución estéril para inyección"
    },
    {
        "id": "167",
        "value": "Solución gotas"
    },
    {
        "id": "168",
        "value": "Solución intraocular"
    },
    {
        "id": "169",
        "value": "Solucion inyectable"
    },
    {
        "id": "170",
        "value": "Solución inyectable concentrada"
    },
    {
        "id": "171",
        "value": "Solución inyectable concentrado para infusión"
    },
    {
        "id": "172",
        "value": "Solución inyectable de liberación prolongada"
    },
    {
        "id": "173",
        "value": "Solucion inyectable para infusion"
    },
    {
        "id": "174",
        "value": "Solución oftálmica"
    },
    {
        "id": "175",
        "value": "Solución oleosa inyectable"
    },
    {
        "id": "176",
        "value": "Solucion oral"
    },
    {
        "id": "177",
        "value": "Solucion para dialisis peritoneal"
    },
    {
        "id": "178",
        "value": "Solucion para hemodialisis"
    },
    {
        "id": "179",
        "value": "Solución para infusión  intravenosa"
    },
    {
        "id": "180",
        "value": "Solucion para inhalacion"
    },
    {
        "id": "181",
        "value": "Solucion para irrigacion"
    },
    {
        "id": "182",
        "value": "Solucion para nebulizacion"
    },
    {
        "id": "183",
        "value": "Solución para perfusión"
    },
    {
        "id": "184",
        "value": "Solucion para uñas"
    },
    {
        "id": "185",
        "value": "Solucion rectal"
    },
    {
        "id": "186",
        "value": "Spray solución nasal"
    },
    {
        "id": "187",
        "value": "Supositorio"
    },
    {
        "id": "188",
        "value": "Suspension"
    },
    {
        "id": "189",
        "value": "Suspensión  para inhalación"
    },
    {
        "id": "190",
        "value": "Suspensión acuosa"
    },
    {
        "id": "191",
        "value": "Suspensión en aerosol"
    },
    {
        "id": "192",
        "value": "Suspensión en spray"
    },
    {
        "id": "193",
        "value": "Suspensión estéril"
    },
    {
        "id": "194",
        "value": "Suspensión inyectable"
    },
    {
        "id": "195",
        "value": "Suspensión inyectable de liberación prolongada"
    },
    {
        "id": "196",
        "value": "Suspension oral"
    },
    {
        "id": "197",
        "value": "Suspensión para inhalación"
    },
    {
        "id": "198",
        "value": "Suspensión presurizada"
    },
    {
        "id": "199",
        "value": "Tableta"
    },
    {
        "id": "200",
        "value": "Tableta bicapa"
    },
    {
        "id": "201",
        "value": "Tableta con capa entérica"
    },
    {
        "id": "202",
        "value": "Tableta con cubierta entérica"
    },
    {
        "id": "203",
        "value": "Tableta cubierta"
    },
    {
        "id": "204",
        "value": "Tableta de acción prolongada"
    },
    {
        "id": "205",
        "value": "Tableta de liberacion prolongada"
    },
    {
        "id": "206",
        "value": "Tableta de liberación retardada."
    },
    {
        "id": "207",
        "value": "Tableta dispersable"
    },
    {
        "id": "208",
        "value": "Tableta efervescente"
    },
    {
        "id": "209",
        "value": "Tableta masticable"
    },
    {
        "id": "210",
        "value": "Tableta orodispersable"
    },
    {
        "id": "211",
        "value": "Tableta para chupar"
    },
    {
        "id": "212",
        "value": "Tableta ranurada"
    },
    {
        "id": "213",
        "value": "Tableta recubierta"
    },
    {
        "id": "214",
        "value": "Tableta recubierta con película"
    },
    {
        "id": "215",
        "value": "Tableta recubierta de liberación extendida"
    },
    {
        "id": "216",
        "value": "Tableta recubierta de liberacion modificada"
    },
    {
        "id": "217",
        "value": "Tableta recubierta de liberación prolongada"
    },
    {
        "id": "218",
        "value": "Tableta recubierta entérica"
    },
    {
        "id": "219",
        "value": "Tableta recubierta gastrorresistente"
    },
    {
        "id": "220",
        "value": "Tableta revestida"
    },
    {
        "id": "221",
        "value": "Tableta sublingual"
    },
    {
        "id": "222",
        "value": "Tabletas de disolución oral instantánea"
    },
    {
        "id": "223",
        "value": "Tabletas de liberación extendida"
    },
    {
        "id": "224",
        "value": "Tabletas dispersables / masticables"
    },
    {
        "id": "225",
        "value": "Tabletas laqueadas"
    },
    {
        "id": "226",
        "value": "Tabletas recubiertas de acción prolongada"
    },
    {
        "id": "227",
        "value": "Tabletas recubiertas de liberacion retardada"
    },
    {
        "id": "228",
        "value": "Tintura"
    },
    {
        "id": "229",
        "value": "Unguento"
    }
]
import es from './es'

const dictionary = {
  ...es,
  "AlegraAccounting": "Contabilidad",
  "AlegraAccounting.description": "Gestiona las cuentas de tu negocio",
  "AlegraPOS": "POS",
  "AlegraPOS.description": "Factura fácil en tus puntos de venta",
  "AlegraShop.description": "Crea tu tienda virtual fácil y rápido",
  "alwaysVisibleInFE": "Siempre será visible en tus documentos electrónicos.",
  "city": "municipio",
  "emissionStatus": "Estado DIAN",
  "immediatePaymentSale": "Ventas de contado",
  "invoiceModalTitle.saleTicket": "documento POS",
  "invoicePrintFooter": "soluciones  Alegra S.A.S - NIT 900.559.088-2 - alegra.com/pos",
  "learnToUseShifts.link": "https://ayuda.alegra.com/es/turnos-de-caja-alegra-pos-colombia",
  "numerationExpired.body": "Elige otra numeración o habilita los rangos en la DIAN para seguir facturando.",
  "numerationExpired.button": "Cómo habilitar rangos",
  "numerationExpired.notification.title": "¡Se venció la fecha de tu numeración! 📅",
  "numerationExpired.notification.text.1": "Ten en cuenta que la numeración {} ya está vencida y a partir del 13 de julio no podrás usarla al vender.",
  "numerationExpired.notification.text": "Ten en cuenta que la numeración {} ya está vencida y a partir del 17 de julio no podrás usarla al vender.",
  "numerationMaxNumberReached.body": "Elige otra numeración o solicita una nueva ante la DIAN para seguir facturando.",
  "numerationMaxNumberReached.button": "Cómo solicitar otra",
  "numerationMaxNumberReached.notification.title": "¡Se acabaron los consecutivos de tu numeración! 🚨",
  "numerationMaxNumberReached.notification.text": "Ten en cuenta que la numeración {} se agotó y a partir del 17 de julio no podrás usarla al vender.",
  "numerationMaxNumberReached.notification.text.1": "Ten en cuenta que la numeración {} se agotó y a partir del 13 de julio no podrás usarla al vender.",
  "prepareFEServiceDown": "¡Prepárate para el mantenimiento de la DIAN!",
  "prepareFEServiceDown.description": "Hoy {} de {} entre las {} y las {} el Sistema de Facturación Electrónica estará en mantenimiento.",
  "networkErrorMessage": "Revisa tu conexión a internet e intenta emitir de nuevo tu documento",
  "networkErrorMessageDailyReport": "Podrás exportar tu informe diario en cuanto recuperes tu conexión.",
  "saleTicket": "documento",
  "seeMore": "Saber más",
  "ticketSyncErrorOfflineSubtitle": "Podrás consultar el documento POS en tu historial de ventas en cuanto recuperes tu conexión.",
  "youHavePendingInvoicesToEmit": "¡Tienes documentos de venta por emitir! 🚨",
  "youHavePendingInvoicesToEmit.invoice": "¡Tienes facturas por emitir! 🚨",
  "youHavePendingInvoicesToEmit.saleTicket": "¡Tienes documentos POS por emitir! 🚨",
  "youHavePendingInvoicesToEmit.creditNote": "¡Tienes notas crédito por emitir! 📢",
  "youHavePendingInvoicesToEmit.adjustNote": "¡Tienes notas de ajuste por emitir! 📢",
  "youHavePendingInvoicesToEmit.description": "Aprende a identificar los documentos electrónicos que tienes “Por emitir” y envíalos a la DIAN con un clic.",
  "youHavePendingInvoicesToEmit.description.invoice": "Ingresa a tu historial de ventas para revisar las facturas con estado “Por emitir” y envíalas a la DIAN con un clic.",
  "youHavePendingInvoicesToEmit.description.saleTicket": "Ingresa a tu historial de ventas para revisar los documentos POS con estado “Por emitir” y envíalos a la DIAN con un clic.",
  "youHavePendingInvoicesToEmit.description.creditNote": "Ingresa a tus devoluciones para revisar las notas crédito con estado “Por emitir” y envíalas a la DIAN con un clic.",
  "youHavePendingInvoicesToEmit.description.adjustNote": "Ingresa a tus devoluciones para revisar las notas de ajuste con estado “Por emitir” y envíalas a la DIAN con un clic.",
  "youHavePendingInvoicesToEmit.link": "https://ayuda.alegra.com/es/consulta-estado-emision-documentos-electronicos-pos-colombia",
  "solutions.description.new": "Más soluciones para tu negocio",
  "AlegraPayroll.new": "Nómina",
  "AlegraPayroll.description.new": "Emite fácil la nómina de tu equipo",
  "AlegraAccounting.new": "Contabilidad",
  "AlegraAccounting.description.new": "Contabiliza, factura y controla tu stock",
  "AlegraPOS.new": "POS",
  "AlegraPOS.description.new": "Vende y factura electrónicamente",
  "AlegraShop.new": "Tienda",
  "AlegraShop.description.new": "Crea tu primera tienda online en 4 clics",
  "AlegraInvoicing.new": "Facturación",
  "AlegraInvoicing.description.new": "Factura electrónicamente con un clic",
  "electronicPayrollTutorial.video.id": "tkWsUbovCks",
  "youCanNowMakeElectronicInvoices.description": "Actívate ante la DIAN en menos de 10 minutos y empieza a crear facturas electrónicas en tu punto de venta.",
  "importProducts.text": "Importar productos",
  "importProducts.tooltip": "Te llevaremos a Alegra Contabilidad para que los importes de manera masiva.",
  "solutionModal.accounting.supTitle": "Tus cuentas claras con",
  "solutionModal.accounting.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.accounting.buttonSecondaryText": "Más información",
  "solutionModal.accounting.description": "Con Alegra Contabilidad controlas tus ingresos y gastos con un clic, y obtienes reportes inteligentes para que estés siempre al día con la DIAN.",
  "solutionModal.accounting.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.store.supTitle": "Incrementa tus ventas con",
  "solutionModal.store.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.store.buttonSecondaryText": "Más información",
  "solutionModal.store.description": "¡Empieza a vender online en segundos!\nCon Alegra Tienda creas tu primera tienda online en solo 4 clics.",
  "solutionModal.store.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.payroll.supTitle": "Tu equipo al día con",
  "solutionModal.payroll.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.payroll.buttonSecondaryText": "Más información",
  "solutionModal.payroll.description": "Con Alegra Nómina resuelves en 10 minutos tu nómina electrónica y emites en segundos tus comprobantes de pago a la DIAN.",
  "solutionModal.payroll.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "solutionModal.pos.supTitle": "Gestiona tu negocio con",
  "solutionModal.pos.buttonMainText": "Prueba 15 días gratis",
  "solutionModal.pos.buttonSecondaryText": "Más información",
  "solutionModal.pos.description": "Factura en segundos con el sistema POS más veloz e intuitivo del mercado, además, podrás generar tus reportes de ventas e inventario en segundos. ⚡",
  "solutionModal.pos.subTitle": "*Después de los 15 días puedes elegir un plan desde {{price}} {{currency}} al mes.",
  "userNotAllowed.edit.saleTicket": "Necesitas permiso del administrador para editar ventas.",
  "userNotAllowed.edit.invoice": "Necesitas permiso del administrador para editar facturas.",
  "POSTutorial": "Curso introductorio del POS",
  "aboutPOS": "Aprende a usar tu punto de venta",
  "numerationElectronicOffline": "Necesitas conexión a internet para crear y enviar documentos electrónicos a la DIAN.",
  'invoiceCreatedElectronicMessageTitle':"Emitiendo",
  'invoiceCreatedElectronicMessage':"Estamos enviando tu factura a la DIAN ⚡",
  "invoiceStampedSuccess": "Tu {} se envió con éxito a la DIAN y ya se encuentra {}",
  "accountingAndTaxConfig": "Configuración contable y fiscal",
  "autocompleteTestTitle": "Prueba el autocompletado ✨",
  "autocompleteTestDescription": "Escribe el número de identificación de tu cliente y obtén sus datos de la DIAN.",
  "findYourIdealHardware": "Descubre tu hardware ideal",
  "nitAutocompletion": "El autocompletado funciona para clientes con NIT <br/> registrados en la Cámara de Comercio.",
  "autocompletingNotAvailable": "Autocompletado sin resultados",
  "autocompletingNotAvailableDescription": "Si el número de identificación es correcto, te recomendamos <br/> completar los datos de forma manual.",
  "sendToEmissionEntity": "Enviando a la DIAN...",
  "newInvoiceModal.title.documentoPOS": "documento POS",
  "invoicePrintFooter.electronic": "Proveedor tecnológico: Soluciones Alegra S.A.S - Software: Alegra POS - NIT 900.559.088-2",
}

export default dictionary;
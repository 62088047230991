import { format, parse } from 'date-fns';
import { find } from 'lodash';
import { get, map } from 'lodash';
import { periodicity } from '../../../../components/forms/globalInvoice/utils';

const CFDIVersion = '4.0';

export function globalInvoiceConverterToApi(values) {
  return {
    numberTemplate: {
      id: get(values, 'numeration.id'),
      prefix: get(values, 'numeration.prefix'),
      number: get(values, 'idNumberTemplate', '').replace(
        get(values, 'numeration.prefix'),
        ''
      ),
    },
    periodicity: {
      period: get(values, 'periodicityPeriod.value'),
      month: format(get(values, 'periodicityMonth'), 'MM'),
      year: format(get(values, 'periodicityYear'), 'yyyy'),
    },
    date: format(get(values, 'creationDate'), 'yyyy-MM-dd'),
    stamp: {
      generateStamp: values?.isDraft ? false : true,
      version: CFDIVersion,
    },
    status: values?.isDraft ? 'draft' : '',
    saleTickets: map(get(values, 'selectedRows'), (row) => ({
      id: row.id,
      idLocal: row.idLocal,
    })),
  };
}

export function globalInvoiceConverterToData(data, numerations) {
  const periodValues = get(data, 'periodicity.period');
  const periodMonth = parse(get(data, 'periodicity.month'), 'dd', new Date());
  const periodYear = get(data, 'periodicity.year')
    ? parse(get(data, 'periodicity.year'), 'yyyy', new Date())
    : parse(new Date().getFullYear().toString(), 'yyyy', new Date());

  return {
    periodicityPeriod: find(periodicity, {
      value: periodValues,
    }),
    id: get(data, 'id'),
    creationDate: new Date(get(data, 'date')),
    periodicityMonth: periodMonth,
    periodicityYear: periodYear,
    idNumberTemplate: get(data, 'numberTemplate.number'),
    numeration: find(numerations, {
      prefix: get(data, 'numberTemplate.prefix'),
    }),
  };
}

export const costaRicaEconomicActivities = [
  {
    id: 1,
    code: '11101',
    value: 'Cultivo de otros productos n.c.p.',
  },
  {
    id: 2,
    code: '11102',
    value: 'Cultivo de palma africana y otros frutos oleaginosos',
  },
  {
    id: 3,
    code: '11103',
    value: 'Cultivo y comercialización de césped',
  },
  {
    id: 4,
    code: '11201',
    value:
      'Cultivo hortalizas legumbres especialidades hortícolas productos de vivero excento de ventas',
  },
  {
    id: 5,
    code: '11218',
    value: 'Producción de minivegetales',
  },
  {
    id: 6,
    code: '11231',
    value:
      'Cultivo hortalizas legumbres especialidades hortícolas productos vivero grabados ventas',
  },
  {
    id: 7,
    code: '11301',
    value: 'Cultivo de especias de todo tipo',
  },
  {
    id: 8,
    code: '11302',
    value: 'Cultivo de frutas',
  },
  {
    id: 9,
    code: '11322',
    value:
      'Cultivo de semillas comestibles y germinación de semillas oleaginosas',
  },
  {
    id: 10,
    code: '11329',
    value: 'Cultivo de plantas para preparar bebidas y medicinas',
  },
  {
    id: 11,
    code: '11340',
    value: 'Cultivo de piña',
  },
  {
    id: 12,
    code: '11349',
    value: 'Cultivo de cacao',
  },
  {
    id: 13,
    code: '11401',
    value: 'Cultivo de café',
  },
  {
    id: 14,
    code: '11501',
    value: 'Cultivo de banano',
  },
  {
    id: 15,
    code: '11701',
    value: 'Cultivo de caña de azúcar',
  },
  {
    id: 16,
    code: '11802',
    value:
      'Cultivo de cereales (inclusive el arroz), legumbres y granos básicos',
  },
  {
    id: 17,
    code: '11901',
    value: 'Cultivo de flores de todo tipo',
  },
  {
    id: 18,
    code: '11903',
    value: 'Viveros',
  },
  {
    id: 19,
    code: '12101',
    value: 'Cría de caballos',
  },
  {
    id: 20,
    code: '12102',
    value:
      'Producción de semen bovino, venta de semen congelado y diluyente para semen',
  },
  {
    id: 21,
    code: '12103',
    value:
      'Cría de ganado vacuno y de ovejas, cabras, caballos, asnos, mulas y burdéganos; cría de ganado lechero',
  },
  {
    id: 22,
    code: '12201',
    value: 'Cría de cerdos',
  },
  {
    id: 23,
    code: '12202',
    value: 'Cría de animales domesticados (aves de corral)',
  },
  {
    id: 24,
    code: '12203',
    value: 'Cría de mariposas',
  },
  {
    id: 25,
    code: '12204',
    value: 'Cría y venta de otros animales semidomesticados o salvajes',
  },
  {
    id: 26,
    code: '12303',
    value:
      'Producción de queso y otros productos lácteos exentos del impuesto sobre las ventas',
  },
  {
    id: 27,
    code: '12401',
    value:
      'Producción y venta de huevos de cualquier tipo, excepto los de gallina',
  },
  {
    id: 28,
    code: '12402',
    value: 'Producción y venta de miel de abeja natural',
  },
  {
    id: 29,
    code: '12403',
    value: 'Producción de huevos de gallina incluidos en la canasta básica',
  },
  {
    id: 30,
    code: '13001',
    value:
      'Cultivo de productos agrícolas en combinación con la cría de animales (explotación)',
  },
  {
    id: 31,
    code: '14001',
    value: 'Servicio de jardinería y/o diseño paisajista',
  },
  {
    id: 32,
    code: '14002',
    value: 'Recolección de cosechas y actividades conexas',
  },
  {
    id: 33,
    code: '14003',
    value: 'Fumigación de cultivos',
  },
  {
    id: 34,
    code: '14004',
    value: 'Servicio de plantación de arboles y similares',
  },
  {
    id: 35,
    code: '14005',
    value: 'Manejo e instalación de sistemas de riego',
  },
  {
    id: 36,
    code: '15001',
    value:
      'Caza ordinaria mediante trampas, y repoblación de animales de caza, incluso las actividades de servicios conexas.',
  },
  {
    id: 37,
    code: '20001',
    value: 'Venta de arboles en pie (arboles de reforestación)',
  },
  {
    id: 38,
    code: '20002',
    value: 'Extracción y/o venta de madera',
  },
  {
    id: 39,
    code: '20005',
    value:
      'Actividades de conservación de bosques. (servicios ambientales, venta de oxigeno).',
  },
  {
    id: 40,
    code: '50001',
    value: 'Pescadores artesanales en peq. escala',
  },
  {
    id: 41,
    code: '50002',
    value: 'Pescadores artesanales medios',
  },
  {
    id: 42,
    code: '50003',
    value:
      'Criadero de peces, crustáceos o moluscos. comercialización de larvas de especies marinas',
  },
  {
    id: 43,
    code: '50005',
    value: 'Pescadores en gran escala',
  },
  {
    id: 44,
    code: '101001',
    value: 'Extracción y aglomeración de carbón de piedra',
  },
  {
    id: 45,
    code: '111001',
    value: 'Extracción de petróleo crudo y gas natural',
  },
  {
    id: 46,
    code: '120001',
    value: 'Extracción de minerales de uranio y torio',
  },
  {
    id: 47,
    code: '141001',
    value: 'Extracción de piedra, arena y arcilla',
  },
  {
    id: 48,
    code: '142101',
    value: 'Extracción de minerales y sustancias para la fabricación de abonos',
  },
  {
    id: 49,
    code: '142201',
    value: 'Extracción de sal',
  },
  {
    id: 50,
    code: '142901',
    value: 'Explotación de otras minas y canteras n.c.p.',
  },
  {
    id: 51,
    code: '142902',
    value: 'Venta de asfalto/mezcla asfáltica',
  },
  {
    id: 52,
    code: '151101',
    value:
      'Producción, procesamiento y conservación de carnes y embutidos (sin envasar)',
  },
  {
    id: 53,
    code: '151103',
    value: 'Producción de cueros y pieles sin curtir',
  },
  {
    id: 54,
    code: '151105',
    value: 'Producción de embutidos empacados, envasado y enlatado',
  },
  {
    id: 55,
    code: '151201',
    value: 'Elaboración y conservación de pescado y sus derivados',
  },
  {
    id: 56,
    code: '151301',
    value: 'Elaboración y conservación de frutas, legumbres y hortalizas',
  },
  {
    id: 57,
    code: '151304',
    value: 'Producción de concentrados para jugos (citricultura)',
  },
  {
    id: 58,
    code: '151401',
    value: 'Elaboración de aceites y grasas de origen vegetal y animal',
  },
  {
    id: 59,
    code: '152001',
    value: 'Producción de helados y otros productos similares',
  },
  {
    id: 60,
    code: '152002',
    value: 'Elaboración de productos lácteos gravados con ventas',
  },
  {
    id: 61,
    code: '152003',
    value: 'Elaboración de productos lácteos exentos de imp. ventas',
  },
  {
    id: 62,
    code: '153101',
    value: 'Servicio de molienda',
  },
  {
    id: 63,
    code: '153102',
    value:
      'Elaboración de harinas premezcladas y preparadas para la fabricación de productos de panadería y repostería',
  },
  {
    id: 64,
    code: '153103',
    value: 'Procesamiento del arroz',
  },
  {
    id: 65,
    code: '153104',
    value: 'Fabricación de harinas finas y gruesas',
  },
  {
    id: 66,
    code: '153201',
    value: 'Elaboración de productos derivados de almidón',
  },
  {
    id: 67,
    code: '153301',
    value:
      'Elaboración de alimentos para animales destinados al consumo humano',
  },
  {
    id: 68,
    code: '153302',
    value: 'Elaboración de pacas de heno',
  },
  {
    id: 69,
    code: '154101',
    value: 'Venta de pan y otros productos similares gravados con ventas',
  },
  {
    id: 70,
    code: '154102',
    value: 'Pastelería, repostería o ambas',
  },
  {
    id: 71,
    code: '154104',
    value: 'Venta de pan y otros productos similares exentos de ventas',
  },
  {
    id: 72,
    code: '154201',
    value: 'Elaboración de azúcar y sus derivados',
  },
  {
    id: 73,
    code: '154202',
    value: 'Elaboración de productos derivados de la caña de azúcar',
  },
  {
    id: 74,
    code: '154301',
    value: 'Elaboración de cacao',
  },
  {
    id: 75,
    code: '154302',
    value: 'Elaboración de chocolate',
  },
  {
    id: 76,
    code: '154303',
    value: 'Elaboración de dulces, golosinas y conservas en azúcar',
  },
  {
    id: 77,
    code: '154401',
    value:
      'Elaboración de macarrones, fideos, alcuzcuz y productos farináceos similares',
  },
  {
    id: 78,
    code: '154902',
    value:
      'Fabricación de productos alimenticios preparados n.c.p. (no contemplados en otra parte)',
  },
  {
    id: 79,
    code: '154903',
    value: 'Fabricación de hielo',
  },
  {
    id: 80,
    code: '154904',
    value:
      'Fabricación de café (excepto el envasado, enlatado, soluble y el descafeinado)',
  },
  {
    id: 81,
    code: '154905',
    value: 'Elaboración de productos de maíz exentos de ventas',
  },
  {
    id: 82,
    code: '154908',
    value: 'Elaboración de productos de maíz gravados con ventas',
  },
  {
    id: 83,
    code: '155101',
    value:
      'Elaborac. rectificac mezcla bebidas alcohólicas/prod. de alcohol etílico (sustan fermentadas)',
  },
  {
    id: 84,
    code: '155301',
    value: 'Elaboración de bebidas malteadas y de malta no artesanales',
  },
  {
    id: 85,
    code: '155302',
    value: 'Elaboración artesanal de bebidas malteadas y de malta.',
  },
  {
    id: 86,
    code: '155403',
    value:
      'Elaboración de bebidas no alcohólicas / gaseosas / agua mineral y de manantial',
  },
  {
    id: 87,
    code: '155404',
    value: 'Elaboración de concentrado para bebidas naturales y gaseosas',
  },
  {
    id: 88,
    code: '160001',
    value: 'Elaboración de productos de tabaco',
  },
  {
    id: 89,
    code: '171101',
    value: 'Fabricación de todo tipo de telas y/o hilos',
  },
  {
    id: 90,
    code: '171201',
    value: 'Maquila de productos textiles',
  },
  {
    id: 91,
    code: '171202',
    value: 'Hidrofugado/impermeabilizado',
  },
  {
    id: 92,
    code: '172101',
    value:
      'Fabricación de artículos confeccionados de materiales textiles, excepto prendas de vestir',
  },
  {
    id: 93,
    code: '172103',
    value: 'Diseño artístico para costura (moldes)',
  },
  {
    id: 94,
    code: '172201',
    value: 'Fabricación de tapices y alfombras',
  },
  {
    id: 95,
    code: '172301',
    value: 'Fabricación de cuerdas, cordeles, bramantes y redes',
  },
  {
    id: 96,
    code: '172302',
    value: 'Fabricación de hilos y cuerdas para uso agrícola y pesca',
  },
  {
    id: 97,
    code: '172902',
    value: 'Servicio de bordado a mano o a maquina',
  },
  {
    id: 98,
    code: '173001',
    value: 'Fabricación de tejidos y artículos de punto y ganchillo',
  },
  {
    id: 99,
    code: '181001',
    value: 'Servicios de costura y sastrería (costureras y sastres)',
  },
  {
    id: 100,
    code: '181002',
    value: 'Fabricación de prendas de vestir (ropa de todo tipo)',
  },
  {
    id: 101,
    code: '182001',
    value:
      'Adobo y teñido de pieles; fabricación de artículos de piel natural o artificial',
  },
  {
    id: 102,
    code: '191101',
    value: 'Fabricación de maletas, bolsos de mano y artículos similares',
  },
  {
    id: 103,
    code: '192001',
    value: 'Fabricación de todo tipo de zapatos excepto el ortopédico',
  },
  {
    id: 104,
    code: '201002',
    value: 'Aserrado y acepilladura de madera',
  },
  {
    id: 105,
    code: '202101',
    value:
      'Fabricación de hojas de madera para enchapado y tableros como plywood, durpanel y similares',
  },
  {
    id: 106,
    code: '202201',
    value:
      'Fabricación de partes y piezas de carpintería para edificios y construcciones',
  },
  {
    id: 107,
    code: '202301',
    value: 'Fabricación de tarimas',
  },
  {
    id: 108,
    code: '202902',
    value: 'Fabricación y/o venta de ataúdes (cajas mortuorias, féretros).',
  },
  {
    id: 109,
    code: '210101',
    value: 'Fabricación de papel y cartón y envases de papel y cartón',
  },
  {
    id: 110,
    code: '210201',
    value: 'Fabricación de otros artículos de papel y cartón',
  },
  {
    id: 111,
    code: '221101',
    value: 'Edición de libros de textos',
  },
  {
    id: 112,
    code: '221201',
    value: 'Edición de periódicos, revistas y otras publicaciones periódicas',
  },
  {
    id: 113,
    code: '221901',
    value: 'Artes graficas',
  },
  {
    id: 114,
    code: '222102',
    value: 'Tipografía y/o litografía (imprenta)',
  },
  {
    id: 115,
    code: '222104',
    value: 'Impresión digital',
  },
  {
    id: 116,
    code: '222105',
    value: 'Serigrafia',
  },
  {
    id: 117,
    code: '223001',
    value: 'Reproducción de grabaciones',
  },
  {
    id: 118,
    code: '232001',
    value: 'Refinerías de petróleo',
  },
  {
    id: 119,
    code: '232002',
    value: 'Fabricación de productos diversos derivados del petróleo',
  },
  {
    id: 120,
    code: '232005',
    value: 'Elaboración de biocombustibles',
  },
  {
    id: 121,
    code: '241101',
    value:
      'Fabricación de sustancias químicas y gases industriales excepto abonos',
  },
  {
    id: 122,
    code: '241201',
    value: 'Fabricación de abonos y compuestos de nitrógeno',
  },
  {
    id: 123,
    code: '241301',
    value: 'Fabricación de plásticos y caucho sintético en formas primarias',
  },
  {
    id: 124,
    code: '241302',
    value: 'Fabricación de resinas',
  },
  {
    id: 125,
    code: '242101',
    value:
      'Fabricación de plaguicidas y otros productos químicos de uso agropecuario',
  },
  {
    id: 126,
    code: '242201',
    value:
      'Fabricación de pinturas, barnices y productos de revestimi-to similares',
  },
  {
    id: 127,
    code: '242202',
    value: 'Fabricación de tintas de imprentas',
  },
  {
    id: 128,
    code: '242301',
    value:
      'Fabricación productos farmacéuticos, sustancias químicas y productos botánicos',
  },
  {
    id: 129,
    code: '242401',
    value:
      'Fabricación jabones, detergentes, preparados de tocador, para limpiar pulir y perfumes',
  },
  {
    id: 130,
    code: '242404',
    value: 'Fabricación de jabones de tocador',
  },
  {
    id: 131,
    code: '242405',
    value:
      'Fabricación de jabones para lavar (excepto preparaciones tenso activas usadas como jabón)',
  },
  {
    id: 132,
    code: '242406',
    value:
      'Fabricación de ceras, abrillantadores, lustradores y preparaciones similares',
  },
  {
    id: 133,
    code: '242901',
    value: 'Fabricación de otros productos químicos.',
  },
  {
    id: 134,
    code: '242902',
    value: 'Fabricación de pegamentos y adhesivos',
  },
  {
    id: 135,
    code: '242903',
    value: 'Reparación de armas de fuego',
  },
  {
    id: 136,
    code: '243001',
    value: 'Fabricación de fibras sintéticas',
  },
  {
    id: 137,
    code: '251101',
    value: 'Fabricación de llantas y cubiertas para equipo y maquinaria móvil',
  },
  {
    id: 138,
    code: '251102',
    value: 'Reencauchadora de llantas',
  },
  {
    id: 139,
    code: '251901',
    value: 'Fabricación de otros productos de caucho',
  },
  {
    id: 140,
    code: '252001',
    value: 'Fabricación de artículos de plástico',
  },
  {
    id: 141,
    code: '261001',
    value: 'Fabricación de vidrio y productos de vidrio',
  },
  {
    id: 142,
    code: '269101',
    value:
      'Fabricación productos de cerámica barro loza y/o porcelana no refractaria uso no estructural',
  },
  {
    id: 143,
    code: '269201',
    value: 'Fabricación de productos de cerámica refractaria',
  },
  {
    id: 144,
    code: '269301',
    value:
      'Fabricación de productos de arcilla y cerámica no refractarias para uso estructural',
  },
  {
    id: 145,
    code: '269401',
    value: 'Fabricación y/o venta de cemento, cal y yeso',
  },
  {
    id: 146,
    code: '269501',
    value:
      'Fabricación de artículos de cemento, yeso y hormigón para la construcción',
  },
  {
    id: 147,
    code: '269601',
    value: 'Corte, tallado y acabado de la piedra',
  },
  {
    id: 148,
    code: '269901',
    value: 'Fabricación de otros productos minerales no metálicos n.c.p.',
  },
  {
    id: 149,
    code: '271001',
    value: 'Industrias básicas de hierro y acero',
  },
  {
    id: 150,
    code: '272001',
    value:
      'Fabricación de productos primarios de metales preciosos y metales no ferrosos',
  },
  {
    id: 151,
    code: '273101',
    value: 'Fundición de hierro y acero',
  },
  {
    id: 152,
    code: '273201',
    value: 'Fundición de metales no ferrosos',
  },
  {
    id: 153,
    code: '281101',
    value: 'Fabricación de productos metálicos para uso estructural',
  },
  {
    id: 154,
    code: '281201',
    value: 'Fabricación de tanques, depósitos y recipientes de metal',
  },
  {
    id: 155,
    code: '281301',
    value:
      'Fabricación generadores de vapor, excepto calderas de agua caliente para calefacción central',
  },
  {
    id: 156,
    code: '289201',
    value: 'Servicios de soldadura',
  },
  {
    id: 157,
    code: '289202',
    value: 'Servicio de tratamiento y revestimiento de todo tipo de materiales',
  },
  {
    id: 158,
    code: '289302',
    value: 'Hojalatería',
  },
  {
    id: 159,
    code: '289304',
    value: 'Taller de mecánica de precisión',
  },
  {
    id: 160,
    code: '289305',
    value:
      'Fabricación de piezas, artículos y accesorios de metal incluye las cerrajerías.',
  },
  {
    id: 161,
    code: '289306',
    value: 'Servicios de torno (mecánica de precisión)',
  },
  {
    id: 162,
    code: '289902',
    value: 'Fabricación de otros productos elaborados de metal n.c.p.',
  },
  {
    id: 163,
    code: '290001',
    value: 'Servicio de reparación de maquinaria y equipo.',
  },
  {
    id: 164,
    code: '290003',
    value: 'Servicio de mantenimiento de maquinaria y equipo.',
  },
  {
    id: 165,
    code: '291101',
    value:
      'Fabricación de motores y turbinas, excepto motores para aeronaves, vehículos automotores y motocicletas',
  },
  {
    id: 166,
    code: '291201',
    value: 'Fabricación de bombas, compresores, grifos y válvulas',
  },
  {
    id: 167,
    code: '291202',
    value: 'Venta de bombas de agua',
  },
  {
    id: 168,
    code: '291401',
    value: 'Fabricación de hornos y quemadores',
  },
  {
    id: 169,
    code: '291901',
    value: 'Reparación de equipo de refrigeración y congelación',
  },
  {
    id: 170,
    code: '291903',
    value:
      'Instalación y/o mantenimiento de equipo de refrigeración y congelación',
  },
  {
    id: 171,
    code: '292101',
    value: 'Fabricación de maquinaria agrícola',
  },
  {
    id: 172,
    code: '292201',
    value: 'Fabricación de maquinas herramienta',
  },
  {
    id: 173,
    code: '292301',
    value: 'Fabricación de maquinaria metalúrgica',
  },
  {
    id: 174,
    code: '292401',
    value:
      'Fabricación de maquinaria para la explotación de minas y canteras y para obras de construcción',
  },
  {
    id: 175,
    code: '292402',
    value: 'Perforación de pozos',
  },
  {
    id: 176,
    code: '292501',
    value:
      'Fabricación de maquinaria para la elaboración de alimentos, bebidas y tabaco',
  },
  {
    id: 177,
    code: '292601',
    value:
      'Fabricación maquinaria para la elaboración de productos textiles, prendas de vestir y cueros',
  },
  {
    id: 178,
    code: '292701',
    value: 'Fabricación de armas y municiones',
  },
  {
    id: 179,
    code: '292901',
    value: 'Fabricación de otros tipos de maquinaria de uso especial',
  },
  {
    id: 180,
    code: '293001',
    value: 'Fabricación de aparatos de uso domestico n.c.p.',
  },
  {
    id: 181,
    code: '300001',
    value: 'Fabricación de maquinaria de oficina, contabilidad e informática',
  },
  {
    id: 182,
    code: '311001',
    value:
      'Fabricación de motores, generadores y transformadores eléctricos, partes y acceso',
  },
  {
    id: 183,
    code: '313001',
    value: 'Fabricación de hilos y cables aislados',
  },
  {
    id: 184,
    code: '314001',
    value: 'Fabricación de pilas, baterías y acumuladores',
  },
  {
    id: 185,
    code: '315001',
    value: 'Fabricación de lámparas eléctricas y equipo de iluminación',
  },
  {
    id: 186,
    code: '319001',
    value: 'Fabricación de otros tipos de equipo eléctrico n.c.p.',
  },
  {
    id: 187,
    code: '321001',
    value: 'Manufactura o fabricación de otros componentes electrónicos',
  },
  {
    id: 188,
    code: '321002',
    value: 'Diseño de componentes electrónicos',
  },
  {
    id: 189,
    code: '322001',
    value: 'Fabricación de transmisores y receptores de radio y televisión',
  },
  {
    id: 190,
    code: '331101',
    value: 'Fabricación y comercialización de prótesis',
  },
  {
    id: 191,
    code: '331102',
    value: 'Fabricación y comercialización de equipo medico',
  },
  {
    id: 192,
    code: '331103',
    value: 'Fabricación y comercialización de zapatos ortopédicos',
  },
  {
    id: 193,
    code: '331201',
    value:
      'Fabricación de equipos para medir, verificar, y navegar y de equipos de control',
  },
  {
    id: 194,
    code: '332001',
    value: 'Fabricación de instrumentos ópticos y equipo fotográfico',
  },
  {
    id: 195,
    code: '333001',
    value: 'Fabricación de relojes de todo tipo',
  },
  {
    id: 196,
    code: '341001',
    value: 'Fabricación de vehículos automotores',
  },
  {
    id: 197,
    code: '342001',
    value:
      'Fabricación de carrocerías para vehículos automotores, remolques y semirremolques',
  },
  {
    id: 198,
    code: '342002',
    value:
      'Fabricación de carrocerías para camiones de la partida arancelaria 870790002',
  },
  {
    id: 199,
    code: '343001',
    value:
      'Fabricación de partes, piezas y accesorios para vehículos automotores',
  },
  {
    id: 200,
    code: '351101',
    value:
      'Servicio de reparación de embarcaciones y sus partes y/o estructuras flotantes',
  },
  {
    id: 201,
    code: '351102',
    value: 'Construcción de embarcaciones y estructuras flotantes',
  },
  {
    id: 202,
    code: '351103',
    value: 'Venta de embarcaciones de motor y vela',
  },
  {
    id: 203,
    code: '352001',
    value:
      'Fabricación de locomotoras y material rodante para ferrocarriles y tranvías',
  },
  {
    id: 204,
    code: '353001',
    value: 'Fabricación de aeronaves y naves espaciales y maquinaria conexa',
  },
  {
    id: 205,
    code: '359101',
    value: 'Fabricación de motocicletas, partes, piezas y sus accesorios',
  },
  {
    id: 206,
    code: '359201',
    value:
      'Fabricación de bicicletas y sillas de ruedas, partes, piezas y sus accesorios',
  },
  {
    id: 207,
    code: '359901',
    value: 'Fabricación de otros tipos de equipo de transporte n.c.p.',
  },
  {
    id: 208,
    code: '361001',
    value:
      'Fabricación y/o reparación de muebles y accesorios (incluye colchones)',
  },
  {
    id: 209,
    code: '361002',
    value: 'Reparación de tapicería',
  },
  {
    id: 210,
    code: '361004',
    value: 'Maquila de muebles',
  },
  {
    id: 211,
    code: '369101',
    value: 'Fabricación de joyas, bisutería y artículos conexos',
  },
  {
    id: 212,
    code: '369201',
    value:
      'Fabricación de instrumentos musicales, partes y piezas y sus accesorios',
  },
  {
    id: 213,
    code: '369301',
    value: 'Fabricación de artículos de deporte',
  },
  {
    id: 214,
    code: '369401',
    value: 'Fabricación de juegos y juguetes',
  },
  {
    id: 215,
    code: '369901',
    value: 'Fabricación de escobas',
  },
  {
    id: 216,
    code: '369902',
    value:
      'Fabricación de velas (candelas) excepto las perfumadas, coloreadas y decoradas.',
  },
  {
    id: 217,
    code: '369903',
    value: 'Fabricación de velas coloreadas, perfumadas y decoradas',
  },
  {
    id: 218,
    code: '369904',
    value: 'Fabricación de sellos de mano, metal o hule (caucho)',
  },
  {
    id: 219,
    code: '371001',
    value: 'Reciclaje de desperdicios y desechos metálicos',
  },
  {
    id: 220,
    code: '372001',
    value: 'Reciclaje de otro tipo de materiales n.c.p.',
  },
  {
    id: 221,
    code: '372003',
    value: 'Reciclaje de papel y plástico y materiales relacionados',
  },
  {
    id: 222,
    code: '401002',
    value:
      'Generación y/o distribución de energía eléctrica (hidraulica,convencional, térmico, etc.)',
  },
  {
    id: 223,
    code: '401004',
    value:
      'Fabricación, ensamble y venta de sistemas para el aprovechamiento de energías renovables',
  },
  {
    id: 224,
    code: '402001',
    value:
      'Fabricación de gas; distribución de combustibles gaseosos por tuberías',
  },
  {
    id: 225,
    code: '402002',
    value: 'Instalación y venta de tanques para gas',
  },
  {
    id: 226,
    code: '403001',
    value: 'Suministro de vapor y aire acondicionado',
  },
  {
    id: 227,
    code: '410001',
    value: 'Captación, tratamiento y distribución de agua',
  },
  {
    id: 228,
    code: '451001',
    value: 'Demolición de edificios y otras estructuras',
  },
  {
    id: 229,
    code: '451002',
    value: 'Preparación de terrenos',
  },
  {
    id: 230,
    code: '452002',
    value:
      'Construcción de edificios, apartamentos, condominios y casas de habitación',
  },
  {
    id: 231,
    code: '452003',
    value:
      'Mantenimiento, reparación y ampliaciones de edificios, apartamentos, condominios y casas',
  },
  {
    id: 232,
    code: '452005',
    value: 'Construcción y mantenimiento de carreteras y otras vías',
  },
  {
    id: 233,
    code: '452006',
    value: 'Actividades de construcción especiales',
  },
  {
    id: 234,
    code: '453001',
    value: 'Reparación de aire acondicionado',
  },
  {
    id: 235,
    code: '453002',
    value: 'Reparación de ascensores(elevadores)',
  },
  {
    id: 236,
    code: '453003',
    value: 'Venta e instalación de alarmas y otros sistemas eléctricos',
  },
  {
    id: 237,
    code: '453004',
    value: 'Reparación de cableado de comunicaciones',
  },
  {
    id: 238,
    code: '453005',
    value: 'Instalación de alarmas y otros sistemas de seguridad',
  },
  {
    id: 239,
    code: '453006',
    value: 'Instalación y mantenimiento de aire acondicionado',
  },
  {
    id: 240,
    code: '453007',
    value: 'Instalación y mantenimiento de ascensores (elevadores)',
  },
  {
    id: 241,
    code: '453008',
    value:
      'Instalación y mantenimiento de cableado de comunicaciones y/o energía eléctrica',
  },
  {
    id: 242,
    code: '453009',
    value: 'Reparación de portones eléctricos',
  },
  {
    id: 243,
    code: '453010',
    value: 'Instalación y mantenimiento de portones eléctricos',
  },
  {
    id: 244,
    code: '453011',
    value:
      'Instalación y mantenimiento de conmutadores y otros sistemas para telecomunicaciones',
  },
  {
    id: 245,
    code: '454001',
    value: 'Servicios de terminación y acabado de edificios',
  },
  {
    id: 246,
    code: '455001',
    value: 'Alquiler de equipo de construcción o demolición con operadores',
  },
  {
    id: 247,
    code: '501001',
    value: 'Venta al por mayor y menor de vehículos nuevos y/o usados',
  },
  {
    id: 248,
    code: '502001',
    value: 'Lavado, encerado y pulido de automóviles (lava car)',
  },
  {
    id: 249,
    code: '502002',
    value: 'Servicios de alineamiento y reparación de llantas',
  },
  {
    id: 250,
    code: '502003',
    value: 'Servicio de reparación de toda clase de vehículos y sus partes',
  },
  {
    id: 251,
    code: '502004',
    value: 'Servicio de enderezado y pintura para toda clase de vehículo',
  },
  {
    id: 252,
    code: '502007',
    value: 'Auto decoración',
  },
  {
    id: 253,
    code: '502008',
    value: 'Servicio de revisión técnica vehicular (diagnósticos por escáner)',
  },
  {
    id: 254,
    code: '503002',
    value: 'Venta de repuestos usados para automóviles',
  },
  {
    id: 255,
    code: '503004',
    value: 'Venta de repuestos, partes y piezas para automóviles nuevos',
  },
  {
    id: 256,
    code: '503005',
    value:
      'Comercialización de llantas (neumáticas) para vehículos automotores',
  },
  {
    id: 257,
    code: '503006',
    value: 'Actividades de desarme de vehículos y venta de repuestos',
  },
  {
    id: 258,
    code: '504001',
    value: 'Venta de motocicletas',
  },
  {
    id: 259,
    code: '504003',
    value: 'Venta de partes o accesorios de motocicletas',
  },
  {
    id: 260,
    code: '504004',
    value: 'Servicio de reparación de toda clase de motocicletas y sus partes',
  },
  {
    id: 261,
    code: '505001',
    value: 'Venta de combustibles (conocidas como gasolineras o bombas)',
  },
  {
    id: 262,
    code: '505002',
    value:
      'Venta de lubricantes, aceites, grasas y productos de limpieza para automotores',
  },
  {
    id: 263,
    code: '505003',
    value: 'Comercio de combustible sin punto fijo (perder)',
  },
  {
    id: 264,
    code: '511001',
    value:
      'Comisionistas, agentes de ventas, organizadores de subastas, tiqueteras, etc.',
  },
  {
    id: 265,
    code: '512102',
    value: 'Venta al por mayor de flores y plantas de todo tipo',
  },
  {
    id: 266,
    code: '512201',
    value:
      'Comercio al por mayor de alimentos, granos básicos, carnes y demás comestibles y artículos de la canasta básica',
  },
  {
    id: 267,
    code: '512202',
    value:
      'Venta al por mayor de frutas, verduras frescas y legumbres y hortalizas exenta de ventas',
  },
  {
    id: 268,
    code: '512204',
    value: 'Comercio al por mayor de productos lácteos',
  },
  {
    id: 269,
    code: '512208',
    value: 'Comercio al por mayor de productos de confitería',
  },
  {
    id: 270,
    code: '512209',
    value: 'Comercio al por mayor de productos de tabaco',
  },
  {
    id: 271,
    code: '512210',
    value:
      'Comercio al por mayor de bebidas con contenido alcohólico (importadores)',
  },
  {
    id: 272,
    code: '512211',
    value:
      'Comercio al por mayor de otros alimentos n.c.p. grabados con ventas',
  },
  {
    id: 273,
    code: '512213',
    value: 'Comercio al por mayor de bebidas gaseosas y carbonatadas',
  },
  {
    id: 274,
    code: '512214',
    value:
      'Venta al por mayor de café (excepto el envasado, enlatado, soluble, descafeinado)',
  },
  {
    id: 275,
    code: '512217',
    value:
      'Comercio al por mayor de vinos, bebidas fermentadas y no fermentadas',
  },
  {
    id: 276,
    code: '512225',
    value:
      'Comercio al por mayor de bebidas no alcohólicas (jugos de frutas, vegetales) y agua embotellada',
  },
  {
    id: 277,
    code: '512232',
    value: 'Comercio al por mayor de productos sustitutos del azúcar',
  },
  {
    id: 278,
    code: '512233',
    value:
      'Comercio al por mayor de carnes de todo tipo (preparadas sazonadas condimentadas empanizadas)',
  },
  {
    id: 279,
    code: '512234',
    value: 'Comercio al por mayor de cerveza importada',
  },
  {
    id: 280,
    code: '512235',
    value: 'Venta al por mayor de legumbres y hortalizas grabados en ventas',
  },
  {
    id: 281,
    code: '512237',
    value:
      'Comercio al por mayor de alimentos y productos n.c.p. exentos de ventas',
  },
  {
    id: 282,
    code: '513101',
    value:
      'Venta al por mayor de calzado, productos textiles, prendas de vestir',
  },
  {
    id: 283,
    code: '513501',
    value:
      'Venta al por mayor de preparados y/o artículos para la limpieza de uso general',
  },
  {
    id: 284,
    code: '513601',
    value:
      'Venta al por mayor equipo medico accesorios medicamentos produce farmacéutico exentos de ventas',
  },
  {
    id: 285,
    code: '513602',
    value: 'Comercio al por mayor de productos de veterinaria',
  },
  {
    id: 286,
    code: '513604',
    value:
      'Venta al por mayor equipo medico accesorio medicamento produce farmacéutico gravados con ventas',
  },
  {
    id: 287,
    code: '513710',
    value: 'Venta al por mayor de libros y textos educativos',
  },
  {
    id: 288,
    code: '513901',
    value: 'Venta al por menor de artículos deportivos',
  },
  {
    id: 289,
    code: '513904',
    value: 'Venta al por mayor de suministros y artículos de librería',
  },
  {
    id: 290,
    code: '513906',
    value:
      'Venta al por mayor de artículos, artefactos, discos y muebles para el hogar',
  },
  {
    id: 291,
    code: '513907',
    value:
      'Venta al por mayor de discos compactos y otros dispositivos de grabación',
  },
  {
    id: 292,
    code: '513910',
    value: 'Venta al por mayor de todo tipo de artículos por catálogo',
  },
  {
    id: 293,
    code: '514101',
    value: 'Venta al por mayor de combustibles sólidos (leña y similares)',
  },
  {
    id: 294,
    code: '514103',
    value: 'Venta al por mayor de combustibles sólidos (carbón)',
  },
  {
    id: 295,
    code: '514201',
    value: 'Venta al por mayor de metales y minerales metalíferos',
  },
  {
    id: 296,
    code: '514304',
    value:
      'Venta al por mayor de equipo de aire acondicionado y calentadores (eléctricos, solares, etc.)',
  },
  {
    id: 297,
    code: '514308',
    value:
      'Venta al por mayor de materiales para la construcción, artículos de ferretería, equipo y materiales de fontanería y calefacción',
  },
  {
    id: 298,
    code: '514701',
    value:
      'Venta al por mayor de productos, sustancias o reactivos químicos y solventes en general',
  },
  {
    id: 299,
    code: '514901',
    value: 'Venta al por mayor y al por menor de chatarra',
  },
  {
    id: 300,
    code: '514903',
    value:
      'Venta al por mayor de productos para uso agropecuario y venta desechos orgánicos e inorgánicos',
  },
  {
    id: 301,
    code: '515001',
    value:
      'Venta al por mayor de maquinaria y equipo industrial, de construcción, ingeniería civil y otros, así como sus accesorios',
  },
  {
    id: 302,
    code: '515002',
    value:
      'Venta al por mayor de repuestos y/o accesorios para maquinaria y equipo agropecuario',
  },
  {
    id: 303,
    code: '515003',
    value: 'Venta al por mayor de maquinaria y equipo agropecuario',
  },
  {
    id: 304,
    code: '515004',
    value: 'Venta al por mayor de extintores y equipo similar',
  },
  {
    id: 305,
    code: '515201',
    value: 'Venta al por mayor de equipo de cómputo, sus partes y accesorios',
  },
  {
    id: 306,
    code: '515203',
    value: 'Venta al por mayor de equipo y suministros de oficina',
  },
  {
    id: 307,
    code: '519003',
    value:
      'Venta al por mayor de equipo, artículos y accesorios de belleza, cosméticos e higiene personal',
  },
  {
    id: 308,
    code: '519005',
    value: 'Venta al por mayor de equipo para campo de juegos (playa)',
  },
  {
    id: 309,
    code: '519006',
    value:
      'Distribución y comercialización al por mayor de material desempaque',
  },
  {
    id: 310,
    code: '519010',
    value:
      'Comercialización y distribución al por mayor de alimentos preparados para animales.',
  },
  {
    id: 311,
    code: '519011',
    value:
      'Comercio al por mayor de equipo y accesorios para pesca deportiva o artesanal',
  },
  {
    id: 312,
    code: '519013',
    value: 'Comercialización al por mayor de suplementos alimenticios',
  },
  {
    id: 313,
    code: '519014',
    value: 'Venta al por mayor de polen y/o semillas para uso agrícola',
  },
  {
    id: 314,
    code: '519015',
    value: 'Venta al por mayor de otros productos no especializados',
  },
  {
    id: 315,
    code: '519016',
    value: 'Venta al por mayor de artículos y accesorios electrónicos',
  },
  {
    id: 316,
    code: '521101',
    value: 'Supermercados y almacenes de abarrotes en cadena',
  },
  {
    id: 317,
    code: '521102',
    value: 'Venta al por menor de especias, salsas y condimentos',
  },
  {
    id: 318,
    code: '521201',
    value: 'Abastecedores, pulperías o minisúper',
  },
  {
    id: 319,
    code: '521202',
    value: 'Pulperías ( minisúper)(sin cantina)',
  },
  {
    id: 320,
    code: '521901',
    value: 'Tiendas o almacenes por departamentos',
  },
  {
    id: 321,
    code: '522001',
    value:
      'Comercio al por menor de confites y otros productos relacionados (confitería)',
  },
  {
    id: 322,
    code: '522002',
    value: 'Venta de carnes (res, pollo, cerdo) incluidas en la canasta básica',
  },
  {
    id: 323,
    code: '522003',
    value: 'Macrobióticas',
  },
  {
    id: 324,
    code: '522004',
    value: 'Venta de verduras y frutas exentos de ventas',
  },
  {
    id: 325,
    code: '522005',
    value:
      'Venta de mariscos y/o pescado (pescaderías o marisquerías)incluidos en la canasta básica',
  },
  {
    id: 326,
    code: '522007',
    value: 'Licorerías y/o depósito de licores (venta al por menor)',
  },
  {
    id: 327,
    code: '522008',
    value:
      'Venta al por menor de huevos de gallina incluidos en la canasta básica',
  },
  {
    id: 328,
    code: '522009',
    value: 'Venta de frutas gravadas con ventas',
  },
  {
    id: 329,
    code: '522010',
    value:
      'Venta de embutidos y carnes (res, pollo, cerdo)incluidas en la canasta básica',
  },
  {
    id: 330,
    code: '522011',
    value:
      'Preparación, servicio y venta de frutas picadas y bebidas de frutas y/o legumbres.',
  },
  {
    id: 331,
    code: '522012',
    value: 'Comercio al por menor de bebidas gaseosas y carbonatadas',
  },
  {
    id: 332,
    code: '522013',
    value: 'Comercio al por menor de agua embotellada',
  },
  {
    id: 333,
    code: '522014',
    value: 'Venta de frutas incluidas en la nueva canasta básica',
  },
  {
    id: 334,
    code: '522015',
    value:
      'Venta al por menor de café (excepto el envasado, enlatado, soluble, descafeinado)',
  },
  {
    id: 335,
    code: '522016',
    value: 'Comercio al por menor de productos de tabaco',
  },
  {
    id: 336,
    code: '522017',
    value:
      'Venta de embutidos y carnes (res, pollo, cerdo, caballo,etc) gravadas en ventas',
  },
  {
    id: 337,
    code: '522018',
    value:
      'Venta de pescados y/o mariscos (pescaderías o marisquerías) gravados en ventas',
  },
  {
    id: 338,
    code: '523101',
    value: 'Farmacias',
  },
  {
    id: 339,
    code: '523102',
    value: 'Venta al por menor de cosméticos y perfumería',
  },
  {
    id: 340,
    code: '523201',
    value: 'Bazares',
  },
  {
    id: 341,
    code: '523202',
    value: 'Venta al por menor de ropa (boutique)',
  },
  {
    id: 342,
    code: '523203',
    value: 'Venta al por menor de calzado (zapatearías)',
  },
  {
    id: 343,
    code: '523204',
    value: 'Pasamanerías',
  },
  {
    id: 344,
    code: '523205',
    value: 'Venta al por menor de prendas de vestir, ropa y zapatos (tiendas)',
  },
  {
    id: 345,
    code: '523206',
    value: 'Servicio de fotocopiadora',
  },
  {
    id: 346,
    code: '523207',
    value: 'Venta al por menor de artículos de cuero (excepto calzado)',
  },
  {
    id: 347,
    code: '523208',
    value: 'Venta al por menor de productos textiles (telas)',
  },
  {
    id: 348,
    code: '523209',
    value: 'Venta al por menor de materiales para calzado',
  },
  {
    id: 349,
    code: '523301',
    value: 'Comercio al por menor de objetos de cerámica y porcelana',
  },
  {
    id: 350,
    code: '523302',
    value: 'Venta al por menor de cristalería',
  },
  {
    id: 351,
    code: '523303',
    value: 'Venta al por menor de discos, cd y otros similares',
  },
  {
    id: 352,
    code: '523304',
    value:
      'Venta al por menor de electrodomésticos, muebles y artículos para el hogar',
  },
  {
    id: 353,
    code: '523306',
    value: 'Venta al por menor de antigüedades',
  },
  {
    id: 354,
    code: '523308',
    value: 'Venta al por menor de instrumentos musicales, partes y accesorios',
  },
  {
    id: 355,
    code: '523309',
    value:
      'Venta de cuadros pinturas hechas por pintores nacionales y extranjeros producidos en el país',
  },
  {
    id: 356,
    code: '523401',
    value: 'Venta al por menor de depósito de madera',
  },
  {
    id: 357,
    code: '523402',
    value:
      'Venta al por menor artículos de ferretería pinturas madera y materiales para la construcción',
  },
  {
    id: 358,
    code: '523403',
    value: 'Venta de pinturas',
  },
  {
    id: 359,
    code: '523404',
    value: 'Venta al por menor de vidrio para la construcción',
  },
  {
    id: 360,
    code: '523405',
    value: 'Venta al por menor de materiales para la construcción',
  },
  {
    id: 361,
    code: '523406',
    value:
      'Venta al por mayor y menor de artículos electrónicos, eléctricos y similares',
  },
  {
    id: 362,
    code: '523407',
    value: 'Venta al por menor de plywood',
  },
  {
    id: 363,
    code: '523501',
    value:
      'Venta al por menor realizada dentro del deposito libre comercial de golfito.',
  },
  {
    id: 364,
    code: '523601',
    value:
      'Comercio al por menor de computadoras, accesorios, microcomponentes y paquetes de cómputo',
  },
  {
    id: 365,
    code: '523701',
    value: 'Librerías',
  },
  {
    id: 366,
    code: '523702',
    value:
      'Venta al por menor de revistas/periódicos en puestos de ventas o mercados',
  },
  {
    id: 367,
    code: '523703',
    value: 'Venta al por menor de libros y textos educativos exentos de ventas',
  },
  {
    id: 368,
    code: '523801',
    value: 'Venta al por menor de armas (armerías)',
  },
  {
    id: 369,
    code: '523803',
    value:
      'Venta al por menor de maquinaria y equipo de todo tipo y artículos conexos',
  },
  {
    id: 370,
    code: '523805',
    value:
      'Venta al por menor de purificadores de agua, sus partes y repuestos',
  },
  {
    id: 371,
    code: '523806',
    value: 'Venta al por menor de maquinaria industrial usada',
  },
  {
    id: 372,
    code: '523807',
    value:
      'Venta al por menor de repuestos nuevos para maquinaria, equipo y otros',
  },
  {
    id: 373,
    code: '523808',
    value:
      'Venta al por menor de repuestos usados para maquinaria, equipo y otros',
  },
  {
    id: 374,
    code: '523901',
    value: 'Venta al por menor de otros productos en almacenes especializados',
  },
  {
    id: 375,
    code: '523902',
    value: 'Venta al por menor de suministros y/0 equipo de oficina',
  },
  {
    id: 376,
    code: '523903',
    value: 'Venta al por menor de flores',
  },
  {
    id: 377,
    code: '523904',
    value: 'Servicios de reparación de joyería y relojería en general',
  },
  {
    id: 378,
    code: '523905',
    value: 'Venta al por menor de joyería, relojería y bisutería',
  },
  {
    id: 379,
    code: '523906',
    value:
      'Venta al por menor celulares accesorios equipo y artículos para comunicaciones incluye la reparación',
  },
  {
    id: 380,
    code: '523907',
    value: 'Floristería',
  },
  {
    id: 381,
    code: '523908',
    value: 'Comercio al por menor de animales domésticos para consumo humano',
  },
  {
    id: 382,
    code: '523909',
    value: 'Venta de productos de artesanía y souvenir',
  },
  {
    id: 383,
    code: '523910',
    value: 'Venta al por menor de juguetes y/o artículos de esparcimiento',
  },
  {
    id: 384,
    code: '523911',
    value:
      'Venta al por menor de pañales desechables, artículos de limpieza y otros (pañalera)',
  },
  {
    id: 385,
    code: '523912',
    value: 'Venta al por menor y mayor de productos e insumos agropecuarios',
  },
  {
    id: 386,
    code: '523913',
    value: 'Venta al por menor de animales domésticos para mascotas.',
  },
  {
    id: 387,
    code: '523915',
    value: 'Distribución y venta de gas en cilindro',
  },
  {
    id: 388,
    code: '523916',
    value:
      'Comercio al por menor de alimentos y productos n.c.p. exentos de ventas',
  },
  {
    id: 389,
    code: '523917',
    value: 'Venta al por menor de bicicletas y sus accesorios',
  },
  {
    id: 390,
    code: '523918',
    value: 'Venta al por menor de equipo de audio y video',
  },
  {
    id: 391,
    code: '523919',
    value: 'Venta al por menor de artículos y accesorios ortopédicos',
  },
  {
    id: 392,
    code: '523920',
    value: 'Venta al por menor de anteojos y artículos ópticos (óptica)',
  },
  {
    id: 393,
    code: '523921',
    value:
      'Venta al por menor de cajas registradoras, calculadoras o maquinas de contabilidad',
  },
  {
    id: 394,
    code: '523922',
    value: 'Venta al por menor de polen y semillas',
  },
  {
    id: 395,
    code: '523923',
    value: 'Venta al por menor de repuestos para electrodomésticos',
  },
  {
    id: 396,
    code: '523925',
    value: 'Venta al por menor de extintores',
  },
  {
    id: 397,
    code: '523926',
    value: 'Mantenimiento de extintores',
  },
  {
    id: 398,
    code: '523927',
    value: 'Reparación equipo de audio y video',
  },
  {
    id: 399,
    code: '523929',
    value: 'Venta de perros entrenados para seguridad',
  },
  {
    id: 400,
    code: '523930',
    value: 'Venta al por menor de piezas de bambú',
  },
  {
    id: 401,
    code: '524001',
    value: 'Venta de todo tipo de artículos usados',
  },
  {
    id: 402,
    code: '524002',
    value: 'Casa de empeño y afín',
  },
  {
    id: 403,
    code: '524004',
    value: 'Venta de libros usados',
  },
  {
    id: 404,
    code: '524005',
    value:
      'Venta de monedas, billetes, estampillas (nuevas y usadas) para colección y especies fiscales',
  },
  {
    id: 405,
    code: '525101',
    value: 'Venta al por menor de todo tipo de artículos por catálogo',
  },
  {
    id: 406,
    code: '525201',
    value: 'Venta de revistas/periódicos (puestos callejeros)',
  },
  {
    id: 407,
    code: '525301',
    value: 'Venta de lotería en agencias o al detalle',
  },
  {
    id: 408,
    code: '525303',
    value: 'Emisión de la lotería nacional y similares',
  },
  {
    id: 409,
    code: '525901',
    value: 'Venta de alfombras y tapices',
  },
  {
    id: 410,
    code: '525902',
    value: 'Venta ambulante de artículos para el hogar',
  },
  {
    id: 411,
    code: '525903',
    value: 'Venta de libros a domicilio',
  },
  {
    id: 412,
    code: '525904',
    value: 'Trabajos de manualidades',
  },
  {
    id: 413,
    code: '525905',
    value:
      'Venta al por menor de tarjetas telefónicas, pines, tiempo aire y similares',
  },
  {
    id: 414,
    code: '526001',
    value: 'Servicios de reparación de zapatos',
  },
  {
    id: 415,
    code: '526002',
    value: 'Reparación de muebles y accesorios domésticos',
  },
  {
    id: 416,
    code: '526003',
    value: 'Reparación de bicicletas',
  },
  {
    id: 417,
    code: '526005',
    value: 'Electricista, servicios',
  },
  {
    id: 418,
    code: '526006',
    value: 'Reparación de artículos eléctricos',
  },
  {
    id: 419,
    code: '526008',
    value: 'Reparación y mantenimiento de persianas y cortinas',
  },
  {
    id: 420,
    code: '526010',
    value: 'Instalación y mantenimiento de persianas y cortinas',
  },
  {
    id: 421,
    code: '526011',
    value: 'Reparación y mantenimiento de equipo para telecomunicaciones',
  },
  {
    id: 422,
    code: '526012',
    value: 'Instalación y mantenimiento de piscinas,jacuzzis y similares',
  },
  {
    id: 423,
    code: '551001',
    value:
      'Alquiler de bienes inmuebles de uso habitacional por períodos inferiores a un mes (casas de estancia transitoria, casa de huéspedes, cabinas, campamentos, entre otros)',
  },
  {
    id: 424,
    code: '551002',
    value: 'Hotel',
  },
  {
    id: 425,
    code: '551004',
    value:
      'Motel y/o servicio de habitación ocasional, albergues, posadas y similares',
  },
  {
    id: 426,
    code: '551005',
    value: 'Servicio de salas vip y premium en aeropuertos',
  },
  {
    id: 427,
    code: '552001',
    value: 'Bares, cantinas o tabernas',
  },
  {
    id: 428,
    code: '552002',
    value: 'Cafeterías',
  },
  {
    id: 429,
    code: '552003',
    value: 'Servicios de catering servicie',
  },
  {
    id: 430,
    code: '552004',
    value:
      'Servicio de restaurante, cafeterías, sodas y otros expendios de comida',
  },
  {
    id: 431,
    code: '552005',
    value: 'Sodas',
  },
  {
    id: 432,
    code: '552007',
    value: 'Otros expendios de comidas',
  },
  {
    id: 433,
    code: '601001',
    value: 'Servicio de transporte por vía férrea',
  },
  {
    id: 434,
    code: '602001',
    value: 'Servicio de transporte de carga por vía terrestre',
  },
  {
    id: 435,
    code: '602002',
    value: 'Transporte de productos derivados del petróleo',
  },
  {
    id: 436,
    code: '602101',
    value: 'Transporte de estudiantes y empleados',
  },
  {
    id: 437,
    code: '602102',
    value: 'Servicio de transporte regular de personas por vía terrestre',
  },
  {
    id: 438,
    code: '602201',
    value: 'Servicios de bus/microbús (excursiones)',
  },
  {
    id: 439,
    code: '602202',
    value: 'Servicio de taxi',
  },
  {
    id: 440,
    code: '602203',
    value: 'Servicio especial estable de taxi',
  },
  {
    id: 441,
    code: '602301',
    value:
      'Servicio de acarreo y distribución de todo tipo de mercancía (incluye la mudanza nacional)',
  },
  {
    id: 442,
    code: '602302',
    value: 'Servicios de grúa',
  },
  {
    id: 443,
    code: '602304',
    value: 'Servicio de mudanza internacional',
  },
  {
    id: 444,
    code: '603001',
    value: 'Servicio de transporte por tuberías',
  },
  {
    id: 445,
    code: '611001',
    value:
      'Servicio de transporte de carga y/o cabotaje de pasajeros por vía acuática',
  },
  {
    id: 446,
    code: '621001',
    value: 'Servicio de transporte de carga por vía aérea',
  },
  {
    id: 447,
    code: '621002',
    value: 'Servicio de transporte aéreo regulado de pasajeros (líneas aéreas)',
  },
  {
    id: 448,
    code: '621003',
    value: 'Servicio de pilotaje',
  },
  {
    id: 449,
    code: '622001',
    value: 'Servicio de transporte aéreo no regular de pasajeros',
  },
  {
    id: 450,
    code: '630001',
    value: 'Prestación de servicios de transito aéreo',
  },
  {
    id: 451,
    code: '630101',
    value: 'Servicio de consolidación de carga y descarga',
  },
  {
    id: 452,
    code: '630201',
    value: 'Servicios de almacenaje',
  },
  {
    id: 453,
    code: '630301',
    value: 'Parqueos/estacionamiento de vehículos',
  },
  {
    id: 454,
    code: '630302',
    value:
      'Funcionamiento de instalaciones terminales como puertos, muelles y aeropuertos',
  },
  {
    id: 455,
    code: '630303',
    value: 'Permiso de paso por propiedades privadas (peaje)',
  },
  {
    id: 456,
    code: '630401',
    value: 'Agencias de viajes y excursiones',
  },
  {
    id: 457,
    code: '630403',
    value: 'Guía de turismo y servicio de asesoría en viajes y turismo',
  },
  {
    id: 458,
    code: '630405',
    value: 'Explotación de actividades turísticas',
  },
  {
    id: 459,
    code: '630901',
    value:
      'Agencias aduanales, almacenes fiscales y estacionamientos transitorios',
  },
  {
    id: 460,
    code: '630903',
    value: 'Agencia de transporte (naviera)',
  },
  {
    id: 461,
    code: '630904',
    value: 'Agente aduanero físico o jurídico',
  },
  {
    id: 462,
    code: '641101',
    value: 'Actividades postales y de correo',
  },
  {
    id: 463,
    code: '642001',
    value: 'Servicios de radio-mensajes, radiolocalizadores y similares',
  },
  {
    id: 464,
    code: '642002',
    value:
      'Servicio de televisión por cable, satélite u otros sistemas similares',
  },
  {
    id: 465,
    code: '642003',
    value: 'Mantenimiento de redes de telecomunicación',
  },
  {
    id: 466,
    code: '642004',
    value: 'Servicio de radio frecuencia',
  },
  {
    id: 467,
    code: '642005',
    value: 'Servicios telefónicos, telegráficos y por télex',
  },
  {
    id: 468,
    code: '642007',
    value: 'Venta de espacio en el cable submarino',
  },
  {
    id: 469,
    code: '642008',
    value:
      'Servicio de transmisión de datos, texto, sonido, voz y video por medio de la red de internet',
  },
  {
    id: 470,
    code: '651101',
    value: 'Banca central',
  },
  {
    id: 471,
    code: '651901',
    value: 'Cooperativas de ahorro y crédito',
  },
  {
    id: 472,
    code: '651903',
    value: 'Bancos estatales (excepto el banco central)',
  },
  {
    id: 473,
    code: '651904',
    value: 'Entidades financieras privadas (bancos)',
  },
  {
    id: 474,
    code: '651905',
    value: 'Servicio de envió y recibo de dinero',
  },
  {
    id: 475,
    code: '651906',
    value: 'Instituciones de ahorro y crédito para vivienda',
  },
  {
    id: 476,
    code: '659101',
    value:
      'Arrendamiento operativo en función financiera con opción de compra o renovación (leasing operativo)',
  },
  {
    id: 477,
    code: '659201',
    value: 'Entidades financieras distintas al sistema bancario nacional',
  },
  {
    id: 478,
    code: '659202',
    value: 'Emisoras y procesadoras de tarjetas de crédito',
  },
  {
    id: 479,
    code: '659901',
    value: 'Servicio de préstamo (prestamistas)',
  },
  {
    id: 480,
    code: '659902',
    value: 'Fideicomisos y/o administradores de fondos de inversión',
  },
  {
    id: 481,
    code: '659903',
    value: 'Sociedades de inversión mobiliaria',
  },
  {
    id: 482,
    code: '659904',
    value: 'Servicio recuperación de deudas (factore0)',
  },
  {
    id: 483,
    code: '659905',
    value: 'Fondos de inversión',
  },
  {
    id: 484,
    code: '659906',
    value: 'Actividades de sociedades de cartera (holding)',
  },
  {
    id: 485,
    code: '659907',
    value: 'Ingresos por intereses diferentes al comercio del préstamo',
  },
  {
    id: 486,
    code: '659908',
    value: 'Fondos de la ley del sistema de banca para el desarrollo',
  },
  {
    id: 487,
    code: '659909',
    value: 'Fideicomisos de la ley del sistema de banca para el desarrollo',
  },
  {
    id: 488,
    code: '660101',
    value: 'Planes de seguros de vida',
  },
  {
    id: 489,
    code: '660201',
    value: 'Operadora de pensiones',
  },
  {
    id: 490,
    code: '660202',
    value: 'Fondos de operadoras de pensiones exentos del 8%',
  },
  {
    id: 491,
    code: '660301',
    value: 'Agentes de seguros',
  },
  {
    id: 492,
    code: '660302',
    value: 'Comercializadores de seguros',
  },
  {
    id: 493,
    code: '660303',
    value: 'Planes de seguros generales',
  },
  {
    id: 494,
    code: '671101',
    value: 'Administración de mercados financieros',
  },
  {
    id: 495,
    code: '671201',
    value: 'Corredores de bolsa',
  },
  {
    id: 496,
    code: '671202',
    value: 'Puestos de bolsa',
  },
  {
    id: 497,
    code: '671901',
    value: 'Puestos y/o casas de cambio de moneda extranjera',
  },
  {
    id: 498,
    code: '671902',
    value:
      'Asesores financieros y actividades auxiliares de la intermediación financiera',
  },
  {
    id: 499,
    code: '672001',
    value:
      'Actividades auxiliares de la financiación de planes de seguros y de pensiones',
  },
  {
    id: 500,
    code: '701002',
    value: 'Alquiler de locales comerciales y centros comerciales',
  },
  {
    id: 501,
    code: '701003',
    value: 'Compra y venta de propiedades (inversionistas)',
  },
  {
    id: 502,
    code: '701004',
    value:
      'Alquiler de edificios y propiedades diferentes a casas de habitación',
  },
  {
    id: 503,
    code: '701005',
    value: 'Alquiler de marcas registradas',
  },
  {
    id: 504,
    code: '701006',
    value: 'Alquiler de patentes (de licores, únicamente)',
  },
  {
    id: 505,
    code: '701007',
    value: 'Explotación de franquicias',
  },
  {
    id: 506,
    code: '702001',
    value: 'Agentes o corredores de bienes raíces',
  },
  {
    id: 507,
    code: '702002',
    value:
      'Arrendamiento o alquiler de bienes inmuebles mediante contrato verbal o escrito.',
  },
  {
    id: 508,
    code: '702003',
    value:
      'Administración, mantenimiento, reparación y limpieza de los servicios y bienes comunes de la propiedad en condominio',
  },
  {
    id: 509,
    code: '711101',
    value: 'Alquiler de automóviles de todo tipo',
  },
  {
    id: 510,
    code: '711102',
    value: 'Alquiler de motocicleta/servicio',
  },
  {
    id: 511,
    code: '711103',
    value:
      'Alquiler de equipo de transporte por vía terrestre, acuática o aérea',
  },
  {
    id: 512,
    code: '711105',
    value: 'Alquiler de carritos de golf y otros',
  },
  {
    id: 513,
    code: '712101',
    value: 'Alquiler de maquinaria y equipo para uso agrícola',
  },
  {
    id: 514,
    code: '712201',
    value: 'Alquiler de maquinaria y equipo de construcción e ingeniería civil',
  },
  {
    id: 515,
    code: '712301',
    value: 'Servicios de internet en locales públicos ( café internet )',
  },
  {
    id: 516,
    code: '712302',
    value: 'Alquiler de maquinaria y equipo de oficina',
  },
  {
    id: 517,
    code: '712901',
    value: 'Alquiler de maquinas expendedoras de alimentos y otros',
  },
  {
    id: 518,
    code: '712902',
    value: 'Alquiler de máquinas de entretenimiento (con monedas)',
  },
  {
    id: 519,
    code: '712904',
    value:
      'Alquiler de películas cinematográficas (video club) y/o vídeo juegos',
  },
  {
    id: 520,
    code: '712905',
    value: 'Alquiler de equipo para radio, televisión y comunicaciones',
  },
  {
    id: 521,
    code: '712906',
    value: 'Alquiler de equipo para dispensar billetes (cajero automático)',
  },
  {
    id: 522,
    code: '712907',
    value:
      'Alquiler maquinaria y equipo para la elaboración y/o mantenimiento de productos alimenticios',
  },
  {
    id: 523,
    code: '712909',
    value: 'Alquiler de otros tipos de maquinaria y equipo para uso comercial',
  },
  {
    id: 524,
    code: '712910',
    value: 'Alquiler de maquinaria y/o equipo para uso industrial',
  },
  {
    id: 525,
    code: '712911',
    value: 'Alquiler de rampas y similares',
  },
  {
    id: 526,
    code: '713001',
    value: 'Alquiler de menaje',
  },
  {
    id: 527,
    code: '713002',
    value: 'Alquiler de equipo recreativo y deportivo',
  },
  {
    id: 528,
    code: '713003',
    value: 'Alquiler de equipo médico y artículos conexos',
  },
  {
    id: 529,
    code: '713012',
    value: 'Alquiler de trajes de todo tipo',
  },
  {
    id: 530,
    code: '713013',
    value: 'Alquiler de equipo y utensilios para eventos especiales',
  },
  {
    id: 531,
    code: '713053',
    value: 'Alquiler de animales (caballos, serpientes, etc.)',
  },
  {
    id: 532,
    code: '721001',
    value: 'Consultores informáticos',
  },
  {
    id: 711,
    code: '721110 ',
    value:
      'Servicios de alquiler residencial, con monto de alquiler mensual inferior o igual a 1.5 salarios base',
  },
  {
    id: 533,
    code: '722003',
    value: 'Diseñador gráfico, de software y páginas web',
  },
  {
    id: 534,
    code: '723001',
    value: 'Procesamiento de datos',
  },
  {
    id: 535,
    code: '724001',
    value: 'Actividades relacionadas con bases de datos',
  },
  {
    id: 536,
    code: '725001',
    value: 'Reparación de equipo de cómputo',
  },
  {
    id: 537,
    code: '725002',
    value: 'Mantenimiento de equipo de cómputo',
  },
  {
    id: 538,
    code: '725003',
    value:
      'Reparación cajas registradoras, calculadoras, máquinas de contabilidad y equipo de oficina',
  },
  {
    id: 539,
    code: '725004',
    value:
      'Mantenimiento de cajas registradoras, calculadoras, máquinas de contabilidad',
  },
  {
    id: 540,
    code: '729001',
    value: 'Otras actividades de informática',
  },
  {
    id: 541,
    code: '731001',
    value:
      'Investigación y desarrollo experimental en el campo de las ciencias naturales y la ingeniería',
  },
  {
    id: 542,
    code: '731003',
    value: 'Meteorólogo por cuenta propia',
  },
  {
    id: 543,
    code: '732001',
    value:
      'Investigaciones y desarrollo experimental en el campo de las ciencias sociales y las humanidades',
  },
  {
    id: 544,
    code: '741101',
    value: 'Bufete de abogado, notario, asesor legal',
  },
  {
    id: 545,
    code: '741203',
    value:
      'Actividad de contabilidad (contadores), teneduría de libros, auditoria y asesor fiscal',
  },
  {
    id: 546,
    code: '741301',
    value: 'Asesores en mercadeo y ventas',
  },
  {
    id: 547,
    code: '741302',
    value: 'Encuestas de opinión publica',
  },
  {
    id: 548,
    code: '741401',
    value: 'Economistas',
  },
  {
    id: 549,
    code: '741402',
    value: 'Asesoramiento empresarial y en materia de gestión',
  },
  {
    id: 550,
    code: '741407',
    value: 'Asesor aduanero',
  },
  {
    id: 551,
    code: '741408',
    value: 'Corresponsal de radio, televisión y prensa escrita',
  },
  {
    id: 552,
    code: '742102',
    value: 'Actividades de arquitectura e ingeniería',
  },
  {
    id: 553,
    code: '742105',
    value: 'Servicios de consultoría en mantenimiento industrial y mecánico',
  },
  {
    id: 554,
    code: '742107',
    value: 'Químicos',
  },
  {
    id: 555,
    code: '742108',
    value: 'Actividades de geografía y/o geología',
  },
  {
    id: 556,
    code: '742110',
    value: 'Biólogo por cuenta propia',
  },
  {
    id: 557,
    code: '742112',
    value: 'Dibujante arquitectónico y/o planos de construcción',
  },
  {
    id: 558,
    code: '742201',
    value: 'Ensayos y análisis técnicos',
  },
  {
    id: 559,
    code: '742202',
    value: 'Profesionales en tecnología de alimentos',
  },
  {
    id: 560,
    code: '743001',
    value: 'Publicidad',
  },
  {
    id: 561,
    code: '743004',
    value: 'Servicios de publicidad',
  },
  {
    id: 562,
    code: '743005',
    value: 'Alquiler de espacios publicitarios',
  },
  {
    id: 563,
    code: '743006',
    value: 'Publicidad a través de medios electrónicos',
  },
  {
    id: 564,
    code: '749101',
    value: 'Servicios de administración de personal',
  },
  {
    id: 565,
    code: '749102',
    value: 'Servicios de bibliotecología',
  },
  {
    id: 566,
    code: '749201',
    value:
      'Servicios de investigación, seguridad privada, agencias y consultores',
  },
  {
    id: 567,
    code: '749204',
    value: 'Instructor de tiro (manejo de armas).',
  },
  {
    id: 568,
    code: '749301',
    value: 'Servicios de fumigación (no agrícola)',
  },
  {
    id: 569,
    code: '749302',
    value: 'Servicios de limpieza (interiores y exteriores)',
  },
  {
    id: 570,
    code: '749401',
    value: 'Estudios fotográficos',
  },
  {
    id: 571,
    code: '749402',
    value: 'Servicio de fotografía (fotógrafo)',
  },
  {
    id: 572,
    code: '749501',
    value: 'Servicios de envase y empaque',
  },
  {
    id: 573,
    code: '749901',
    value: 'Servicio de fotocopiado y otros',
  },
  {
    id: 574,
    code: '749902',
    value: 'Servicios de levantado de texto y/o corrección de textos y otros',
  },
  {
    id: 575,
    code: '749903',
    value: 'Servicios secretariales y/o oficinista',
  },
  {
    id: 576,
    code: '749904',
    value: 'Servicio de diseño o decoración de interiores (por cuenta propia).',
  },
  {
    id: 577,
    code: '749905',
    value: 'Servicio de contestación de teléfonos (call center)',
  },
  {
    id: 578,
    code: '749906',
    value: 'Servicios de traductor',
  },
  {
    id: 579,
    code: '749907',
    value:
      'Servicio de inspección de todo tipo de mercaderías (incluye drogas)',
  },
  {
    id: 580,
    code: '749908',
    value: 'Servicio de recolección de monedas de los teléfonos públicos',
  },
  {
    id: 581,
    code: '749911',
    value: 'Agencias fotográficas por catálogo',
  },
  {
    id: 582,
    code: '749914',
    value: 'Servicio de cobranza de recibos públicos y otros',
  },
  {
    id: 583,
    code: '749917',
    value: 'Modelaje profesional (modelo)',
  },
  {
    id: 584,
    code: '749918',
    value: 'Agencias de cobro y calificación crediticia',
  },
  {
    id: 585,
    code: '751101',
    value: 'Actividades de la administración publica en general',
  },
  {
    id: 586,
    code: '751102',
    value:
      'Actividades de la administración publica en general, no sujetas al impuesto sobre la renta',
  },
  {
    id: 587,
    code: '751201',
    value:
      'Juntas de educación, comedores escolares, patronatos, cooperativas escolares, colegiales, vocacionales',
  },
  {
    id: 588,
    code: '751202',
    value: 'Comités cantonales de deportes y recreación',
  },
  {
    id: 589,
    code: '751301',
    value: 'Actividades del sector público relacionadas con la infraestructura',
  },
  {
    id: 590,
    code: '751302',
    value: 'Fondos públicos (actividades del sector público)',
  },
  {
    id: 591,
    code: '751401',
    value:
      'Actividades de servicios auxiliares para la administración pública en general',
  },
  {
    id: 592,
    code: '752101',
    value: 'Relaciones exteriores',
  },
  {
    id: 593,
    code: '752201',
    value: 'Actividades de defensa',
  },
  {
    id: 594,
    code: '752301',
    value: 'Actividades de mantenimiento del orden público y de seguridad',
  },
  {
    id: 595,
    code: '752302',
    value:
      'Servicios de vigilancia o control portuaria, costera, aérea fronteriza',
  },
  {
    id: 596,
    code: '753001',
    value:
      'Actividades de planes de seguridad social de afiliación obligatoria',
  },
  {
    id: 597,
    code: '801001',
    value: 'Enseñanza preescolar y primaria privada',
  },
  {
    id: 598,
    code: '802101',
    value: 'Enseñanza secundaria privada',
  },
  {
    id: 599,
    code: '802201',
    value: 'Enseñanza secundaria de formación técnica y profesional',
  },
  {
    id: 600,
    code: '803002',
    value: 'Enseñanza superior privada (universidades)',
  },
  {
    id: 601,
    code: '809001',
    value: 'Escuelas comerciales (no estatales)',
  },
  {
    id: 602,
    code: '809004',
    value: 'Profesor por cuenta propia',
  },
  {
    id: 603,
    code: '809005',
    value: 'Enseñanza cultural',
  },
  {
    id: 604,
    code: '809006',
    value: 'Enseñanza de la de seguridad privada',
  },
  {
    id: 605,
    code: '809007',
    value: 'Escuela y agencia de modelos',
  },
  {
    id: 606,
    code: '851101',
    value: 'Clínica, centros médicos, hospitales privados y otros',
  },
  {
    id: 607,
    code: '851201',
    value: 'Ginecólogo',
  },
  {
    id: 608,
    code: '851202',
    value: 'Servicios de médico general',
  },
  {
    id: 609,
    code: '851203',
    value: 'Neurólogos',
  },
  {
    id: 610,
    code: '851204',
    value: 'Servicios de oftalmólogo u oculista, optometrista y/o óptico',
  },
  {
    id: 611,
    code: '851206',
    value: 'Ortopedista (consulta privada)',
  },
  {
    id: 612,
    code: '851207',
    value: 'Servicios de odontólogo y conexos',
  },
  {
    id: 613,
    code: '851208',
    value: 'Otorrinolaringología, audiología y servicios conexos.',
  },
  {
    id: 614,
    code: '851209',
    value: 'Farmacéutico o boticario',
  },
  {
    id: 615,
    code: '851210',
    value: 'Cardiólogos',
  },
  {
    id: 616,
    code: '851212',
    value: 'Oncólogos',
  },
  {
    id: 617,
    code: '851213',
    value: 'Reumatólogo',
  },
  {
    id: 618,
    code: '851901',
    value: 'Medicina alternativa',
  },
  {
    id: 619,
    code: '851902',
    value: 'Físico terapista',
  },
  {
    id: 620,
    code: '851905',
    value: 'Psicólogo',
  },
  {
    id: 621,
    code: '851906',
    value: 'Psiquiatría',
  },
  {
    id: 622,
    code: '851907',
    value: 'Servicios de enfermería',
  },
  {
    id: 623,
    code: '851908',
    value: 'Laboratorios médicos - clínicos',
  },
  {
    id: 624,
    code: '851909',
    value: 'Servicios de radiología, anestesiología y otros',
  },
  {
    id: 625,
    code: '851910',
    value: 'Transporte en ambulancia terrestre y aéreo (servicio privado)',
  },
  {
    id: 626,
    code: '851911',
    value: 'Profesionales en salud ocupacional',
  },
  {
    id: 627,
    code: '851912',
    value: 'Nutricionista',
  },
  {
    id: 628,
    code: '851913',
    value: 'Profesionales en educación especial',
  },
  {
    id: 629,
    code: '851914',
    value: 'Servicios de paramédicos',
  },
  {
    id: 630,
    code: '851915',
    value: 'Servicios de esterilización de productos médicos y farmacéuticos',
  },
  {
    id: 631,
    code: '851916',
    value:
      'Otras actividades relacionadas con la salud humana(banco de sangre, banco de piel, etc.)',
  },
  {
    id: 632,
    code: '852001',
    value: 'Servicios veterinarios con venta de productos gravados con ventas',
  },
  {
    id: 633,
    code: '852002',
    value: 'Servicios médicos veterinarios',
  },
  {
    id: 634,
    code: '853101',
    value: 'Fundaciones de bien social',
  },
  {
    id: 635,
    code: '853102',
    value: 'Fundaciones de servicio social (privadas)',
  },
  {
    id: 636,
    code: '853103',
    value: 'Residencias universitarias con servicios integrados',
  },
  {
    id: 637,
    code: '853201',
    value: 'Guarderías/centros infantiles y servicios sociales',
  },
  {
    id: 638,
    code: '853301',
    value:
      'Asociaciones declaradas de utilidad pública por el poder ejecutivo, incluye las asadas',
  },
  {
    id: 639,
    code: '900001',
    value:
      'Asesoramiento y eliminación de desperdicios, saneamiento (excepto limpieza de tanques sépticos)',
  },
  {
    id: 640,
    code: '900002',
    value: 'Limpieza de tanques sépticos y aguas residuales',
  },
  {
    id: 641,
    code: '911101',
    value: 'Actividades de organizaciones empresariales y de empleadores',
  },
  {
    id: 642,
    code: '911102',
    value: 'Cámaras de comercio',
  },
  {
    id: 643,
    code: '911201',
    value: 'Actividades de organizaciones profesionales',
  },
  {
    id: 644,
    code: '912001',
    value: 'Asociaciones solidaristas',
  },
  {
    id: 645,
    code: '912002',
    value: 'Actividades de sindicatos',
  },
  {
    id: 646,
    code: '919101',
    value: 'Actividades de organizaciones religiosas',
  },
  {
    id: 647,
    code: '919201',
    value: 'Actividades de organizaciones políticas',
  },
  {
    id: 648,
    code: '919901',
    value: 'Asociación de desarrollo comunal y/o servicios comunitarios',
  },
  {
    id: 649,
    code: '919902',
    value: 'Asociaciones de clubes sociales',
  },
  {
    id: 650,
    code: '919903',
    value:
      'Asociaciones o entidades con fines culturales, sociales, recreativos, artesanales, etc.',
  },
  {
    id: 651,
    code: '919905',
    value: 'Asociación proada a personas adictas a drogas e indigentes',
  },
  {
    id: 652,
    code: '921101',
    value:
      'Actividades producción postproducción y distribución de películas cinematográficas (videos)',
  },
  {
    id: 653,
    code: '921201',
    value: 'Exhibición de filmes y videocintas (salas de cine)',
  },
  {
    id: 654,
    code: '921301',
    value: 'Radioemisoras',
  },
  {
    id: 655,
    code: '921302',
    value: 'Programación y transmisiones de radio y televisión',
  },
  {
    id: 656,
    code: '921401',
    value: 'Teatros (explotación)',
  },
  {
    id: 657,
    code: '921402',
    value: 'Actividades musicales y artísticas (servicios)',
  },
  {
    id: 658,
    code: '921403',
    value: 'Periodista por cuenta propia',
  },
  {
    id: 659,
    code: '921501',
    value: 'Canales de televisión',
  },
  {
    id: 660,
    code: '921902',
    value: 'Venta al por menor de discos, grabaciones de música y de video',
  },
  {
    id: 661,
    code: '921903',
    value: 'Salón de baile y discoteca',
  },
  {
    id: 662,
    code: '921904',
    value: 'Salón de patines',
  },
  {
    id: 663,
    code: '922001',
    value: 'Actividades de agencias de noticias',
  },
  {
    id: 664,
    code: '923101',
    value: 'Actividades de bibliotecas y archivos',
  },
  {
    id: 665,
    code: '923201',
    value: 'Galerías de arte',
  },
  {
    id: 666,
    code: '923202',
    value: 'Servicios de restauración de obras de arte',
  },
  {
    id: 667,
    code: '923301',
    value:
      'Actividades de jardines botánicos, zoológicos, parques nacionales y reservas nacionales',
  },
  {
    id: 668,
    code: '924101',
    value: 'Gimnasios',
  },
  {
    id: 669,
    code: '924102',
    value: 'Explotación de piscinas o albercas de baño',
  },
  {
    id: 670,
    code: '924103',
    value:
      'Entrenador, instructores y/o preparadores físicos por cuenta propia',
  },
  {
    id: 671,
    code: '924104',
    value: 'Actividades de escuelas y clubes deportivos',
  },
  {
    id: 672,
    code: '924105',
    value: 'Explotación de instalaciones y campos deportivos',
  },
  {
    id: 673,
    code: '924106',
    value: 'Actividades deportivas y otras por cuenta propia',
  },
  {
    id: 674,
    code: '924107',
    value: 'Espectáculos deportivos',
  },
  {
    id: 675,
    code: '924901',
    value: 'Light club/cabaret té',
  },
  {
    id: 676,
    code: '924902',
    value: 'Casinos y salas de juegos',
  },
  {
    id: 677,
    code: '924903',
    value: 'Pesca deportiva',
  },
  {
    id: 678,
    code: '924904',
    value: 'Actividades de juegos de billar, pool y otros similares',
  },
  {
    id: 679,
    code: '924905',
    value: 'Sala de video juegos',
  },
  {
    id: 680,
    code: '924906',
    value:
      'Grabación de sonido (música, etc.) en discos gramofónicos y en cinta magnetofónica',
  },
  {
    id: 681,
    code: '924907',
    value:
      'Servicio de enlace de llamadas y casas de apuestas electrónicas (sportbooks)',
  },
  {
    id: 682,
    code: '924908',
    value:
      'Espectáculos públicos en general excepto los deportivos y el teatro',
  },
  {
    id: 683,
    code: '924909',
    value: 'Otras actividades de esparcimiento',
  },
  {
    id: 684,
    code: '930101',
    value: 'Servicios de lavandería',
  },
  {
    id: 685,
    code: '930102',
    value: 'Servicio de teñido de prendas de vestir',
  },
  {
    id: 686,
    code: '930103',
    value: 'Servicio de limpieza y lavado de muebles',
  },
  {
    id: 687,
    code: '930202',
    value: 'Salones de belleza, peluquería y barberia',
  },
  {
    id: 688,
    code: '930301',
    value: 'Servicios fúnebres y actividades conexas',
  },
  {
    id: 689,
    code: '930302',
    value: 'Cementerio público (junta administrativa)',
  },
  {
    id: 690,
    code: '930303',
    value: 'Cementerio o camposanto privado',
  },
  {
    id: 691,
    code: '930901',
    value: 'Salas de masajes',
  },
  {
    id: 692,
    code: '930903',
    value: 'Otras actividades de servicios personales n.c.p.',
  },
  {
    id: 693,
    code: '930904',
    value: 'Centro o sala de bronceado',
  },
  {
    id: 694,
    code: '930905',
    value: 'Peluquería y sala de estética para animales',
  },
  {
    id: 695,
    code: '930906',
    value: 'Servicio de tatuaje y piercing',
  },
  {
    id: 696,
    code: '930907',
    value: 'Servicio de disco móvil',
  },
  {
    id: 697,
    code: '930908',
    value: 'Servicio de herradura',
  },
  {
    id: 698,
    code: '930915',
    value: 'Albergue y cuidado de animales a domicilio',
  },
  {
    id: 699,
    code: '950001',
    value: 'Hogares privados con servicio doméstico',
  },
  {
    id: 700,
    code: '960103',
    value:
      'Actividad no especificada (solo régimen simplificado para efectos de migración)',
  },
  {
    id: 701,
    code: '960104',
    value: 'Impuesto a las personas jurídicas',
  },
  {
    id: 702,
    code: '960105',
    value: 'Actividades preoperativas o de organización',
  },
  {
    id: 703,
    code: '990001',
    value:
      'Actividades de organizaciones y órganos extraterritoriales, incluye las embajadas de otros países',
  },
  {
    id: 704,
    code: '523935',
    value:
      'Venta al por menor de otros productos nuevos en comercios especializados no incluidos en canasta básica',
  },
  {
    id: 705,
    code: '960106',
    value: 'Exportadores de mercancías exentas del impuesto de ventas',
  },
  {
    id: 706,
    code: '12303',
    value:
      'Producción de queso y otros productos lácteos exentos del impuesto sobre las ventas',
  },
  {
    id: 707,
    code: '11105',
    value: 'Cultivo de tubérculos',
  },
  {
    id: 708,
    code: '12302',
    value:
      'Producción de leche cruda y otros productos lácteos sin procesamientos incluidos en canasta',
  },
  {
    id: 709,
    code: '853202',
    value:
      'Servicios sociales de redes de cuido y centros de atención de adultos mayores',
  },
  {
    id: 710,
    code: '701001',
    value:
      'Actividades inmobiliarias (Alquiler de vivienda superior a 1.5 salario base)',
  },
];

import {
  Button,
  Grid,
  Space,
  Typography,
  useDeviceType,
  Tooltip,
  useModal,
  EmptyState,
} from '@alegradev/smile-ui-react';
import { useSelector } from 'react-redux';
import ContactDetailBodyHeader from './BodyHeader';
import { I18n } from 'aws-amplify';
import { useEffect, useMemo } from 'react';
import getInformationByCountry from './informationByCountry';
import { country as countrySelector } from '../../../../selectors/company';
import { hasPermissionTo } from '../../../../selectors/auth';
import checkIfIsDefaultClient from '../../utils/isDefaultClient';
import {
  useChangeContactStatusMutation,
  useDeleteContactMutation,
} from '../../services/mutations';
import { handleDeleteClient } from '../../utils/modals';
import { useHistory } from 'react-router-dom';
import { useFormat } from '../../../../hooks/useFormat';
import useTotalToCollect from '../../../../hooks/useTotalToCollect/hook';
import { IconBulb, IconLock, IconPencil, IconTrash } from '@tabler/icons-react';
import { showCreditLimitField } from '../../utils/limitCredit';

function ContactDetailBodyInformation({ contact, partible = false }) {
  const type = useDeviceType();
  const can = useSelector(hasPermissionTo);
  const country = useSelector(countrySelector);
  const isMobileTable = type === 'mobile' || type === 'tablet';
  const history = useHistory();
  const { openModal, closeModal } = useModal();
  const { mutate: handleDeleteContact } = useDeleteContactMutation();
  const { mutate: handleChangeContactStatus } =
    useChangeContactStatusMutation();
  const { Wrapper, Col, Row } = Grid;
  const { totalToCollect, getBalanceByClient } = useTotalToCollect({
    client: contact,
  });

  const { fmt, decimal } = useFormat();

  useEffect(() => {
    if (showCreditLimitField(country)) {
      getBalanceByClient();
    }
  }, [country]);

  const isDefaultClient = useMemo(
    () => checkIfIsDefaultClient(contact, country),
    [contact, country]
  );

  const detailInfo = useMemo(
    () =>
      getInformationByCountry(country, contact, fmt, decimal, totalToCollect),
    [contact, fmt, decimal, totalToCollect]
  );

  const renderSection = (title, section) => {
    if (!detailInfo[section]) return null;

    return (
      <>
        <Typography type='body-1-bold' text={title} />
        <Space height={10} />
        <Row spacing='lg'>
          {Object.keys(detailInfo[section]).map((key) => {
            const { value, label, size, hide } = detailInfo[section][key];
            if (hide) return null;
            return (
              <Col xs={size} md={12} key={key}>
                <Typography type='label-2' text={label} />
                <Typography type='body-2-regular' text={value || '-'} />
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  const wrapperProps = useMemo(() => {
    if (partible) return {};

    return {
      hideScrollbar: true,
      overflow: 'auto',
      relativeHeight: 130,
    };
  }, [partible]);

  if (!can('view', 'contacts') || !can('view-client', 'contacts')) {
    return (
      <Wrapper
        fullHeight
        fullWidth
        justify={{ lg: 'center' }}
        align={{ lg: 'center' }}
      >
        <EmptyState
          hideAction
          generalIcon={IconLock}
          title='Necesitas autorización'
          description='Tu usuario tiene un perfil limitado, para ingresar a esta sección debes solicitar acceso a un usuario administrador.'
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper direction={{ lg: 'column' }} gap={10} {...wrapperProps}>
      <Wrapper
        backgroundColor='white'
        rounded={{ lg: 'xl' }}
        padding={{ lg: 'md' }}
        direction={{ lg: 'column' }}
      >
        {(partible || isMobileTable) && (
          <Wrapper
            direction={{ lg: 'row', sm: 'column' }}
            fullWidth
            gap={10}
            justify={{ lg: partible ? 'between' : 'center', sm: 'center' }}
          >
            <ContactDetailBodyHeader contact={contact} partible={partible} />
            {partible && !isDefaultClient && (
              <Wrapper justify={{ md: 'center' }}>
                {can('delete', 'contacts') && (
                  <Tooltip
                    visible={!can('delete', 'contacts')}
                    overlay={I18n.get(
                      'deleteContactsUnallow',
                      'Te hace falta el permiso para eliminar contactos'
                    )}
                  >
                    <Button
                      onlyIcon={IconTrash}
                      size='small'
                      emphasis='text'
                      disabled={!can('delete', 'contacts')}
                      onClick={() =>
                        handleDeleteClient({
                          contact,
                          handleDeleteContact,
                          openModal,
                          closeModal,
                        })
                      }
                    />
                  </Tooltip>
                )}
                <Tooltip
                  visible={!can('edit', 'contacts')}
                  overlay={I18n.get(
                    'changeStatusContactsUnallow',
                    'Te hace falta el permiso para cambiar el estado de contactos'
                  )}
                >
                  <Button
                    onlyIcon={IconBulb}
                    size='small'
                    emphasis='text'
                    disabled={!can('edit', 'contacts')}
                    onClick={() =>
                      handleChangeContactStatus({
                        id: contact?.id,
                        status: contact?.status,
                      })
                    }
                  />
                </Tooltip>

                <Tooltip
                  visible={!can('edit', 'contacts')}
                  overlay={I18n.get(
                    'editContactsUnallow',
                    'Te hace falta el permiso para editar contactos'
                  )}
                >
                  <Button
                    onlyIcon={IconPencil}
                    size='small'
                    emphasis='text'
                    disabled={!can('edit', 'contacts')}
                    onClick={() =>
                      history.push(`/contacts/contact/${contact.id}`)
                    }
                  />
                </Tooltip>
              </Wrapper>
            )}
          </Wrapper>
        )}

        <Space height={20} />

        {renderSection(
          I18n.get('personalDetails', 'Datos principales'),
          'personalDetails'
        )}
      </Wrapper>

      <Wrapper
        backgroundColor='white'
        rounded={{ lg: 'xl' }}
        padding={{ lg: 'lg' }}
        direction={{ lg: 'column' }}
      >
        {renderSection(
          I18n.get('comercialInformation', 'Información comercial'),
          'comercialInformation'
        )}
      </Wrapper>
      <Wrapper
        backgroundColor='white'
        rounded={{ lg: 'xl' }}
        padding={{ lg: 'lg' }}
        direction={{ lg: 'column' }}
      >
        {renderSection(
          I18n.get('accountingAndTaxConfig', 'Configuración contable y fiscal'),
          'accountingAndTaxConfig'
        )}
      </Wrapper>

      {!partible && (
        <Wrapper>
          <Space height={30} />
        </Wrapper>
      )}
    </Wrapper>
  );
}

export default ContactDetailBodyInformation;

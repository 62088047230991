import React from 'react'
import { useDispatch } from 'react-redux';
import { I18n } from '@aws-amplify/core';

import { setTypeToPrint } from '../../../reducers/print';

import { Button } from '@alegradev/smile-ui-react';
import { IconPrinter } from '@tabler/icons-react';

const Bottom = ({ handleClose }) => {
  const dispatch = useDispatch();

  const handlePrint = () => {
    dispatch(setTypeToPrint('provisionalInvoice'))
  }

  return (
    <div className="modal__invoice-provisional-ticket__bottom">
      <Button
        type="button"
        className='modal__invoice-provisional-ticket__print-button'
        variant='reversed'
        onClick={() => handlePrint()}
        leftIcon={IconPrinter}
        text={I18n.get('print', 'imprimir')}
      />

      <Button
        type="button"
        data-testid="new-invoice-btn"
        onClick={handleClose}
        text={I18n.get('newSale', 'nueva venta')}
      />
    </div>
  )
}

export default Bottom;
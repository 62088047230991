import { Button, Divider, Grid, Typography } from '@alegradev/smile-ui-react';
import { I18n } from 'aws-amplify';
import { GlobalDiscountsTable } from './table';
import { GlobalDiscountsProvider } from './GlobalDiscountsProvider';
import { GlobalDiscountsHeader } from './header';
import { GlobalDiscountsFooter } from './footer';
import { IconX } from '@tabler/icons-react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { closeModal } from '../../../../reducers/modals';

const GlobalDiscounts = () => {
  const { Wrapper } = Grid;
  const dispatch = useDispatch();
  const isOpen = useSelector((state) =>
    get(state, 'modals.globalDiscounts.isOpen', false)
  );

  if (!isOpen) return null;

  const handleReadMore = () => {
    window.open(
      'https://ayuda.alegra.com/colombia/aplicar-descuentos-en-pos',
      '_blank'
    );
  };

  return (
    <GlobalDiscountsProvider>
      <Wrapper
        direction={{ lg: 'column' }}
        backgroundColor='white'
        padding={{ lg: 'md' }}
      >
        <Typography
          type='body-1-bold'
          text={I18n.get('globalDiscounts', 'Descuentos globales')}
        />
        <p style={{ margin: 0 }}>
          <Typography
            type='body-3-regular'
            variant='secondary'
            tag='span'
            text={I18n.get(
              'globalDiscountsDescription',
              'Añade descuentos a todos los productos de una venta de forma fácil y rápida. '
            )}
          />
          <Typography
            tag='span'
            type='body-3-regular'
            extraClassName='pointer'
            variant='success'
            text={I18n.get('knowMore', 'Saber más.')}
            onClick={handleReadMore}
          />
        </p>
        <Wrapper extraClasses='global-discounts__close-button'>
          <Button
            onClick={() => dispatch(closeModal({ modal: 'globalDiscounts' }))}
            onlyIcon={IconX}
            size='small'
            emphasis='text'
          />
        </Wrapper>
      </Wrapper>

      <Divider margin='0' />

      <GlobalDiscountsHeader />

      <Divider margin='0' />

      <GlobalDiscountsTable />

      <GlobalDiscountsFooter />
    </GlobalDiscountsProvider>
  );
};

export default GlobalDiscounts;

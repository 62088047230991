import { get } from 'lodash';
import {
  transformAdditionalCharges,
  transformItems,
  transformPayments,
} from './utils/InvoiceTransform';
import { COUNTRIES } from '../../utils/enums/countries';

export class InvoiceTransformerStrategy {
  /**
   * Transforms an invoice object by extracting and organizing its properties into a structured format.
   *
   * @param {Object} invoice - The invoice object to transform.
   * @returns {Object} A transformed invoice object containing various properties such as idStation, date, dueDate,
   *                   status, timestamp, termsConditions, items, payments, numberTemplate, total, subtotal,
   *                   totalReceived, cashReturned, type, anotation, client, costCenter, priceList, warehouse,
   *                   seller, currency, externalPayment, deliveryTerm, stamp, operationType, saleCondition,
   *                   saleConcept, startDateService, endDateService, paymentForm, paymentMethod, paymentType,
   *                   incomeType, saleType, originApp, and additionalCharges.
   *                   The properties are extracted from the input invoice object using lodash's get function
   *                   and some properties are transformed using utility functions.
   *                   The function also handles conditional removal of undefined or empty properties.
   */
  transform(invoice, company) {
    let additionalCharges = transformAdditionalCharges(invoice);

    let result = {
      idStation: get(invoice, 'idStation', null),
      date: get(invoice, 'date', null),
      dueDate: get(invoice, 'dueDate', null),
      regime: get(invoice, 'regime', null),
      status: get(invoice, 'status', null),
      timestamp: get(invoice, 'timestamp', null),
      termsConditions: get(invoice, 'termsConditions', null),
      items: transformItems(invoice),
      payments: transformPayments(invoice),
      numberTemplate: !!get(invoice, 'numberTemplate.id', null)
        ? { id: get(invoice, 'numberTemplate.id', null) }
        : null,
      total: get(invoice, 'total', null),
      subtotal: get(invoice, 'subtotal', null),
      totalReceived: get(invoice, 'totalReceived', null),
      cashReturned: get(invoice, 'cashReturned', null),
      type: get(invoice, 'type', null),
      anotation: get(invoice, 'anotation', null),
      client: get(invoice, 'client.id', null),
      costCenter: !!get(invoice, 'costCenter', null)
        ? get(invoice, 'costCenter', null)
        : null,
      priceList: get(invoice, 'priceList.id', 0),
      warehouse: !!get(invoice, 'warehouse', null)
        ? get(invoice, 'warehouse', null)
        : null,
      seller: !!get(invoice, 'seller.id', null)
        ? { id: get(invoice, 'seller.id', null) }
        : null,
      currency: {
        code: !!get(invoice, 'currency.exchangeRate', null)
          ? get(invoice, 'currency.code', null)
          : null,
        exchangeRate: get(invoice, 'currency.exchangeRate', null)
          ? get(invoice, 'currency.exchangeRate')
          : null,
      },
      externalPayment: {
        id: get(invoice, 'externalPayment.id', null),
        provider: ['nequiPush', 'bancolombiaQR'].includes(
          get(invoice, 'externalPayment.provider', null)
        )
          ? 'wompi'
          : get(invoice, 'externalPayment.provider', null),
      },
      deliveryTerm: get(invoice, 'deliveryTerm', null),
      stamp: {
        generateStamp: get(invoice, 'stamp.generateStamp', false),
      },
      operationType: get(invoice, 'operationType', null),
      saleCondition: get(invoice, 'saleCondition', null),
      saleConcept: get(invoice, 'saleConcept', null),
      startDateService: get(invoice, 'startDateService', null),
      endDateService: get(invoice, 'endDateService', null),
      paymentForm: !!get(invoice, 'paymentForm', null)
        ? JSON.stringify(get(invoice, 'paymentForm', null))
        : null,
      paymentMethod: get(invoice, 'paymentMethod', null),
      paymentType: get(invoice, 'paymentType', null),
      incomeType: get(invoice, 'incomeType', null),
      saleType: get(invoice, 'saleType', null),
      originApp: get(invoice, 'originApp', null),
      additionalCharges,
      provisionalTicket: get(invoice, 'provisionalTicket', null),
    };

    if (!result.paymentType) delete result.paymentType;

    if (get(company, 'country') === COUNTRIES.MEXICO && get(company, 'identification').length !== 13) {
      delete result.regime
    }
  
    if (!result.regime)
      delete result.regime

    if (!result.additionalCharges || !result.additionalCharges.length)
      delete result.additionalCharges;

    return result;
  }
}

import React, { useMemo } from 'react';
import { I18n } from '@aws-amplify/core';
import { capitalize } from 'lodash';

import {
  MemoizedField,
  renderField,
} from '../../../../../components/forms/fields/V2';
import {
  Button,
  Divider,
  Grid,
  Icon,
  SimpleTable,
  Tooltip,
  Typography,
} from '@alegradev/smile-ui-react';
import { useSelector } from 'react-redux';
import { banksSelector } from '../../../../../reducers/banks';
import { IconInfoCircle, IconPlus } from '@tabler/icons-react';
import useDecimalsVersionsGroup from '../../../../../hooks/useDecimalsVersionsGroup/hook';
import { decimalSeparator } from '../../../../../selectors/company';

function ProviderPayment({ fields, values, form }) {
  const { Wrapper, Row, Col } = Grid;
  const bankAccounts = useSelector(banksSelector.selectAll);
  const { isDecimalActive } = useDecimalsVersionsGroup();
  const separator = useSelector(decimalSeparator);

  const columns = useMemo(
    () => [
      {
        Header: capitalize(I18n.get('bankAccount', 'Cuenta bancaria')),
        accessor: 'bankAccount',
        width: '30%',
        Cell: ({ row }) => {
          return (
            <MemoizedField
              name={`${fields.name}[${row.index}].bankAccount`}
              render={renderField}
              options={bankAccounts}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option}
              fieldType='rselect'
              placeholder={I18n.get('select', 'Seleccionar')}
            />
          );
        },
      },
      {
        Header: capitalize(I18n.get('value', 'Valor')),
        accessor: 'bankAccount.amount',
        width: '10%',
        Cell: ({ row }) => (
          <MemoizedField
            name={`${fields.name}[${row.index}].bankAccount.amount`}
            render={renderField}
            fieldType='decimal'
            onDecimalsError={(status) =>
              form?.change('decimalsError', {
                ...values?.decimalsError,
                exchangeRate: status,
              })
            }
            placeholder={I18n.get('value', 'Valor')}
            separator={separator}
            decimals={isDecimalActive ? 8 : null}
          />
        ),
      },
    ],
    [fields.name]
  );

  if (!values.bankAccounts)
    return (
      <Wrapper>
        <Button
          type='button'
          leftIcon={IconPlus}
          onClick={() => fields.push({})}
          text={I18n.get('addPayment', 'Agregar pago')}
        />
      </Wrapper>
    );

  return (
    <Wrapper direction={{ lg: 'column' }} gap={10}>
      <Wrapper>
        <Typography
          type='label-2'
          text={I18n.get('providerDonePayment', 'Pago realizado al proveedor')}
        />
        <Tooltip
          overlay={I18n.get(
            'providerDonePaymentTooltip',
            'Se registrará un comprobante de agreso con el pago de esta compra.'
          )}
        >
          <Icon icon={IconInfoCircle} size='small' type='primary' />
        </Tooltip>
      </Wrapper>
      <Divider margin={0} />

      <Row>
        <Col xs={6} sm={12}>
          <SimpleTable columns={columns} data={fields?.value || []} />
        </Col>
      </Row>
    </Wrapper>
  );
}

export default ProviderPayment;

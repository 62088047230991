import { Typography } from '@alegradev/smile-ui-react';
import '../../_invoices.scss';
import { I18n } from 'aws-amplify';
import { useInvoiceDetail } from '../context';
import BigNumber from 'bignumber.js';
import { useFormat } from '../../../../hooks/useFormat';

const PaymentSummary = () => {
  const { data } = useInvoiceDetail();
  const { fmt, decimal } = useFormat();

  return (
    <div className='mt-4 d-flex justify-content-end'>
      <div className='payment-summary-container'>
        <div className='header-summary'>
          <Typography
            text={I18n.get('totalReceived', 'Total recibido')}
            type='label-3'
          />
        </div>
        <div className='content-summary'>
          <Typography
            type='body-3-regular'
            text={new BigNumber(data?.totalReceived).toFormat(decimal, fmt)}
          />
        </div>
        <div className='header-summary'>
          <Typography text={I18n.get('change', 'Cambio')} type='label-3' />
        </div>
        <div className='content-summary'>
          <Typography
            type='body-3-regular'
            text={new BigNumber(data?.cashReturned).toFormat(decimal, fmt)}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;

import {
  Button,
  Icon,
  Popover,
  TableCellRegular,
  Tooltip,
  Typography,
} from '@alegradev/smile-ui-react';
import { IconArrowUpRight, IconCheck, IconCopy } from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import dayjs from 'dayjs';
import { get } from 'lodash';
import { handleCopy, VISIBLE_MESSAGE } from '../utils';

const InvoiceDetailGlobalInvoicesTableColumns = ({
  displayGlobalInvoiceTooltip,
  setDisplayGlobalInvoiceTooltip,
}) => {
  return [
    {
      Header: I18n.get('', 'Folio'),
      accessor: 'numberTemplate.number',
      width: '20%',
      Cell: ({ row }) => {
        const { original } = row;
        const idLocal = get(original, 'idLocal', null);
        const id = get(original, 'id', null);

        const getUrl = () => {
          if (idLocal) {
            return `/global-invoices/invoice/${idLocal}/detail`;
          }
          return `${process.env.REACT_APP_ALEGRA}global-invoice/view/id/${id}`;
        };

        const urlToRedirect = getUrl();

        return (
          <a
            className='invoices-global-invoice-folio d-flex align-items-center'
            href={urlToRedirect}
            target='_blank'
            rel='noopener noreferrer'
          >
            <Typography
              type='caption-bold'
              text={'#' + get(original, 'numberTemplate.number')}
            />
            <Icon icon={IconArrowUpRight} color='#15803D' size='extraSmall' />
          </a>
        );
      },
    },
    {
      Header: I18n.get('date', 'Fecha'),
      accessor: 'date',
      width: '15%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <TableCellRegular
            value={dayjs(get(original, 'date', null)).format('DD/MM/YYYY')}
          />
        );
      },
    },
    {
      Header: I18n.get('', 'Estado de timbre'),
      accessor: 'status',
      width: '20%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='d-flex align-items-center gap-2'>
            <Icon icon={IconCheck} color='#16A34A' size='small' />
            <Typography type='caption-regular' text='Timbrado' />
          </div>
        );
      },
    },
    {
      Header: I18n.get('', 'Folio fiscal'),
      accessor: 'stamp.uuid',
      alignment: 'center',
      width: '45%',
      Cell: ({ row }) => {
        const { original } = row;
        return (
          <div className='d-flex align-items-center gap-2'>
            <Typography
              type='body-3-regular'
              text={get(original, 'stamp.uuid', '')}
              variant='tertiary'
              maxLength={18}
            />
            <Popover
              title={
                <Typography
                  extraClassName='px-2 py-2'
                  type='body-3-bold'
                  variant='inverse'
                  text={I18n.get('', 'Folio fiscal copiado')}
                />
              }
              placement='top'
              defaultVisible={displayGlobalInvoiceTooltip}
              visible={displayGlobalInvoiceTooltip}
              small
              full={false}
              width={160}
            >
              <Button
                type='button'
                variant='reversed'
                onlyIcon={IconCopy}
                size='small'
                onClick={() =>
                  handleCopy({
                    value: get(original, 'stamp.uuid'),
                    setDisplayGlobalInvoiceTooltip,
                  })
                }
              />
            </Popover>
          </div>
        );
      },
    },
  ];
};

export default InvoiceDetailGlobalInvoicesTableColumns;

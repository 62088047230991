import { I18n } from 'aws-amplify';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';
import { toLower } from 'lodash';

export const RepublicaDominicanaInvoiceTextStrategy = {
  getModalTitle: (numeration) => {
    if (numeration?.isElectronic) {
      return toLower(
        I18n.get(
          `invoiceModalTitle.${numeration?.documentType}.electronic`,
          'factura'
        )
      );
    }
    return BaseInvoiceTextStrategy.getModalTitle(numeration);
  },
  getSubmitButtonName: ({ values, numeration, isElectronic }) => {
    return BaseInvoiceTextStrategy.getSubmitButtonName({
      values,
      numeration,
      isElectronic,
    });
  },
  getNewSubmitButtonName: ({ numeration }) => {
    if (numeration?.isElectronic) {
      return I18n.get('emit', 'Emitir');
    }
    return BaseInvoiceTextStrategy.getNewSubmitButtonName({ numeration });
  },
};

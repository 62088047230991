import {
  Grid,
  Input,
  InputDecimal,
  Space,
  Tabs,
  Textarea,
  Toast,
  Typography,
} from '@alegradev/smile-ui-react';
import { IconCoin, IconPercentage } from '@tabler/icons-react';
import { I18n } from 'aws-amplify';
import { useGlobalDiscounts } from '../GlobalDiscountsProvider';
import { GLOBAL_DISCOUNTS_TYPES } from '../utils/constants';
import { useSelector } from 'react-redux';
import {
  country as countrySelector,
  decimalSeparator,
} from '../../../../../selectors/company';
import { COUNTRIES } from '../../../../../utils/enums/countries';
import { capitalize } from 'lodash';

const GlobalDiscountsHeader = () => {
  const { Wrapper } = Grid;
  const { values, toggleValues, toggleError } = useGlobalDiscounts();
  const country = useSelector(countrySelector);
  const separator = useSelector(decimalSeparator);

  const handleChangeValue = (value, key) => {
    if (values?.type === GLOBAL_DISCOUNTS_TYPES.PERCENTAGE && value > 99.99) {
      toggleValues('value', 99);
      return;
    }

    toggleValues(key, value);
  };

  const handleChangeTab = (id) => {
    toggleValues('type', id);
    toggleValues('value', '');
  };

  return (
    <Wrapper
      backgroundColor='white'
      direction={{ lg: 'column' }}
      padding={{ lg: 'md' }}
    >
      {/* <Tabs
        onTabChanged={(id) => handleChangeTab(id)}
        defaultTab={values?.type}
        simple
        items={[
          {
            id: GLOBAL_DISCOUNTS_TYPES.PERCENTAGE,
            title: I18n.get('percentage', 'Porcentaje'),
          },
          {
            id: GLOBAL_DISCOUNTS_TYPES.AMOUNT,
            title: I18n.get('value', 'Valor'),
          },
        ]}
      /> */}

      <Typography
        type='body-2-bold'
        text={
          values?.type === GLOBAL_DISCOUNTS_TYPES.PERCENTAGE
            ? I18n.get('percentage', 'Porcentaje')
            : I18n.get('value', 'Valor')
        }
      />

      <Space height={4} />

      <InputDecimal
        decimals={1}
        size='medium'
        onChange={(value) => handleChangeValue(value, 'value')}
        defaultValue={values?.value}
        onDecimalsError={(status) => toggleError(status)}
        separator={separator}
        min={0}
        max={values?.type === GLOBAL_DISCOUNTS_TYPES.PERCENTAGE ? 100 : null}
        trailingIcon={
          values?.type === GLOBAL_DISCOUNTS_TYPES.PERCENTAGE
            ? IconPercentage
            : IconCoin
        }
      />

      {country === COUNTRIES.COSTA_RICA && (
        <>
          <Space height={10} />
          <Textarea
            onChange={(value) => toggleValues('nature', value.target.value)}
            defaultValue={values?.nature}
            required
            size='medium'
            label={capitalize(
              I18n.get('discountNature', 'naturaleza del descuento')
            )}
          />
        </>
      )}
    </Wrapper>
  );
};

export default GlobalDiscountsHeader;

import { useState, useEffect, useCallback, useRef } from 'react';
import { throttle } from 'lodash';
import db from '../../database/database';
import { getAll } from '../../database/itemsDB';

const useItemsFromLocalDB = () => {
  const [updateItemsDBCount, setUpdateCount] = useState(0);
  const isFetchingRef = useRef(false);
  const listenerRef = useRef(null);

  const incrementCounter = useCallback(() => {
    if (isFetchingRef.current) {
      return;
    }

    isFetchingRef.current = true;
    try {
      setUpdateCount((prevCount) => prevCount + 1);
    } catch (error) {
      console.error('Error incrementing update counter:', error);
    } finally {
      isFetchingRef.current = false;
    }
  }, []);

  const throttledIncrement = useCallback(
    throttle(() => {
      incrementCounter();
    }, 2000),
    [incrementCounter]
  );

  useEffect(() => {
    if (db?.items?.hook) {
      listenerRef.current = throttledIncrement;
      db.items.hook('updating', listenerRef.current);
    }

    return () => {
      if (listenerRef.current && db?.items?.hook) {
        db.items.hook('updating').unsubscribe(listenerRef.current);
        listenerRef.current = null;
      }
    };
  }, [throttledIncrement]);

  return { updateItemsDBCount };
};

export default useItemsFromLocalDB;

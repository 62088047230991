import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { graphqlOperation } from '@aws-amplify/api';
import { get, capitalize } from 'lodash';

import * as mutations from '../../../graphql/mutations';
import { formError } from '../../../utils/errors';
import { openModal } from '../../../reducers/modals';
import {
  country as countrySelector,
  email as companyEmail,
  identificationSelector,
  currency,
} from '../../../selectors/company';
import { validate } from '../../forms/sendInvoiceEmail/utils';
import { sendGTMEvent, sendNewGTMEvent } from '../../../reducers/company';

import Body from '../../forms/sendInvoiceEmail/SendInvoiceEmail';
import { APIGraphqlSelector } from '../../../selectors/app';
import { Typography, useDeviceType } from '@alegradev/smile-ui-react';
import { COUNTRIES } from '../../../utils/enums/countries';

const NewBodyEmailSaved = () => {
  const dispatch = useDispatch();
  const invoiceCreated = useSelector((state) =>
    get(state, 'print.invoice', null)
  );
  const email = useSelector(companyEmail);
  const identification = useSelector(identificationSelector);
  const country = useSelector(countrySelector);
  const companyCurrency = useSelector(currency);
  const APIGraphql = useSelector(APIGraphqlSelector);
  const deviceType = useDeviceType();

  const getDocumentType = () => {
    if (country === COUNTRIES.SPAIN) {
      if (get(invoiceCreated, 'numberTemplate.documentType') === 'saleTicket') {
        return I18n.get('invoiceModalTitle.saleTicket', 'Ticket');
      }
    }
    return I18n.get(
      get(invoiceCreated, 'numberTemplate.documentType'),
      'invoice'
    );
  };

  const submit = async (values) => {
    try {
      await APIGraphql(
        graphqlOperation(mutations.sendInvoice, {
          id: invoiceCreated.id,
          emails: values.email,
        })
      );

      dispatch(
        sendGTMEvent('invoice-email-sent', {
          isElectronicInvoice: get(
            invoiceCreated,
            'numberTemplate.isElectronic',
            false
          ),
          customerPaymentMethod: get(invoiceCreated, 'paymentMethod'),
          currency: get(
            invoiceCreated,
            'currency.code',
            get(companyCurrency, 'code')
          ),
          invoiceTotal: get(invoiceCreated, 'total'),
          totalItems: get(invoiceCreated, 'items').length,
        })
      );
      dispatch(
        sendNewGTMEvent('pos-invoice-shared', {
          medium: 'mail',
        })
      );
    } catch (error) {
      if (!email || !identification) {
        dispatch(openModal({ modal: 'companyEmail' }));
        return formError(
          null,
          I18n.get(
            'youNeedToConfigYourCompanyEmail',
            'Primero necesitas configurar el correo electrónico de tu negocio. Luego reintenta'
          )
        );
      }
      return formError(
        error,
        I18n.get(
          'invoiceSendByEmailError',
          'ocurrió un error al intentar enviar la venta por correo'
        )
      );
    }
  };

  const getSendEmailTitle = () => {
    return capitalize(I18n.get('send', 'Enviar') + ' ' + getDocumentType());
  };

  return (
    <div
      className={`px-sm-5 pb-5 d-flex flex-column ${deviceType !== 'mobile' ? 'w-90 m-auto' : 'w-100 my-5'} `}
    >
      <Typography
        extraClassName='mb-2'
        type='label-2'
        variant={
          !!invoiceCreated && !!invoiceCreated.timestamp
            ? 'disabled'
            : 'secondary'
        }
        text={capitalize(getSendEmailTitle())}
      />

      <Form
        onSubmit={submit}
        validate={(values) => validate(values)}
        initialValues={{
          email: get(invoiceCreated, 'client.email', null),
        }}
      >
        {({ handleSubmit, submitting, submitError, submitSucceeded }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Body
              invalid={!!invoiceCreated && !!invoiceCreated.timestamp}
              submitting={submitting}
            />

            {!!submitError && !!email && !!identification && (
              <p className='h4 text-danger text-capitalize-first'>
                {submitError}
              </p>
            )}

            {submitSucceeded && (
              <p className='h4 text-primary text-capitalize-first'>
                {I18n.get(
                  'invoiceSendByEmailSuccessfully',
                  'la venta ha sido exitosamente enviada por correo'
                )}
              </p>
            )}
          </form>
        )}
      </Form>
    </div>
  );
};

export default NewBodyEmailSaved;

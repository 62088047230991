import { get } from 'lodash';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';
import { clientDataAreEquals } from '../../utils/invoice/activeInvoice';
import {
  INVOICE_DOCUMENT_TYPES,
  INVOICE_PAYMENT_METHODS,
} from '../../components/modals/invoiceRefactored/utils/constants';

export class ColombiaInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * Calculates the payment method according to the country and the received amount.
   *
   * If the received amount is less or equal to zero, returns 'INSTRUMENT_NOT_DEFINED'
   * if the invoice is electronic, or null if it's not electronic.
   *
   * Otherwise, maps the greater payment method as follows:
   * - cash: 'CASH'
   * - debit-card: 'DEBIT_CARD'
   * - credit-card: 'CREDIT_CARD'
   * - transfer: 'DEBIT_TRANSFER'
   * - instrument-not-defined: 'INSTRUMENT_NOT_DEFINED'
   *
   * If the greater payment method is not in the mapping, it defaults to the
   * parent class implementation.
   *
   * @param {string} greaterPaymentMethod The greater payment method.
   * @param {string} country The country.
   * @param {BigNumber} totalReceived The total received.
   * @param {boolean} isElectronicPOSDocument Whether the invoice is an electronic
   *     POS document.
   * @return {string} The payment method.
   */
  calculatePaymentMethod({
    greaterPaymentMethod,
    country,
    totalReceived,
    isElectronicPOSDocument,
  }) {
    if (totalReceived <= 0) {
      return isElectronicPOSDocument ? 'INSTRUMENT_NOT_DEFINED' : null;
    }

    const colombiaPaymentMethods = {
      cash: 'CASH',
      'debit-card': 'DEBIT_CARD',
      'credit-card': 'CREDIT_CARD',
      transfer: 'DEBIT_TRANSFER',
      'instrument-not-defined': 'INSTRUMENT_NOT_DEFINED',
    };

    if (colombiaPaymentMethods[greaterPaymentMethod]) {
      return colombiaPaymentMethods[greaterPaymentMethod];
    }

    return super.calculatePaymentMethod({
      greaterPaymentMethod,
      country,
      totalReceived,
      isElectronicPOSDocument,
    });
  }
  /**
   * This function calculates the payments for a Colombian invoice.
   *
   * @param {Object} params - The parameters for calculating payments.
   * @param {Object} params.payments - An object representing different payment types and their amounts.
   * @param {Object} params.banks - An object mapping payment types to bank accounts.
   * @param {Object} params.today - A dayjs object representing the current date.
   * @param {BigNumber} params.total - The total amount to be received.
   * @param {BigNumber} params.subtotal - The subtotal before any additional charges.
   * @param {string} params.country - The country where the invoice is being issued.
   * @param {number} params.decimal - The number of decimal places to consider for currency calculations.
   * @param {Object} params.cashReceiptNumberTemplate - Template for the cash receipt numbering.
   * @param {Object} params.currency - The currency object which may include exchange rate.
   * @param {Object} params.numberTemplate - Template for the invoice numbering.
   *
   * @returns {Object} An object containing the total, subtotal, total received, cash returned, processed payments,
   *                   original payment method, and final payment method.
   */
  calculatePayments({
    payments,
    banks,
    today,
    total,
    subtotal,
    country,
    decimal,
    cashReceiptNumberTemplate,
    currency,
    numberTemplate,
  }) {
    const response = super.calculatePayments({
      payments,
      banks,
      today,
      total,
      subtotal,
      country,
      decimal,
      cashReceiptNumberTemplate,
      currency,
      numberTemplate,
    });
    return {
      ...response,
      paymentMethod: this.calculatePaymentMethod({
        greaterPaymentMethod: response.originalPaymentMethod,
        country,
        totalReceived: response.totalReceived,
        isElectronicPOSDocument:
          get(numberTemplate, 'isElectronic') &&
          get(numberTemplate, 'documentType') ===
            INVOICE_DOCUMENT_TYPES.SALE_TICKET,
      }),
    };
  }
  /**
   * Transforms an invoice to an electronic invoice format.
   *
   * @param {Object} invoice - The invoice to transform.
   * @param {Object} numberTemplate - The template for the invoice numbering.
   * @param {Object} today - A dayjs object representing the current date.
   *
   * @returns {Object} The transformed invoice with the payment form and stamp.
   */
  electronicInvoice({ invoice, numberTemplate, today }) {
    const getPaymentForm = () => {
      if (
        get(numberTemplate, 'documentType') ===
        INVOICE_DOCUMENT_TYPES.SALE_TICKET
      ) {
        return 'CASH';
      }

      return invoice.totalReceived < invoice.total ? 'CREDIT' : 'CASH';
    };
    return {
      ...invoice,
      paymentForm: getPaymentForm(),
      stamp: {
        generateStamp: !!numberTemplate ? !!numberTemplate.isElectronic : false,
      },
      dueDate: !!get(invoice, 'paymentTerm.days')
        ? today
            .add(get(invoice, 'client.term.days', 0), 'day')
            .format('YYYY-MM-DD')
        : invoice.dueDate,
    };
  }
  /**
   * Transforms an invoice to a non-electronic invoice format.
   *
   * @param {Object} invoice - The invoice to transform.
   * @param {Object} numberTemplate - The template for the invoice numbering.
   *
   * @returns {Object} The transformed invoice with the payment form and without the stamp.
   */
  nonElectronicInvoice({ invoice, numberTemplate }) {
    if (
      get(numberTemplate, 'documentType') ===
        INVOICE_DOCUMENT_TYPES.SALE_TICKET ||
      get(numberTemplate, 'documentType') === INVOICE_DOCUMENT_TYPES.INVOICE
    ) {
      return {
        ...invoice,
        paymentForm: invoice.totalReceived > 0 ? 'CASH' : 'CREDIT',
      };
    }

    return {
      ...invoice,
    };
  }
  /**
   * Modifies the invoice to create an electronic or non-electronic invoice for Colombia.
   *
   * @param {Object} params - Params object.
   * @param {Object} params.invoice - The invoice object.
   * @param {Object} params.state - The global state object.
   * @param {boolean} params.isElectronic - Indicates if the invoice is electronic.
   * @param {Object} params.numberTemplate - The number template object.
   * @param {Date} params.today - The current date.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    idPaymentMethodLocal,
  }) {
    const modifiedInvoice = {
      ...invoice,
      idPaymentMethodLocal,
    };
    if (isElectronic) {
      return this.electronicInvoice({
        invoice: modifiedInvoice,
        numberTemplate,
        today,
      });
    }
    return this.nonElectronicInvoice({
      invoice: modifiedInvoice,
      numberTemplate,
    });
  }
  /**
   * Returns the default client information for Colombia.
   *
   * @param {Object} address - The address object for the client.
   * @param {boolean} isElectronic - Indicates if the invoice is electronic.
   * @returns {Object} The default client object, which includes default name,
   *                   identification, kind of person, regime, address, type,
   *                   and a flag to ignore repeated clients.
   */
  defaultClient({ address, isElectronic }) {
    return {
      nameObject: { firstName: 'Consumidor', lastName: 'final' },
      identificationObject: { type: 'CC', number: '222222222222' },
      kindOfPerson: 'PERSON_ENTITY',
      regime: 'SIMPLIFIED_REGIME',
      address,
      type: ['client'],
      ignoreRepeated: true,
    };
  }

  /**
   * Checks if the given client is the default client for Colombia.
   *
   * @param {Object} client - The client object to compare against the default.
   * @returns {boolean} - True if the client matches the default client criteria, otherwise false.
   */
  isDefaultClient({ client }) {
    const defaultC = this.defaultClient();
    if (!!get(client, 'nameObject'))
      return (
        clientDataAreEquals(
          defaultC.nameObject.firstName,
          client.nameObject.firstName
        ) &&
        clientDataAreEquals(
          defaultC.nameObject.lastName,
          client.nameObject.lastName
        ) &&
        clientDataAreEquals(
          defaultC.identificationObject.type,
          client.identificationObject.type
        ) &&
        clientDataAreEquals(
          defaultC.identificationObject.number,
          client.identificationObject.number
        )
      );
    return (
      clientDataAreEquals(
        defaultC.nameObject.firstName,
        client.name.firstName
      ) &&
      clientDataAreEquals(defaultC.nameObject.lastName, client.name.lastName) &&
      clientDataAreEquals(
        defaultC.identificationObject.type,
        client.identificationObject.type
      ) &&
      clientDataAreEquals(
        defaultC.identificationObject.number,
        client.identificationObject.number
      )
    );
  }

  transform(invoice) {
    let result = super.transform(invoice);
    const payments = result?.payments || [];

    result = {
      ...result,
      payments: payments.map((payment) => ({
        ...payment,
        idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
      })),
    };

    return result;
  }
}

import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { I18n } from '@aws-amplify/core';
import { capitalize, isArray, isNumber, get } from 'lodash';
import { useSelector } from 'react-redux'
import { BigNumber } from 'bignumber.js'

import { decimalPrecision, multitax } from '../../../selectors/company'
import { activeTaxes } from '../../../selectors/taxes'
import { renderField, renderSelect } from '../fields/V0/Fields';

const Price = props => {
  const [lastModified, setLastModified] = useState('salePrice');
  const decimal = useSelector(decimalPrecision);
  const taxes = useSelector(activeTaxes)
  const isMulti = useSelector(multitax)

  const calculateTotalPrice = (taxPercent) => {
    const salePrice = typeof get(props, 'values.salePrice', '0') === 'number'
      ? String(get(props, 'values.salePrice', '0'))
      : get(props, 'values.salePrice', '0');
      
    if (!!salePrice) {
      const price = new BigNumber(100).plus(new BigNumber(taxPercent)).dividedBy(100).multipliedBy(salePrice)
        .decimalPlaces(decimal).toNumber()
      if (isNumber(price))
        props.form.change('price', '' + price);
    }
  }

  const calculateSalePrice = (taxPercent) => {
    const price = typeof get(props, 'values.price', '0') === 'number'
      ? String(get(props, 'values.price', '0')) 
      : get(props, 'values.price', '0');

    if (!!price) {
      const salePrice = new BigNumber(price).multipliedBy(100).dividedBy(new BigNumber(taxPercent).plus(100))
        .decimalPlaces(decimal).toNumber()
      if (isNumber(salePrice))
        props.form.change('salePrice', '' + salePrice);
    }
  }

  const calculatePriceValues = (lastTouched = null) => {
    let taxes = !!get(props, 'values.tax', null)
      ? get(props, 'values.tax', null) : [];
    taxes = !isArray(taxes) ? [taxes] : taxes;

    const totalPercent = taxes.reduce((prevTax, tax) => prevTax + +get(tax, 'percentage'), 0)

    if (!!lastTouched) {
      if (lastTouched === 'price')
        calculateSalePrice(totalPercent)
      else
        calculateTotalPrice(totalPercent)
      setLastModified(lastTouched)
    } else {
      if (lastModified === 'price')
        calculateSalePrice(totalPercent)
      else
        calculateTotalPrice(totalPercent)
    }
  }

  return (
    <>
      <Field
        name="salePrice"
        component={renderField}
        className="col-sm-7"
        type="number"
        required
        label={capitalize(I18n.get('priceBeforeTaxes', 'precio sin impuestos'))}
        onBlur={() => calculatePriceValues('salePrice')}
      />

      <Field
        name="tax"
        component={renderSelect}
        showFavoriteIcon={false}
        className="col-sm-5"
        options={taxes}
        getOptionLabel={option => !!option.customName ? option.customName : `${option.name} (${option.percentage}%)`}
        getOptionValue={option => option.id}
        label={capitalize(I18n.get('taxes', 'impuestos'))}
        showClearIndicator
        isMulti={isMulti}
        onInputChange={() => calculatePriceValues()}
        helpTooltip={isMulti
          ? (
            I18n.get('taxesHelp', 'Si tu producto tiene varios impuestos, puedes seleccionarlos dando clic sobre el nombre de cada uno en el listado desplegable.')
          )
          : null
        }
      />

      <Field
        name="price"
        component={renderField}
        type="number"
        className="col-12"
        required
        label={capitalize(I18n.get('finalPrice', 'precio final'))}
        help={capitalize(I18n.get('finalPriceHelp', 'precio del producto sin aplicar impuestos'))}
        onBlur={() => calculatePriceValues('price')}
      />
    </>
  )
}

export default Price;

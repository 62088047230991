import { get } from 'lodash';
import { BaseInvoiceStrategy } from './BaseInvoiceStrategy';

export class PanamaInvoiceStrategy extends BaseInvoiceStrategy {
  /**
   * This function is used to modify the invoice for electronic invoicing
   * in Panama. It adds the sale condition and the stamp to the invoice.
   * The sale condition is 'CASH' if the payment method is 'debit-card' or
   * 'credit-card', otherwise it is 'CREDIT'. The stamp is set to true.
   * The type, operationType, saleType and originApp are set to 'INTERNAL_OPERATION',
   * 'SALE', 'ORDER' and 'POS' respectively.
   * @param {Object} invoice The invoice
   * @param {Object} numberTemplate The number template
   * @returns {Object} The modified invoice
   */
  electronicInvoice({ invoice, numberTemplate }) {
    const panamaPaymentMethods = {
      'debit-card': 'DEBIT_CARD',
      'credit-card': 'CREDIT_CARD',
    };

    const saleCondition =
      panamaPaymentMethods[invoice?.paymentMethod] || invoice.totalReceived > 0
        ? 'CASH'
        : 'CREDIT';
    return {
      ...invoice,
      saleCondition,
      stamp: {
        generateStamp: true,
      },
      type: 'INTERNAL_OPERATION',
      operationType: 'SALE',
      saleType: 'ORDER',
      originApp: 'POS',
    };
  }
  /**
   * Modify the invoice to create an electronic or non-electronic invoice for Panama.
   *
   * @param {Object} invoice - The invoice object.
   * @param {Object} state - The global state object.
   * @param {boolean} isElectronic - The invoice is electronic or not.
   * @param {Object} numberTemplate - The number template object.
   * @param {Date} today - The current date.
   * @returns {Object} - The modified invoice.
   */
  modifyPreparedInvoice({
    invoice,
    state,
    isElectronic,
    numberTemplate,
    today,
    idPaymentMethodLocal,
  }) {
    const modifiedInvoice = {
      ...invoice,
      dueDate: today
        .add(get(invoice, 'client.term.days', 0), 'day')
        .format('YYYY-MM-DD'),
      idPaymentMethodLocal,
    };

    delete modifiedInvoice.type;
    delete modifiedInvoice.operationType;
    delete modifiedInvoice.paymentTerm;
    delete modifiedInvoice.deliveryTerm;

    if (isElectronic) {
      return this.electronicInvoice({
        invoice: modifiedInvoice,
        numberTemplate,
      });
    }

    return modifiedInvoice;
  }
  /**
   * Default client for Panama
   *
   * @param {Object} params - Object with `address` and `isElectronic` properties
   * @param {Object} params.address - Client address
   * @param {boolean} params.isElectronic - Is the invoice electronic or not
   * @returns {Object} - The default client
   */
  defaultClient({ address, isElectronic }) {
    return {
      name: 'Consumidor final',
      address,
      thirdType: 'FINAL_CONSUMER',
      kindOfPerson: null,
      type: ['client'],
      ignoreRepeated: true,
    };
  }
  transform(invoice) {
    let result = super.transform(invoice);
    const payments = result?.payments || [];

    result = {
      ...result,
      payments: payments.map((payment) => ({
        ...payment,
        idPaymentMethodLocal: get(invoice, 'idPaymentMethodLocal', null),
      })),
    };

    return result;
  }
}

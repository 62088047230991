import { I18n } from 'aws-amplify';
import { INVOICE_DOCUMENT_TYPES } from '../utils/constants';
import { BaseInvoiceTextStrategy } from './BaseInvoiceTextStrategy';

export const ArgentinaInvoiceTextStrategy = {
  getModalTitle: (numeration) => {
    return BaseInvoiceTextStrategy.getModalTitle(numeration);
  },
  getSubmitButtonName: ({ values, numeration, isElectronic }) => {
    if (get(numeration, 'documentType') !== INVOICE_DOCUMENT_TYPES.INVOICE) {
      return I18n.get('sell', 'Vender');
    }

    return BaseInvoiceTextStrategy.getSubmitButtonName({
      values,
      numeration,
      isElectronic,
    });
  },
  getNewSubmitButtonName: ({ numeration }) => {
    if (numeration?.isElectronic) {
      return I18n.get('emit', 'Emitir');
    }
    return BaseInvoiceTextStrategy.getNewSubmitButtonName({ numeration });
  },
};

import { createContext, useContext, useEffect, useState } from 'react';

const InvoiceDetailContext = createContext({
  data: {},
  isEditable: false,
  setIsEditable: () => {},
  refetch: () => null,
  displayGlobalInvoiceTooltip: false,
  setDisplayGlobalInvoiceTooltip: () => {},
});

export function useInvoiceDetail() {
  return useContext(InvoiceDetailContext);
}

function InvoiceDetailProvider({ children, value }) {
  const [isEditable, setIsEditable] = useState(false);
  const [displayGlobalInvoiceTooltip, setDisplayGlobalInvoiceTooltip] =
    useState(false);
  useEffect(() => {
    if (displayGlobalInvoiceTooltip) {
      setTimeout(() => {
        setDisplayGlobalInvoiceTooltip(false);
      }, 3000);
    }
  }, [displayGlobalInvoiceTooltip]);

  return (
    <InvoiceDetailContext.Provider
      value={{
        isEditable,
        setIsEditable,
        data: value,
        displayGlobalInvoiceTooltip,
        setDisplayGlobalInvoiceTooltip,
      }}
    >
      {children}
    </InvoiceDetailContext.Provider>
  );
}

export default InvoiceDetailProvider;

import { useCESModal } from '@alegradev/smile-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import {
  companySelector,
  country as countrySelector,
  membershipSelector,
  notificationsSelector,
} from '../../selectors/company';
import useNotification from '../useNotification/hook';
import {
  NEW_INVOICE_MODAL_USE,
  SURVEY_FINISHED_NOTIFICATION,
  SURVEY_FIRST_NOTIFICATION,
} from '../../components/forms/newInvoice/utils';
import dayjs from 'dayjs';
import { I18n } from 'aws-amplify';
import { sendNewGTMEvent } from '../../reducers/company';
import { get } from 'lodash';
import { COUNTRIES } from '../../utils/enums/countries';
import { showNewInvoiceModal } from '../../components/modals/newInvoice/utils';

const POR_NPS_RATED = 'pos-nps-rated';

const useNpsSurvey = () => {
  const { openCESModal } = useCESModal();
  const notifications = useSelector(notificationsSelector);
  const { viewed: viewedFinished, setViewed: setViewedFinished } =
    useNotification({
      notificationName: SURVEY_FINISHED_NOTIFICATION,
    });
  const { viewed: viewedFirstOmit, setViewed: setViewedFirstOmit } =
    useNotification({
      notificationName: SURVEY_FIRST_NOTIFICATION,
    });
  const membership = useSelector(membershipSelector);
  const { registryDate } = useSelector(companySelector);
  const country = useSelector(countrySelector);

  const dispatch = useDispatch();

  const sendSurvey = (data) => {
    setViewedFinished();
    dispatch(
      sendNewGTMEvent(POR_NPS_RATED, {
        qualifications: get(data, 'optionSelected', ''),
        comments: get(data, 'comments', ''),
      })
    );
  };

  const omitSurvey = () => {
    if (viewedFirstOmit) {
      setViewedFinished();
    } else {
      setViewedFirstOmit();
      dispatch(
        sendNewGTMEvent(POR_NPS_RATED, {
          qualifications: 'S',
        })
      );
    }
  };

  const openSurvey = () => {
    openCESModal({
      title: I18n.get('nps.title', 'Califica tu experiencia'),
      description: I18n.get(
        'nps.description',
        'Nos encantaría saber qué piensas. Evalúa qué tan fácil fue para ti usar las nuevas pantallas de venta'
      ),
      easyText: I18n.get('itWasEasy', 'Fue fácil'),
      hardText: I18n.get('itWasHard', 'Fue difícil'),
      neutralText: I18n.get('itWasNeutral', 'Fue neutral'),
      primaryAction: {
        text: I18n.get('send', 'Enviar'),
        onClick: (data) => sendSurvey(data),
      },
      secondaryAction: {
        text: I18n.get('skip', 'Omitir'),
        onClick: () => omitSurvey(),
      },
      commentsLabel: I18n.get(
        'nps.comments.label',
        '¿Podrías decirnos por qué?'
      ),
      commentsPlaceholder: I18n.get(
        'nps.comments.placeholder',
        'Déjanos tu comentario...'
      ),
    });
  };

  const getNotificationByType = (type) =>
    notifications.find((item) => item[type] !== undefined);

  const COUNTRIES_DEPLOY_DATE = {
    [COUNTRIES.USA]: '2024-10-24',
    [COUNTRIES.INTERNATIONAL]: '2024-10-24',
    [COUNTRIES.PERU]: '2025-01-14',
    [COUNTRIES.PANAMA]: '2025-01-14',
    [COUNTRIES.SPAIN]: '2025-01-20',
    [COUNTRIES.REPUBLICA_DOMINICANA]: '2025-01-20',
  };
  const verifyIfIsOldUserByVersion = () => {
    if (showNewInvoiceModal(country)) {
      return dayjs(registryDate).isSameOrBefore(COUNTRIES_DEPLOY_DATE[country]);
    }
    return false;
  };

  const handleNps = () => {
    if (
      !get(membership, 'pay', false) ||
      !verifyIfIsOldUserByVersion() ||
      window.Cypress
    )
      return;

    const newInvoiceModalNotification = getNotificationByType(
      NEW_INVOICE_MODAL_USE
    );

    if (!newInvoiceModalNotification || viewedFinished) return;

    const notificationDate = dayjs(
      newInvoiceModalNotification[NEW_INVOICE_MODAL_USE]
    );
    const now = dayjs();
    const twoHoursAgo = now.subtract(2, 'hour');
    const isOlderThanTwoHours = notificationDate.isBefore(twoHoursAgo);

    if (viewedFirstOmit) {
      const firstOmitNotification = getNotificationByType(
        SURVEY_FIRST_NOTIFICATION
      );
      if (!firstOmitNotification) return;

      const firstOmitDate = dayjs(
        firstOmitNotification[SURVEY_FIRST_NOTIFICATION]
      );
      const oneDayAgo = now.subtract(1, 'day');
      const isOlderThanOneDay = firstOmitDate.isBefore(oneDayAgo);

      if (isOlderThanOneDay) {
        openSurvey();
      }
    } else if (isOlderThanTwoHours) {
      openSurvey();
    }
  };

  return {
    handleNps,
  };
};

export default useNpsSurvey;

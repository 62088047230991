import { get } from 'lodash';

export const costaRicaTransformer = (values, _props, newValues) => {
  const identificationType = get(values, 'identification.type', null);
  const identificationNumber = get(values, 'identification.number', null);

  const foreignId = identificationType === 'PE';

  const addressObject = get(values, 'address', null);
  const combinedAddress = [
    get(addressObject, 'combined.value', ''),
    get(addressObject, 'combined.parentValue', ''),
    get(addressObject, 'combined.provinceValue', ''),
  ].join(', ');

  const neighborhood = get(values, 'address.neighborhood.value', null);
  const address = get(values, 'address.address', null);
  const splitAddress = combinedAddress?.split(', ');

  let newAddress = { address };
  if (!!combinedAddress && !foreignId) {
    newAddress = {
      ...newAddress,
      district: (combinedAddress && splitAddress[0]) || null,
      city: (combinedAddress && splitAddress[1]) || null,
      department: (combinedAddress && splitAddress[2]) || null,
      neighborhood,
    };
  }

  delete newValues.identification;
  return {
    ...newValues,
    identificationObject: {
      type: identificationType,
      number: identificationNumber,
    },
    economicActivity: get(values, 'economicActivity.code', null),
    address: newAddress,
    creditLimit: get(values, 'creditLimit', null),
  };
};

import React from 'react'
import { I18n } from '@aws-amplify/core';
import { useSelector } from 'react-redux';
import { BigNumber } from 'bignumber.js';
import Loader from 'react-content-loader';
import dayjs from 'dayjs';

import {
  timezone as tzSelector,
  dateFormat as dateFormatSelector,
} from '../../../selectors/company';
import {
  paymentsLoading,
  payments as paymentsSelector,
  baseAmount,
  startDate,
} from '../../../selectors/shifts';
import { get, replace } from 'lodash';
import { useFormat } from '../../../hooks/useFormat';

const textLoader = () => (
  <Loader title="" height={14} width={72}>
    <rect width='100%' height='100%' x="0" y="0" rx="5" ry="5" />
  </Loader>
)


const Details = () => {
  const loading = useSelector(paymentsLoading);
  const payments = useSelector(paymentsSelector);
  const base = useSelector(baseAmount);
  const start = useSelector(startDate);
  const timezone = useSelector(tzSelector);
  const dateFormat = useSelector(dateFormatSelector);
  const { decimal, fmt } = useFormat();

  const cash = new BigNumber(payments.cash);
  const debit = new BigNumber(payments.debit);
  const credit = new BigNumber(payments.credit);
  let transfer = new BigNumber(payments.transfer);
  const refunds = new BigNumber(payments.refunds);
  const inPayment = new BigNumber(payments.inPayment);
  const outPayment = new BigNumber(payments.outPayment);
  const totalsTipCash = new BigNumber(payments.tipsTotalCash);
  const totalsTipDebit = new BigNumber(payments.tipsTotalDebit);
  const totalsTipCredit = new BigNumber(payments.tipsTotalCredit);
  const totalsTipTransfer = new BigNumber(payments.tipsTotalTransfer);
  const customPaymentMethods = get(payments, 'customPaymentMethods', []);

  const totalsTipOther = totalsTipDebit.plus(totalsTipCredit).plus(totalsTipTransfer);

  let total = new BigNumber(base)
    .plus(cash)
    .plus(totalsTipCash)
    .plus(inPayment)
    .minus(refunds)
    .minus(outPayment)
  
  customPaymentMethods.forEach((paymentMethod) => {
    transfer = transfer.plus(paymentMethod.totalSales);
    total = total.plus(paymentMethod.totalSales);
  });

  return (
    <div className="d-flex flex-column pt-5 px-5">
      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h3">
          {I18n.get('startDate', 'fecha de inicio')}
        </strong>
        <div className="h4">
          {!!start ? dayjs.tz(start, timezone).format(I18n.get(!!dateFormat ? `${dateFormat.toUpperCase()} h:mm a` : I18n.get('dateTimeFormat', 'DD/MM/YYYY h:mm a'))) : null}
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('base', 'base inicial')}</strong>
        <div className="h4">{new BigNumber(base).toFormat(decimal, fmt)}</div>
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('cashSales', 'ventas en efectivo')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h4">{cash.toFormat(decimal, fmt)}</div>
        }
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('debitSales', 'ventas por tarjeta de débito')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h4">{debit.toFormat(decimal, fmt)}</div>
        }
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('creditSales', 'ventas por tarjeta de crédito')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h4">{credit.toFormat(decimal, fmt)}</div>
        }
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('transferSales', 'ventas por transferencias')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h4">{transfer.toFormat(decimal, fmt)}</div>
        }
      </div>
      {customPaymentMethods.map((customPaymentMethod, index) => {
        const totalSales = new BigNumber(customPaymentMethod.totalSales);
        return (
          <div
            className={`d-flex align-items-center justify-content-between ${customPaymentMethods.length - 1 === index ? 'border-bottom' : ''} my-2 pl-3`}
          >
            <strong className='text-capitalize-first h4'>
              {replace(
                I18n.get('salesBy', 'Ventas por {}'),
                '{}',
                get(customPaymentMethod, 'name', '')
              )}
            </strong>
            {!!loading ? (
              textLoader()
            ) : (
              <div className='h4'>{totalSales.toFormat(decimal)}</div>
            )}
          </div>
        );
      })}

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('refundCash', 'Devolución de dinero')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h4 text-danger">{refunds.toFormat(decimal, fmt)}</div>
        }
      </div>

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('invoiceCash', 'ingresos de efectivo')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h4">{inPayment.toFormat(decimal, fmt)}</div>
        }
      </div>

      {((totalsTipCash.isGreaterThan(0) || totalsTipOther.isGreaterThan(0))) &&
        <>
          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('shift.tipsCash', 'Propinas en efectivo')}</strong>
            {!!loading
              ? textLoader()
              : <div className="h4">{totalsTipCash.toFormat(decimal, fmt)}</div>
            }
          </div>

          <div className="d-flex align-items-center justify-content-between border-bottom my-2">
            <strong className="text-capitalize-first h4">{I18n.get('shift.tipsOther', 'Propinas en otros medios')}</strong>
            {!!loading
              ? textLoader()
              : <div className="h4">{totalsTipOther.toFormat(decimal, fmt)}</div>
            }
          </div>
        </>
      }

      <div className="d-flex align-items-center justify-content-between border-bottom my-2">
        <strong className="text-capitalize-first h4">{I18n.get('expenseCash', 'retiros de efectivo')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h4 text-danger">{outPayment.toFormat(decimal, fmt)}</div>
        }
      </div>

      <div className="d-flex align-items-center justify-content-between">
        <strong className="text-capitalize-first h2">{I18n.get('expectedAmount', 'valor esperado en caja')}</strong>
        {!!loading
          ? textLoader()
          : <div className="h3">{total.toFormat(decimal, fmt)}</div>
        }
      </div>

      <div className="border-bottom my-2" />

    </div>
  )
}

export default Details;